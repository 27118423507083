import { createPlatePlugin } from '@udecode/plate-core/react';
import { CURRENT_TEXT_EDITOR_VERSION } from 'components/bdd/TextEditor/helpers/constants';

export const VersionPlugin = createPlatePlugin({
  key: 'version',
  withOverrides: (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]) => {
      // Ensure the version is set on the root node
      if (path.length === 0) {
        if (!node.version) {
          node.version = CURRENT_TEXT_EDITOR_VERSION;
        }
      }

      return normalizeNode([node, path]);
    };

    return editor;
  },
});

export const extractVersionNumber = (value) => {
  if (value && value[0] && value[0].version) {
    return value[0].version;
  }

  return null;
};
