import { BDDDateRangePicker } from 'components/bdd/bdddatepicker';
import { Column, Container } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { useContext } from 'react';
import { SLStatContext } from '..';

export const DateRangeFilter = ({}) => {
  const { filters, setFilters } = useContext(SLStatContext);

  return (
    <Container>
      <Typography variant="label">Date Range</Typography>
      <Container minWidth={200}>
        <Typography variant="body1">
          <BDDDateRangePicker
            compact
            zIndex={6}
            placeholderText="Date Range"
            selected={filters["start_date"]}
            startDate={filters["start_date"]}
            endDate={filters["end_date"]}
            onChange={([s, e]) => {
              setFilters({
                start_date: s,
                end_date: e,
              });
            }}
            showYearDropdown
            isClearable
          />
        </Typography>
      </Container>
    </Container>
  );
};
