import { gql } from "@apollo/client"

export const GET_RINKNET_LEAGUES = gql`query GetLeagues {
    rinknetLeagues {
      id
      id_
      name
      priority
    }
}`

export const GET_SL_LEAGUES = gql`query GetSLLeagues {
    slLeagues {
      id
      shorthand
      name
    }
}`


export const GET_RINKNET_LEAGUES_SEASONS = gql`query GetSeasonsForLeague($leagueid: ID!) {
    rinknetSeasons(leagueid: $leagueid) {
      rinknetseason
    }
}`


const RinknetLeagueGroupFragment = gql`fragment rinknetLeagueGroupFragment on RinknetLeagueGroups {
    id
    name
    rinknetLeagues {
        id
        id_
        name
        priority
    }
}`
export const GET_RINKNET_LEAGUE_GROUPS = gql`query GetRinknetLeagueGroups {
    rinknetLeagueGroups {
       ...rinknetLeagueGroupFragment
    }
} ${RinknetLeagueGroupFragment}`

export const CREATE_RINKNET_LEAGUE_GROUP = gql`mutation CreateRinknetLeagueGroup($input: CreateRinknetLeagueGroupInput!) {
    createRinknetLeagueGroup(input: $input) {
        rinknetLeagueGroup {
            ...rinknetLeagueGroupFragment
        }
    }
} ${RinknetLeagueGroupFragment}`

export const UPDATE_RINKNET_LEAGUE_GROUP = gql`mutation UpdateRinknetLeagueGroup(
    $input: UpdateRinknetLeagueGroupInput!,
    $leagueIdsToAdd: [ID!],
    $leagueIdsToRemove: [ID!]
) {
    updateRinknetLeagueGroup(
        input: $input,
        leagueIdsToAdd: $leagueIdsToAdd,
        leagueIdsToRemove: $leagueIdsToRemove
    ) {
        rinknetLeagueGroup {
            ...rinknetLeagueGroupFragment
        }
    }
} ${RinknetLeagueGroupFragment}`

export const DELETE_RINKNET_LEAGUE_GROUP = gql`mutation DeleteRinknetLeagueGroup($id: ID!) {
    deleteRinknetLeagueGroup(id: $id) {
        id
    }
}`