import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_RINKNET_TEAMS_BY_LEAGUE, GET_TEAMS_BY_LEAGUE } from "../../apollo/queries/teams.queries"
import BDDSelect from "../bdd/bddselect"
import { StyledBDDSelect } from "components/bdd/Select/StyledBDDSelect"

export default function TeamsSelect({ value, onChange, useRinknet, leagueid, league, onTeamsLoaded }) {
    if (!leagueid && !league) {
        league = 'NHL'
    }
    const q = useRinknet ? GET_RINKNET_TEAMS_BY_LEAGUE : GET_TEAMS_BY_LEAGUE
    const variables = useRinknet ? { filters: { leagueid, active: true } } : { league };
    const { data, loading, error } = useQuery(q, { variables })
    const dKey = useRinknet ? 'rinknetTeams' : 'slTeams'
    const options = !!data ? data[dKey].map((t) => ({ value: useRinknet ? t.id_ : t.slug, label: t.longname })) : []
    
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if (options.length && !!onTeamsLoaded && !loaded) {
            onTeamsLoaded(options)
            setLoaded(true)
        }
    }, [options])

    return <StyledBDDSelect
      name='team'
      selectedValue={value}
      options={options}
      onChange={onChange}
      selectProps={{
        isSearchable: true,
        isLoading: loading,
        placeholder: loading ? 'Loading...' : error ? 'Error' : 'Select Team...'
      }}
    />
}

export const useSourcedTeamsSelect = ({
  useRinknet=true,
  defaultTeam, // rinknet id or slug
  league, // rinknet id or slug 
}) => {
  const [value, setValue] = useState(defaultTeam);
  const select = <TeamsSelect
    useRinknet={useRinknet}
    value={value}
    onChange={(n,v) => setValue(v)}
    leagueid={league}
    league={league}
  />
  return {
    select,
    selectedValue: value
  }
}