import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import BDDSelect from 'components/bdd/bddselect';
import { theme } from 'constants/theme';

export const SELECT_VARIANTS = {
  OUTLINED: 'outlined',
  DEFAULT: 'default',
};

export const SELECT_SIZES = {
  DEFAULT: 'default',
  SMALL: 'sm',
};

// This is a hack to be able to properly calculate
// the width of a react-select component
const HiddenLabel = styled.div({
  ...theme.typography.subtitle1,
  position: 'absolute',
  visibility: 'hidden',
});

export const StyledBDDSelect = ({
  name,
  options,
  selectedValue,
  variant,
  onChange,
  selectProps,
  size = 'default',
  color = 'light',
  collapseIndicator = false,
  renderWithoutOptions = false,
}) => {
  renderWithoutOptions = renderWithoutOptions || selectProps?.renderWithoutOptions;
  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (!!selectProps?.width) {
      setWidth(selectProps.width);
    } else {
      setWidth(Math.max(ref?.current?.offsetWidth + theme.spacing[5], 100));
    }
  });

  if (!options && !renderWithoutOptions) return null;

  const longestOptionLabel = options
    ?.map((option) => option.label)
    ?.concat([selectProps?.placeholder])
    ?.sort((a, b) => b?.length - a?.length)[0];

  const optionTypography = { ...theme.typography.body1 };
  const valueTypography = { ...theme.typography.body1 };
  const placeholderTypography = { ...theme.typography.body1 };

  if (size === SELECT_SIZES.SMALL) {
    optionTypography.fontSize = '9pt';
    valueTypography.fontSize = '9pt';
    placeholderTypography.fontSize = '9pt';
  }

  return (
    <>
      <HiddenLabel ref={ref}>{longestOptionLabel}</HiddenLabel>
      <BDDSelect
        name={name}
        value={selectedValue}
        options={options}
        styles={{
          placeholder: (provided) => ({
            ...provided,
          }),
          container: (provided) => ({
            ...provided,
            ...valueTypography,
            width: variant == SELECT_VARIANTS.OUTLINED && width,
            backgroundColor: 'unset',
          }),
          control: (provided) => ({
            ...provided,
            // if we're "collapsingIndicator" (e.g. on the career stats view) we want the background fo the input to be transparent to show info
            backgroundColor: collapseIndicator ? 'unset' : theme.colors[color].background,
            ...(variant != SELECT_VARIANTS.OUTLINED
              ? {
                  borderColor: theme.colors.light.border,
                  '&:hover': {
                    borderColor: theme.colors.teams.bos.primary,
                  },
                }
              : {
                  ...theme.borders.none,
                  '&:hover': {
                    ...theme.borders.none,
                  },
                }),
            boxShadow:
              !!selectProps?.isInvalid
                ? `0px 0px 5px ${theme.colors.states.danger}`
                : 'none',
            minHeight: 0,
          }),
          option: (provided, state) => {
            return {
              ...provided,
              ...optionTypography,
              padding: '4px 8px',
              backgroundColor: state.isSelected
                ? theme.colors.light.highlight
                : state.isFocused
                ? theme.colors.light.focus
                : theme.colors.light.background,
              color: theme.colors.light.text.primary,
              '&:hover': {
                backgroundColor: state.isSelected
                  ? theme.colors.light.highlight
                  : theme.colors.light.focus,
              },
            };
          },
          ...(size == SELECT_SIZES.SMALL && {
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: theme.spacing[1],
            }),
            valueContainer: (provided) => ({
              ...provided,
              paddingTop: 0,
              paddingBottom: 0,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: theme.spacing[1],
            }),
            multiValue: (provided) => ({
              ...provided,
              padding: 0,
            }),
          }),
          ...(variant == SELECT_VARIANTS.OUTLINED && {
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: 0,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              flexGrow: collapseIndicator ? 1 : null, // flexGrow=1 places the indicator next to the value container--not at the end
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
              // justifyContent: 'end',
            }),
            menu: (provided) => ({
              ...provided,
            }),
            singleValue: (provided) => ({
              ...provided,
              ...valueTypography,
              marginLeft: 0,
              marginRight: 0,
              maxWidth: 'auto',
              color: theme.colors[color].text.primary,
            }),
          }),
        }}
        onChange={onChange}
        {...selectProps}
      />
    </>
  );
};
