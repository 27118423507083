import { Row } from 'components/bdd/Layout';
import { ASSET_TYPES } from '../constants';
import BDDTag from 'components/PlayerTagging/bddtag';
import { positionToFDG, rinknetToDetailedPositions } from 'helpers/hockeyutils';
import { Typography } from 'components/bdd/Typography';
import { formatDollars } from 'helpers/contracts';
import { bruinsGold } from 'helpers/plotting';

export const targetToBoardStyle = (target) => {
  if (target.isReclamation) {
    return {
      boxShadow: `0 0 5px 6px #ff000077`,
    };
  }
  if (target.ahlDepth) {
    return {
      boxShadow: `0 0 5px 6px #aaaaaa`,
    };
  }
  return {};
};

export const targetToStyle = (target) => {
  if (target.isReclamation) {
    return {
      boxShadow: `0 0 5px 2px red`,
    };
  }
  if (target.ahlDepth) {
    return {
      boxShadow: `0 0 5px 2px #aaaaaa`,
    };
  }

  return {
    boxShadow: `0 0 5px 2px ${bruinsGold}`,
  };
};

export const targetToPosFDG = (target) => {
  return !!target.positions?.length
    ? positionToFDG(target.positions[0])
    : positionToFDG(target.bddPlayer?.rinknetPlayer?.position);
};

export const targetToName = (target) => {
  return target.assetType === ASSET_TYPES.PLAYER
    ? `${target.bddPlayer.firstname} ${target.bddPlayer.lastname}`
    : 'Draft pick';
};

export const targetToPositions = (target) => {
  const rp = target.bddPlayer.rinknetPlayer;

  return !!target.positions?.length
    ? target.positions.map((p) => p.slice(0, -1))
    : rinknetToDetailedPositions(rp?.position);
};

export const targetToPrimaryPosition = (target) => {
  return !!target.primaryPosition
    ? target.primaryPosition
    : rinknetToDetailedPositions(target.bddPlayer?.rinknetPlayer?.position)[0];
};

export const targetToAcquisitionMethod = (target) => {
  return target.bddPlayer?.availability;
};

export const createProObjectiveTargetCacheUpdate = (
  cache,
  {
    data: {
      createProObjectiveTargets: { targets },
    },
  }
) => {
  cache.modify({
    fields: {
      proObjectiveTargets(existing = [], { readField }) {
        // "createProObjectiveTargets" can either create a new target OR activate an existing one...
        // thus we need to filter out any existing ref to this target
        const incomingIds = targets.map((t) => t.id);
        return existing
          .filter((t) => !incomingIds.find((id) => id == readField('id', t.id)))
          .concat(targets);
      },
      proObjectiveTeams(existing = [], { readField }) {
        const incomingIds = targets.map((t) => t.id);
        const teamToIncomingTargetIds = targets.reduce((acc, t) => {
          if (!(t.teamSlug in acc)) acc[t.teamSlug] = [];
          acc[t.teamSlug].push({ id: t.id });
          return acc;
        }, {});
        const ret = existing.map((e) => {
          if (e.teamSlug in teamToIncomingTargetIds) {
            return {
              ...e,
              targets: e.targets
                .filter((t) => !incomingIds.find((id) => id == readField('id', t)))
                .concat(teamToIncomingTargetIds[e.teamSlug]),
            };
          }
          return e;
        });
        return ret;
      },
    },
  });
};

export const processTagsByType = (tagData, type) => {
  const tags = !!tagData
    ? [...tagData.bddTags]
        .sort((a, b) => b.priority - a.priority)
        .filter((t) => t.tagType === type)
    : [];
  const options = tags.map((t) => ({
    label: (
      <Row gap={4}>
        <BDDTag key={t.id} tag={t} />
        {t.description}
      </Row>
    ),
    value: t.id,
    search: t.description,
  }));
  return [tags, options];
};

export const targetToRoleTag = (target) => {
  if (target.assetType !== ASSET_TYPES.PLAYER) return null;
  return target?.bddPlayer?.roleTag;
};

export const targetToAvailabilityTag = (target) => {
  if (target.assetType !== ASSET_TYPES.PLAYER) return null;
  return target.bddPlayer?.bddManagedTags?.find((t) => t.tag.tagType === 'AVAILABILITY')
    ?.tag;
};

export const targetToOtherTags = (target) => {
  if (target.assetType !== ASSET_TYPES.PLAYER) return null;
  return target.bddPlayer?.bddManagedTags?.filter(
    (t) => !['ROLE', 'AVAILABILITY'].includes(t.tag.tagType)
  );
};
// export const updateProObjectiveTargetCacheUpdate = (cache, { data: { updateProObjectiveTarget: { target }} }) => {
//   console.log(target)
//   cache.modify({
//     fields: {
//       proObjectiveTargets(existing=[], { readField }) {
//         return [...existing.filter(t => readField('id', t) === target.id), target]
//       }
//     }
//   })
// }

export const getTransactionLabel = (target, value) => {
  if (!!value) {
    const signingTransaction = target.transactions?.find(
      (t) =>
        t.id == value.transactionId && t.bddSignings.find((s) => s.id == value.variantId)
    );

    const tradeTransaction = target.transactions?.find(
      (t) =>
        t.id == value.transactionId && t.bddTrades.find((s) => s.id == value.variantId)
    );

    if (!!signingTransaction) {
      const signing = signingTransaction.bddSignings.find((s) => s.id == value.variantId);
      return (
        <Typography variant="stat" textAlign="center">
          {signing.term} x {formatDollars(signing.capHitNhl)}
        </Typography>
      );
    }

    if (!!tradeTransaction) {
      const trade = tradeTransaction.bddTrades.find((s) => s.id == value.variantId);
      const assetsTraded =
        trade.tradedPlayers.filter((tp) => tp.fromTeamSlug == 'nhl.bos').length +
        trade.tradedDraftPicks.filter((tdp) => tdp.fromTeamSlug == 'nhl.bos').length;

      return <Typography variant="stat" textAlign="center">{assetsTraded} Assets</Typography>;
    }
  }
};
