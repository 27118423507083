import {
  PlayerLeague,
  PlayerInfo,
  PlayerActiveContract,
  PlayerName,
  PlayerPosition,
  PlayerInfoRow,
} from './PlayerBio';
import styled from 'styled-components';
import { ScoutingRanks } from './ScoutingRanks';
import { LIST_ID_TYPES } from 'constants';
import { theme } from 'constants';
import { EliteProspectsData } from './EliteProspectsData';
import { useCombineNotesUploadData } from '../hooks/useCombineNotesUploadData';
import { WarData } from './WarData';
import { SlData } from './SlData';
import { Column, Container, Row } from 'components/bdd/Layout';
import { ListPlayerNotes } from './Notes';
import { HoverInfo } from 'components/bdd';
import { useState } from 'react';
import LeftRightArrowButtons from 'components/bdd/Button/LeftRightArrowButtons';
import SportlogiqSkills from './SportlogiqSkills';
import { PlayerNoteCommentForm } from 'components/PlayerNotes';
import useModal from 'components/bdd/Modal/useModal';
import { Button } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import useQuestionnaires from '../hooks/useQuestionnaires';
import { Typography } from 'components/bdd/Typography';
import useSkatingEvals from '../hooks/useSkatingEvals';
import { PlayerTags } from './PlayerTags';
import { PLAYER_ENTITY_TYPE, useNotes } from 'components/Notes';
import { useGeneratedSummaries } from "components/ScoutingV2/hooks/useGeneratedSummaries";
import { GeneratedScoutingSummaryBody } from "components/PlayersV2/Scouting/GeneratedScoutingSummary";

const FieldContainer = styled.div(({ minWidth, center, ...styles }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: center ? "center" : "start",
  justifyContent: center ? "center" : "space-evenly",
  textAlign: center ? "center" : "start",
  minWidth: minWidth,
  ...styles,
}));

const FieldLabel = styled.div({
  ...theme.typography.body1,
  width: "100%",
  textAlign: "start",
});
const FieldSublabel = styled.div({
  ...theme.typography.caption,
  width: "100%",
  textAlign: "center",
});

const BorderBox = styled.div((style) => ({
  border: "1px solid #ddd",
  borderRadius: "5px",
  textAlign: "center",
  width: "100%",
  flexGrow: 1,
  ...style,
}));

export const PLPlayerName = ({
  bddPlayer,
  rinknetPlayer,
  slPlayerMaster,
  draftYear,
  bddPlayerSlugs,
  rinknetIds,
  variant,
  allowHover = true,
  linkName = true,
}) => {
  const { playerToCombineNotes } = useCombineNotesUploadData({
    draftYear,
    skip: !draftYear,
  });

  const { rinknetIdToHasQuestionnaire, modal } = useQuestionnaires({
    rinknetIds,
    year: draftYear,
    skip: !draftYear,
  });

  const { rinknetIdToHasSkatingEval, modal: skatingEvalModal } =
    useSkatingEvals({
      rinknetIds,
      year: draftYear,
      skip: !draftYear,
    });

  const {
    noteItems: playerNoteItems,
    createNote,
    updateNote,
  } = useNotes({
    entityIds: bddPlayerSlugs,
    entityType: PLAYER_ENTITY_TYPE,
    includeMentions: true,
    includeSubEntityTypes: [PLAYER_ENTITY_TYPE, "TARGET_BDD_PLAYER"],
  });

  const playersNotes =
    !!bddPlayer && !!playerNoteItems
      ? playerNoteItems?.filter((pn) => pn.note.entityId === bddPlayer.slug)
      : null;

  const { setShowModal: setShowAddNoteModal, renderModal } = useModal({
    title: <Typography variant="h6">Add Note</Typography>,
    size: "lg",
    useNewModal: true,
  });

  const notesModal = renderModal(
    <Container padding={3}>
      <PlayerNoteCommentForm
        bddPlayer={bddPlayer}
        rinknetPlayer={
          !!rinknetPlayer ? rinknetPlayer : bddPlayer.rinknetPlayer
        }
        onCancel={() => setShowAddNoteModal(false)}
        onSubmit={(values) => {
          createNote({ variables: values });
          setShowAddNoteModal(false);
        }}
      />
    </Container>
  );

  return (
    <>
      {notesModal}
      <PlayerName
        bdd={bddPlayer}
        rp={rinknetPlayer}
        slp={slPlayerMaster}
        playerToCombineNotes={playerToCombineNotes}
        hasQuestionnaire={rinknetIdToHasQuestionnaire?.[rinknetPlayer.realId]}
        hasSkatingEval={rinknetIdToHasSkatingEval?.[rinknetPlayer.realId]}
        bddPlayerNotes={playersNotes}
        // createNote={createNote}
        updateNote={updateNote}
        setShowAddNoteModal={setShowAddNoteModal}
        variant={variant}
        allowHover={allowHover}
      />
    </>
  );
};

export const PLBio = ({
  bddPlayer,
  rinknetPlayer,
  slPlayerMaster,
  draftYear,
  bddPlayerSlugs,
  rinknetIds,
  allowHover = true,
  overridePosition = null, // allows pro obj to maintain its own positions
  justName = false,
}) => {
  return (
    <FieldContainer minWidth={250}>
      {justName ? (
        <div>
          <PLPlayerName
            bddPlayer={bddPlayer}
            rinknetPlayer={rinknetPlayer}
            slPlayerMaster={slPlayerMaster}
            draftYear={draftYear}
            bddPlayerSlugs={bddPlayerSlugs}
            rinknetIds={rinknetIds}
            allowHover={allowHover}
          />
          {/* <PlayerInfoRow rp={rinknetPlayer} bd={bddPlayer} /> */}
        </div>
      ) : (
        <>
          <div>
            <PLPlayerName
              bddPlayer={bddPlayer}
              rinknetPlayer={rinknetPlayer}
              slPlayerMaster={slPlayerMaster}
              draftYear={draftYear}
              bddPlayerSlugs={bddPlayerSlugs}
              rinknetIds={rinknetIds}
              allowHover={allowHover}
            />
          </div>
          <PlayerInfo rp={rinknetPlayer} rinknetIds={rinknetIds} />
          <Row columnGap={4} alignItems="start">
            <PlayerActiveContract bd={bddPlayer} />
            {!!overridePosition ? (
              overridePosition
            ) : (
              <PlayerPosition rp={rinknetPlayer} />
            )}
            <PlayerTags bddPlayer={bddPlayer} />
          </Row>
        </>
      )}
    </FieldContainer>
  );
};

export const PLWar = ({
  bddPlayer,
  listIdType,
  shouldRender = true,
  hideHeader,
}) => {
  if (listIdType !== LIST_ID_TYPES.bdd_player_slug) return null;
  return (
    <FieldContainer minWidth={150}>
      {!hideHeader && <FieldLabel>Isolated Impact</FieldLabel>}
      {shouldRender && <WarData bddPlayer={bddPlayer} />}
    </FieldContainer>
  );
};

export const PLScoutingRanks = ({
  rinknetPlayer,
  rinknetIds,
  list,
  playerToAnalytics,
  shouldRender = true,
  hideHeader,
  compact,
  hideScoutReportModal,
  draftYear,
  defaultSeason,
  hideExpectedPickRange,
}) => {
  const scoutingRanks = shouldRender && (
    <ScoutingRanks
      rinknetId={rinknetPlayer?.realId}
      rinknetIds={rinknetIds}
      posFDG={rinknetPlayer?.posFDG}
      playerToAnalytics={playerToAnalytics}
      listType={list.listType}
      listId={list.id}
      playerName={`${rinknetPlayer.firstname} ${rinknetPlayer.lastname}`}
      compact={compact}
      hideScoutReportModal={hideScoutReportModal}
      draftYear={draftYear}
      season={defaultSeason}
      hideExpectedPickRange={hideExpectedPickRange}
    />
  );

  return (
    <FieldContainer minWidth={300}>
      {!hideHeader && <FieldLabel>Scouting Grades</FieldLabel>}
      {compact ? (
        scoutingRanks
      ) : (
        <BorderBox padding="2px" minHeight={80}>
          {scoutingRanks}
        </BorderBox>
      )}
    </FieldContainer>
  );
};

export const PLEPData = ({
  rinknetPlayer,
  rinknetPlayers,
  defaultSeason,
  shouldRender,
  hideHeader,
}) => {
  return (
    <FieldContainer minWidth={110}>
      {!hideHeader && <FieldLabel>EP Stats</FieldLabel>}
      <BorderBox padding="4px" minHeight={40}>
        {shouldRender && (
          <EliteProspectsData
            rinknetPlayers={rinknetPlayers}
            rp={rinknetPlayer}
            maxLeagueWidth="90px"
            season={defaultSeason}
          />
        )}
      </BorderBox>
    </FieldContainer>
  );
};

export const PLListNotes = ({ notes, shouldRender, hideHeader }) => {
  return (
    !!notes &&
    !!notes.length && (
      <FieldContainer flexGrow={1} minWidth="300px">
        <BorderBox>
          {!hideHeader && (
            <FieldLabel>
              List Player Notes{" "}
              <HoverInfo content="These notes are associated with the list and don't exist anywhere else" />
            </FieldLabel>
          )}
          {shouldRender && !!notes && <ListPlayerNotes notes={notes} />}
        </BorderBox>
      </FieldContainer>
    )
  );
};

export const PLSportlogiqData = ({
  list,
  filters,
  slStats,
  bddPlayer,
  shouldRender,
  hideHeader,
}) => {
  return (
    <FieldContainer minWidth={250}>
      {!hideHeader && <FieldLabel>SL Stats</FieldLabel>}
      <BorderBox>
        {shouldRender && (
          <SlData
            list={list}
            bddPlayer={bddPlayer}
            filters={filters}
            slStats={slStats}
          />
        )}
      </BorderBox>
    </FieldContainer>
  );
};

export const PLSportlogiqSkills = ({
  bddPlayer,
  bddPlayerSlugs,
  statDefinitions,
  defaultSeason,
  shouldRender,
  hideHeader,
}) => {
  return (
    <FieldContainer minWidth={200} minHeight={60}>
      {!hideHeader && <FieldLabel>SL Skills</FieldLabel>}
      <BorderBox padding="4px">
        {shouldRender && (
          <SportlogiqSkills
            bddPlayer={bddPlayer}
            slugs={bddPlayerSlugs}
            statDefinitions={statDefinitions}
            season={defaultSeason}
          />
        )}
      </BorderBox>
    </FieldContainer>
  );
};

export const PLPlayerNotes = ({
  bddPlayer,
  rinknetPlayer,
  bddPlayerSlugs,
  shouldRender,
}) => {
  const {
    noteItems: playerNoteItems,
    createNote,
    updateNote,
  } = useNotes({
    entityIds: bddPlayerSlugs,
    entityType: PLAYER_ENTITY_TYPE,
    includeMentions: true,
    includeSubEntityTypes: [PLAYER_ENTITY_TYPE, "TARGET_BDD_PLAYER"],
  });

  const { renderModal, setShowModal: setShowAddNoteModal } = useModal({
    title: `Add ${rinknetPlayer?.firstname} ${rinknetPlayer?.lastname} Note`,
  });
  const notesModal = renderModal(
    <Container padding={3}>
      <PlayerNoteCommentForm
        bddPlayer={bddPlayer}
        rinknetPlayer={
          !!rinknetPlayer ? rinknetPlayer : bddPlayer.rinknetPlayer
        }
        onCancel={() => setShowAddNoteModal(false)}
        onSubmit={(values) => {
          createNote({ variables: values });
          setShowAddNoteModal(false);
        }}
      />
    </Container>
  );

  if (!bddPlayer) return null;

  const filteredPlayerNoteItems = shouldRender
    ? playerNoteItems?.filter((pn) => pn.note.entityId === bddPlayer.slug)
    : [];

  return (
    <FieldContainer flexGrow={1} minWidth="300px" minHeight="60px">
      <BorderBox>
        {notesModal}
        <BDDPlayerNoteViewer
          bddPlayer={bddPlayer}
          rinknetPlayer={rinknetPlayer}
          playerNoteItems={filteredPlayerNoteItems}
          setShowAddNoteModal={setShowAddNoteModal}
          shouldRender={shouldRender}
          createNote={createNote}
          updateNote={updateNote}
        />
      </BorderBox>
    </FieldContainer>
  );
};

export const BDDPlayerNoteViewer = ({
  bddPlayer,
  rinknetPlayer,
  playerNoteItems,
  // createNote,
  setShowAddNoteModal,
  updateNote,
  hideHeader,
  shouldRender = true,
}) => {
  const [noteIdx, setNoteIdx] = useState(0);

  return (
    <div onClick={(ev) => ev.stopPropagation()}>
      <Column gap={1}>
        <Row columnGap={3}>
          {!hideHeader && (
            <Row columnGap={1}>
              <Typography variant="subtitle1">Player Notes</Typography>
              <HoverInfo content="These notes are associated with the player directly and can be viewed on the playercard" />
            </Row>
            // <FieldLabel>
            //   Player Notes
            //   <HoverInfo content="These notes are associated with the player directly and can be viewed on the playercard" />
            // </FieldLabel>
          )}
          <LeftRightArrowButtons
            leftOnClick={(ev) => {
              setNoteIdx(noteIdx - 1);
              ev.stopPropagation();
            }}
            rightOnClick={(ev) => {
              setNoteIdx(noteIdx + 1);
              ev.stopPropagation();
            }}
            disabled={!playerNoteItems?.length}
            leftDisabled={noteIdx <= 0}
            rightDisabled={noteIdx >= playerNoteItems?.length - 1}
          />
          <div style={{ marginLeft: "auto" }}>
            <Button
              size="sm"
              variant="outline-dark"
              onClick={() => {
                setShowAddNoteModal(true);
              }}
            >
              <Row columnGap={1}>
                <Icon icon={<PlusLg />}></Icon>
                <Typography variant="stat">
                  <b>Add Note</b>
                </Typography>
              </Row>
            </Button>
          </div>
        </Row>
        {shouldRender && !!playerNoteItems?.[noteIdx] && (
          <PlayerNoteCommentForm
            key={playerNoteItems?.[noteIdx]?.note?.note}
            bddPlayer={bddPlayer}
            rinknetPlayer={
              !!rinknetPlayer ? rinknetPlayer : bddPlayer.rinknetPlayer
            }
            existingPlayerNote={playerNoteItems[noteIdx].note}
            onSubmit={(values) => updateNote({ variables: values })}
          />
        )}
      </Column>
    </div>
  );
};

export const PLPlayerGeneratedSummary = ({
  rinknetPlayer,
  rinknetIds,
  shouldRender,
}) => {
  const { generatedSummaries, placeholder } = useGeneratedSummaries({
    rinknetIds,
  });
  if (!shouldRender) return null;
  if (!!placeholder) return placeholder;
  const generatedSummary = generatedSummaries?.find(
    (s) => s.rinknetId == rinknetPlayer.realId
  );
  if (!generatedSummary)
    return (
      <Typography variant="caption">No generated summary available</Typography>
    );
  return <GeneratedScoutingSummaryBody report={generatedSummary} />;
};
