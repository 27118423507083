import { ErrorMessage } from 'formik';
import React from 'react';
import { strContains } from '../../helpers/data';
import { getBDDPlayerReportChoices } from '../../helpers/filters';
import BDDFormError from '../bdd/bddformerror';
import BDDSelect from '../bdd/bddselect';

export const SelectInput = ({
  title,
  noTitle,
  position,
  reportType,
  field,
  ...rest
}) => {
  const { name, value, onChange } = field; // dont know why error isn't in here
  const error = rest.form.errors[name];
  const touched = rest.form.touched[name];
  return (
    <>
      {noTitle ? null : <label>{title}</label>}
      <BDDSelect
        name={name}
        placeholder={title}
        value={value}
        options={nameToOptions(name, position, reportType)}
        onChange={(n, v) => onChange({ target: { name: n, value: v } })}
        isClearable
        isInvalid={!!error && !!touched}
        {...rest}
      />
      <BDDFormError>
        <ErrorMessage name={name} />
      </BDDFormError>
    </>
  );
};

const skillCategoryOptions = [
  { label: '5.0 (Excellent)', value: 5.0 },
  { label: '4.5', value: 4.5 },
  // { label: '4.25', value: 4.25 },
  { label: '4.0 (Good)', value: 4.0 },
  // { label: '3.75', value: 3.75 },
  { label: '3.5', value: 3.5 },
  // { label: '3.25', value: 3.25 },
  { label: '3.0 (Average)', value: 3.0 },
  // { label: '2.75', value: 2.75 },
  { label: '2.5', value: 2.5 },
  { label: '2.0', value: 2.0 },
  { label: '1.5', value: 1.5 },
  { label: '1.0 (Poor)', value: 1.0 },
];

export const positionToCategories = {
  f: [
    'Skating/Pace',
    'Skill/Creativity',
    'Puck Possession',
    'Awareness/Hockey Sense',
    'Commitment to Defend',
    'Scoring Ability',
    'Interior Ice Involvement',
    'Compete Level',
  ],
  d: [
    'Skating/Mobility',
    'Defending',
    'Awareness/Hockey Sense',
    'Outlets/Passing',
    'Puck Handling',
    'Offensive Involvement',
    'Shooting Ability',
    'Compete Level',
  ],
  g: [
    'Positioning/Anticipation',
    'Focus/Compete',
    'Tracking Pucks',
    'Athleticism',
    'Footwork/Balance',
    'Hands (glove/blocker)',
    'Rebound Control',
    'Puck Handling',
  ],
};

const gameScoreOptions = [
  { label: '5.0 (Excellent)', value: 5.0 },
  { label: '4.5 (Very Good)', value: 4.5 },
  { label: '4.0 (Good)', value: 4.0 },
  { label: '3.5 (Above Average)', value: 3.5 },
  { label: '3.0 (Average)', value: 3.0 },
  { label: '2.0 (Below Average)', value: 2.0 },
  { label: '1.0 (Poor)', value: 1.0 },
];

const positionToCurrentRoleOptions = {
  f: [
    { label: '1 (Elite)', value: 1 },
    { label: '2 (Top Six)', value: 2 },
    { label: '3 (Middle Six)', value: 3 },
    { label: '4 (Bottom Six)', value: 4 },
    { label: '5 (Extra Forward)', value: 5 },
  ],
  d: [
    { label: '1 (Elite)', value: 1 },
    { label: '2 (Top Pair)', value: 2 },
    { label: '3 (Middle Pair)', value: 3 },
    { label: '4 (Bottom Pair)', value: 4 },
    { label: '5 (Extra Defenseman)', value: 5 },
  ],
  g: [
    { label: '1 (Full-time Starter)', value: 1 },
    { label: '2 (Split Rotation)', value: 2 },
    { label: '3 (Backup)', value: 3 },
  ],
};

export const currentRoleToText = (value, position) => {
  /* Given a current role as an integer value, and a position, returns text
    @params value: int (or str) 1-5 "current role"
    @params position: f,d,g
    @returns the text for this projection */
  const roles = positionToCurrentRoleOptions[position.toLowerCase()];
  const filt = roles.filter((r) => r.value == value);
  if (!filt.length) return null;
  var label = filt[0].label;
  if (strContains(label, '(')) {
    label = label.slice(label.indexOf('(') + 1, label.indexOf(')'));
  }
  return label;
};

const positionToPlayerType = {
  f: [
    { label: 'Offensive', value: 'Offensive' },
    { label: 'Two-Way', value: 'Two-Way' },
    { label: 'Shooter/Scorer', value: 'Shooter/Scorer' },
    { label: 'Skilled/Playmaker', value: 'Skilled/Playmaker' },
    { label: 'Power Forward', value: 'Power Forward' },
    { label: 'Energy Style', value: 'Energy Style' },
    { label: 'Defensive/Checker', value: 'Defensive/Checker' },
    { label: 'Lacks Identity', value: 'Lacks Identity' },
  ],
  d: [
    { label: 'Two-Way', value: 'Two-Way' },
    { label: 'Puckmover', value: 'Puckmover' },
    { label: 'Transporter', value: 'Transporter' },
    { label: 'Offensive/PP', value: 'Offensive/PP' },
    { label: 'Shutdown/PK', value: 'Shutdown/PK' },
    { label: 'Defensive', value: 'Defensive' },
    { label: 'Size/Strength', value: 'Size/Strength' },
    { label: 'Lacks Identity', value: 'Lacks Identity' },
  ],
  g: [
    { label: 'Athletic', value: 'Athletic' },
    { label: 'Blocker', value: 'Blocker' },
  ],
};

const positionToProProjection = {
  f: [
    { label: '1 (NHL Elite)', value: 1 },
    { label: '2 (NHL Top Six)', value: 2 },
    { label: '3 (NHL Middle Six)', value: 3 },
    { label: '4 (NHL Bottom Six)', value: 4 },
    { label: '5 (NHL Depth)', value: 5 },
    { label: '6 (AHL Player)', value: 6 },
    { label: '7 (Development Project)', value: 7 },
    { label: '8 (No Interest)', value: 8 },
    { label: '9 (Undrafted)', value: 9 },
  ],
  d: [
    { label: '1 (Elite)', value: 1 },
    { label: '2 (Top Pair)', value: 2 },
    { label: '3 (Middle Pair)', value: 3 },
    { label: '4 (Bottom Pair)', value: 4 },
    { label: '5 (NHL Depth)', value: 5 },
    { label: '6 (AHL Player)', value: 6 },
    { label: '7 (Development Project)', value: 7 },
    { label: '8 (No Interest)', value: 8 },
  ],
  g: [
    { label: '1 (Elite)', value: 1 },
    { label: '2 (Starting Goalie)', value: 2 },
    { label: '3 (First Backup)', value: 3 },
    { label: '4 (Second Backup)', value: 4 },
    { label: '5 (AHL/Depth)', value: 5 },
    { label: '6 (Development Project)', value: 6 },
    { label: "7 (Don't Want)", value: 7 },
  ],
};
export const proProjectionToText = (value, position) => {
  /* Given a pro projection as an integer value, and a position
    @params value: int (or str) 1-8 "pro projection"
    @params position: f,d,g
    @returns the text for this projection */
  const projections = positionToProProjection[position.toLowerCase()];
  const filt = projections.filter((p) => p.value == value);
  if (!filt.length) return null;
  var label = filt[0].label;
  if (strContains(label, '(')) {
    label = label.slice(label.indexOf('(') + 1, label.indexOf(')'));
  }
  return label;
};

const draftRoundOptions = [
  { label: 'Top 10', value: 'Top 10' },
  { label: 'Mid 1st', value: 'Mid 1st' },
  { label: 'Late 1st', value: 'Late 1st' },
  { label: 'Early 2nd', value: 'Early 2nd' },
  { label: 'Late 2nd', value: 'Late 2nd' },
  { label: 'Round 3', value: 'Round 3' },
  { label: 'Rounds 4/5', value: 'Rounds 4/5' },
  { label: 'Rounds 6/7', value: 'Rounds 6/7' },
  { label: 'Monitor', value: 'Monitor' },
  { label: 'Non-draft', value: 'Non-draft' },
];

const underageDraftRoundOptions = [
  { label: 'High Underage', value: 'high underage' },
  { label: 'Mid Underage', value: 'mid underage' },
  { label: 'Late Underage', value: 'late underage' },
];

export const nameToOptions = (name, position, reportType) => {
  switch (name) {
    case 'reportType': {
      return getBDDPlayerReportChoices();
    }
    case 'gameScore': {
      return gameScoreOptions;
    }
    case 'currentRole': {
      if (!position) throw Error('Position required when field is currentRole');
      if (!['f', 'd', 'g'].includes(position))
        throw Error('Position required when field is currentRole');
      return positionToCurrentRoleOptions[position];
    }
    case 'draftRound': {
      if (reportType === 'underage') return underageDraftRoundOptions;
      return draftRoundOptions;
    }
    case 'proProjection': {
      return positionToProProjection[position];
    }
    case 'playerType': {
      return positionToPlayerType[position];
    }
    case 'positionDetail': {
      return positionOptions[position];
    }
    default: {
      return skillCategoryOptions;
    }
  }
};

export const positionOptions = {
  f: [
    { label: 'Left Wing', value: 'Left Wing' },
    { label: 'Center', value: 'Center' },
    { label: 'Right Wing', value: 'Right Wing' },
  ],
  d: [
    { label: 'Left Defence', value: 'Left Defence' },
    { label: 'Right Defence', value: 'Right Defence' },
  ],
  g: [{ label: 'Goalie', value: 'Goalie' }],
};
// export const positionOptions = [
//     { label: 'FORWARD', options: [
//         { label: 'Left Wing', value: 'Left Wing' },
//         { label: 'Center', value: 'Center' },
//         { label: 'Right Wing', value: 'Right Wing' },
//     ]}, {label: 'DEFENCE', options: [
//         { label: 'Left Defence', value: 'Left Defence' },
//         { label: 'Right Defence', value: 'Right Defence' },
//     ]}, {label: 'Goalie', options: [{ label: 'Goalie', value: 'Goalie' }]}
// ]

export const defaultGradeOptions = [
  {
    value: 'proProjection',
    label: 'Pro Projection',
    ratings: ['Pro Projection'],
    invert: 7,
  },
  {
    value: 'gameScore',
    label: 'Game Score',
    ratings: ['Game Rating', 'Game Impact'],
  },
  {
    value: 'currentRole',
    label: 'Current Role',
    ratings: ['Current Role'],
    invert: 6,
  },
];

export const skaterSkillGradeOptions = [
  {
    value: 'skating',
    label: 'Skating',
    ratings: ['Skating', 'Skating/Mobility', 'Skating/Pace'],
    skillCategory: true,
  },
  {
    value: 'hockeySense',
    label: 'Hockey Sense',
    ratings: ['Hockey Sense', 'Awareness/Hockey Sense'],
    skillCategory: true,
  },
  {
    value: 'scoring',
    label: 'Scoring',
    ratings: ['Scoring', 'Shooting', 'Shooting Ability', 'Scoring Ability'],
    skillCategory: true,
  },
  {
    value: 'intensity',
    label: 'Intensity/Compete',
    ratings: ['Intensity/Compete', 'Intensity', 'Compete Level'],
    skillCategory: true,
  },
  {
    value: 'puckHandling',
    label: 'Puck Handling',
    ratings: ['Puck Handling', 'Puck Skills', 'Puck Possession'],
    skillCategory: true,
  },
  // {
  //   value: 'passing',
  //   label: 'Passing',
  //   ratings: ['Passing', 'Outlets/Passing'],
  //   skillCategory: true,
  // },
//   {
//     value: 'creativity',
//     label: 'Skill/Creativity',
//     ratings: ['Skill/Creativity', 'Skill/Hands', 'Hands'],
//     skillCategory: true,
//   },
];

export const goalieSkillGradeOptions = [
  {
    value: 'positioning',
    label: 'Positioning/Anticipation',
    ratings: ['Positioning/Anticipation', 'Positioning'],
    skillCategory: true,
  },
  {
    value: 'reboundControl',
    label: 'Rebound Control',
    ratings: ['Rebound Control'],
    skillCategory: true,
  },
  {
    value: 'postTracking',
    label: 'Post Tracking',
    ratings: ['Post Tracking'],
    skillCategory: true,
  },
  {
    value: 'trackingPucks',
    label: 'Tracking Pucks',
    ratings: ['Tracking Pucks'],
    skillCategory: true,
  },
  {
    value: 'handsGloveBlocker',
    label: 'Hands (glove/blocker)',
    ratings: ['Hands (glove/blocker)'],
    skillCategory: true,
  },
  {
    value: 'footwork',
    label: 'Footwork/Balance',
    ratings: ['Footwork/Balance'],
    skillCategory: true,
  },
];

export const getGradeOptions = (posFDG) => [
  ...defaultGradeOptions,
  ...(posFDG == 'G' ? goalieSkillGradeOptions : skaterSkillGradeOptions),
];
