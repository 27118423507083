import { theme } from 'constants';
import { useContext, useEffect } from 'react';
import { WindowContext } from '../context';
import { Column, Container } from '../Layout';
import PageHeader from './PageHeader';
import { PageBody } from './PageBody';
import { PageRouter } from './PageRouter';
import { PageTabs } from './PageTabs';
import { BackButton } from './BackButton';
import { BreadcrumbListener, Breadcrumbs } from './Breadcrumbs';
import { buildStore } from 'helpers/zustand';
import { ShareButton, SHORT_KEY_PARAM } from './ShareButton';
import { SearchParams } from 'helpers/searchParams';

const initialState = {
  breadcrumb: null,
  // We want a state variable to check if we've loaded the page with a short url.
  // We should prevent loading the body of the page before we've swapped to the long search params.
  shortKeyExists: false,
};

export const { ContextProvider, useStore: usePageStore } = buildStore(initialState);

const PageComponent = ({ breadcrumb, children }) => {
  const { width, height } = useContext(WindowContext);

  const searchParams = new SearchParams();
  const shortKeyExists = searchParams.paramExists(SHORT_KEY_PARAM);

  
  return (
    <ContextProvider breadcrumb={breadcrumb} shortKeyExists={shortKeyExists}>
      <Container
        height={
          width <= theme.breakpoints.md
            ? `calc(100vh - ${theme.heights.topbar}px)`
            : height <= theme.breakpoints.xs
            ? '100vh'
            : '100vh'
        }
      >
        <BreadcrumbListener breadcrumb={breadcrumb} />
        <Column>{children}</Column>
      </Container>
    </ContextProvider>
  );
};

export const Page = Object.assign(PageComponent, {
  Header: PageHeader,
  Body: PageBody,
  Router: PageRouter,
  Tabs: PageTabs,
  BackButton: BackButton,
  ShareButton: ShareButton,
  Breadcrumbs: Object.assign(Breadcrumbs, {
    Listener: BreadcrumbListener,
  }),
});
