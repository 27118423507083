import { useQuery } from '@apollo/client';
import { PAGINATED_EP_QUERY } from 'apollo/queries/eliteprospects.queries';
import { GET_EP_STATS } from 'apollo/queries/stat.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { usePaginated } from 'components/bdd/Paginated';
import { roundToX } from 'helpers/data';
import { getRedGreenColor } from 'helpers/tables';
import { useMemo } from 'react';

export const useEPData = ({ query, gameType, targetGroup, sort, skip = false }) => {
  const { data, previousData, loading, error } = usePaginated({
    key: 'paginatedEpStats',
    defaultLimit: 10000,
    query: PAGINATED_EP_QUERY,
    skip: skip || !query,
    variables: {
      sort,
      query,
    },
  });

  const stats = (data || previousData)?.paginatedEpStats.data[0];
  const metastats = stats?.extras?.metastats;

  const { highlightCallback, formatStatByZScore } = useEPMetadata({
    stats,
    metastats,
    gameType,
    targetGroup,
  });

  return {
    stats,
    metastats,
    highlightCallback,
    formatStatByZScore,
    loading,
    error,
  };
};

export const useEPMetadata = ({ stats, metastats, gameType, targetGroup }) => {
  const metastatsForRow = useMemo(() => {
    if (!stats || !targetGroup || !metastats) return null;

    return stats.data.map((row) => {
      const league = row.league_slug;
      const season = row.season.split(/,+/).pop().trim();
      const game_type = gameType == 'null' ? null : gameType;
      const target_group =
        targetGroup == 'age' && row.age
          ? 'age_' + row.age.toString().split(/,+/).pop().trim()
          : 'league';
      const position = row.position == 'skaters' ? null : row.position;

      return metastats.filter(
        (m) =>
          m.league_slug == league &&
          m.season == season &&
          m.game_type == game_type &&
          m.target_group == target_group &&
          m.position == position
      );
    });
  }, [metastats, targetGroup]);

  const getMetastatsForSlug = (slug, index) => {
    const metastats = metastatsForRow[index];

    const stat_slug = slug.split('/')[0];
    const norm = slug.includes('/GP') ? 'GP' : null;

    return metastats.filter((m) => m.norm == norm).find((m) => m.stat_slug == stat_slug);
  };

  const highlightCallback = (slug, value, row) => {
    if (!stats || !metastats) return null;

    try {
      const slugToDef = {};
      stats.format.stat_definitions.forEach((s) => (slugToDef[s.slug] = s));
      const metastatsForSlug = getMetastatsForSlug(slug, stats.data.indexOf(row));

      const mean = metastatsForSlug.mean;
      const stddev = metastatsForSlug.stddev;

      return getRedGreenColor(
        value,
        mean - 2 * stddev,
        mean + 2 * stddev,
        slugToDef[slug].negative,
        2
      );
    } catch (e) {
      return null;
    }
  };

  const formatStatByZScore = (value, slug, row) => {
    if (!stats || !metastats) return null;
    if (slug == 'GP') return value;

    const metastatsForSlug = getMetastatsForSlug(slug, stats.data.indexOf(row));
    if (!metastatsForSlug) {
      return null;
    }

    const mean = metastatsForSlug.mean;
    const stddev = metastatsForSlug.stddev;
    if (!stddev) return 0;

    const zScore = (value - mean) / stddev;
    return roundToX(zScore, 2);
  };

  return {
    formatStatByZScore,
    highlightCallback,
  };
};
