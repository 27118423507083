import React, { useEffect } from 'react'
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { theme } from 'constants';
import BDDInputField from 'components/form/bddinputfield';
import BDDSelectField from 'components/form/bddselectfield';
import { Button, Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { GET_SL_GAMES } from 'apollo/queries/game.queries';
import SeasonSelect from 'components/filters/seasonselect';
import { GET_TEAMS_BY_LEAGUE } from "apollo/queries/teams.queries";
import { GET_SL_EVENT_NAMES } from "apollo/queries/events.queries";

const StyledForm = styled(Form)({
  margin: theme.spacing[5],
  '.row': {
    marginTop: theme.spacing[2]
  }
});

const GameSelect = ({ leagueSlug, season, teamSlug }) => {
  const { data, loading, error } = useQuery(GET_SL_GAMES, { variables: { leagueSlug, season, teamSlug } });
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (!!data && data.slGames.length > 0) {
      const demoFilt = data.slGames.filter(g => g.id === '2021202220020')
      if (demoFilt.length) setFieldValue('gameUid', demoFilt[0].id)
      else setFieldValue('gameUid', data.slGames[0].id);
    }
  }, [data])
  return (
    <Field
      name="gameUid"
      component={BDDSelectField}
      isLoading={loading}
      options={!!data ? data.slGames.map(g => ({ 
        value: g.id, 
        label: <><small>{g.gameDate}</small>{' '}
          <span style={{fontWeight: g.winner === g.awayTeamSlug ? 'bold' : 'normal'}}>{g.awayTeam.shorthand} {g.awayScore}</span> @{' '}
          <span style={{fontWeight: g.winner === g.homeTeamSlug ? 'bold' : 'normal'}}>{g.homeTeam.shorthand} {g.homeScore}</span>
        </>
      })) : []}
      placeholder='Games'
    />
  );
}

const TeamsSelect = ({ leagueSlug }) => {
  const { data, loading, error } = useQuery(GET_TEAMS_BY_LEAGUE, { variables: { league: leagueSlug } });
  return (
    <Field
      name="teamSlug"
      component={BDDSelectField}
      isLoading={loading}
      options={
        !!data
          ? data.slTeams.map((t) => ({ value: t.slug, label: t.longname }))
          : []
      }
      placeholder="Teams"
    />
  );
}

const EventNameSelect = () => {
  const { data, loading } = useQuery(GET_SL_EVENT_NAMES);
  return (
    <Field
      name='eventName'
      component={BDDSelectField}
      isLoading={loading}
      options={!!data ? data.slEventNames.map(e => ({ value: e.name, label: e.label })) : []}
      placeholder='Event'
      isClearable={false}
    />
  );
}


export default function EventFilters({ 
  mode, // 'byGame', 'byPlayer', 'fullGame'
  onSubmit,
  submitText='Load Events'
}) {
  const schema = mode === 'byGame' ? Yup.object({
      leagueSlug: Yup.string(),
      season: Yup.number(),
      teamSlug: Yup.string().nullable(),
      gameUid: Yup.string().required('You must select a game to load events'),
      eventName: Yup.string().required('You must select an event to load events'),
    }) : mode === 'fullGame' ? Yup.object({
      leagueSlug: Yup.string(),
      season: Yup.number(),
      teamSlug: Yup.string().nullable(),
      gameUid: Yup.string().required('You must select a game'),
      period: Yup.number().required('You must select a period')
    }) : null
  
  const initialValues = {
    leagueSlug: 'NHL',
    season: 20212022,
    teamSlug: 'nhl.bos',
    gameUid: '2021202220020',
  }
  if (mode === 'byGame') {
    initialValues.eventName = 'shot'
  }
  if (mode === 'fullGame') initialValues.period = 1

  const showFilter = {
    leagueSlug: true,
    season: true,
    teamSlug: true,
    gameUid: true,
    eventName: mode === 'byGame',
    period: mode === 'fullGame',
  }

  const submitEventFilters = (values) => {
    const v = {...values}
    delete v['teamSlug'] // we don't filter on teamSlug (that's just for setting game options)
    onSubmit(v);
  }

  return <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitEventFilters}>
    {({ values, handleSubmit, handleChange }) => {
      return (
        <StyledForm>
          <Row>
            <Col>
              <Field
                name="leagueSlug"
                placeholder="League"
                options={[{ value: 'NHL', label: 'NHL' }, { value: 'AHL', label: 'AHL' }]}
                component={BDDSelectField}
                isClearable={false}
              />
            </Col>
            <Col>
              <SeasonSelect 
                name="season"
                placeholder='Season'
                value={values.season}
                onChange={(n,v) => handleChange({ target: { name: 'season', value: v } })}
                useBDDSelect
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <TeamsSelect leagueSlug={values.leagueSlug} />
            </Col>
          </Row>
          <Row>
            <Col>
              <GameSelect leagueSlug={values.leagueSlug} season={values.season} teamSlug={values.teamSlug}/>
            </Col>
          </Row>
          {showFilter.eventName && <Row>
            <Col>
              <EventNameSelect />
            </Col>
          </Row>}
          {showFilter.period && <Row>
            <Col>
              <Field
                name="period"
                placeholder="Period"
                options={[{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }, { value: 4, label: '4' }]}
                component={BDDSelectField}
                isClearable={false}
              />
            </Col>
          </Row>}
          <Row>
            <Col>
            <center>
              <Button variant='warning' onClick={handleSubmit}>
                {submitText}
              </Button>
            </center>
            </Col>
          </Row>
        </StyledForm>
      );
    }}
  </Formik>

}