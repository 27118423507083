import { range } from './data'
import { getCurrentSeason, getNextOrCurrentSeason, getSeasonPlusMinusYears, season8to4 } from "./hockeyutils"

export const NULL_FILTER_VALUE = 'None'


// If filters have dates in them (start, end with cond: '>=' and '<=' resp.), return them, else [null, null]
export const filtersToDates = filters => {
    const startDateL = !!filters ? filters.filter(f => f.col === 'game_date' && f.cond === '>=') : []
    const startDate = startDateL.length ? startDateL[0].val : null
    const endDateL = !!filters ? filters.filter(f => f.col === 'game_date' && f.cond === '<=') : []
    const endDate = endDateL.length ? endDateL[0].val : null    
    return [startDate, endDate]
}


// Set of report, manpower, strength, norm where metastats are stored (hardcoded for now)
const metastatsRequests = [{stats: 'playersOverview', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'offense', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'defense', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'gameInvolvement', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'transitionPossession', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'transitionPassing', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'powerPlayer', manpower_situation: 'ES', strength: 55, norm: 20},
                    {stats: 'penaltyKill', manpower_situation: 'ES', strength: 55, norm: 20}]

export function filtersToParams(filters) {
    var params = {}

    // Straight mapped keys
    let copyKeys = ['season', 'game_type', 'norm', 'stats', 'period', 'differential',
                'start_date', 'end_date', 'predicted_position', 'position', 'statNames']
    copyKeys.forEach(key => {
        // if they're multikeys, we comma join them
        if (filters[key] instanceof Array && filters[key].length > 0) {
            params[key] = filters[key].join(',')
        }
        else if (filters[key] && filters[key] !== NULL_FILTER_VALUE) {
            params[key] = filters[key]
        }
    })

    if ('strength' in filters) {
        if (filters['strength'] !== NULL_FILTER_VALUE) {
            if (filters['strength'].length == 2) {
                params['manpower_situation'] = filters['strength']
            } else if (filters['strength'].length == 4) {
                params['manpower_situation'] = filters['strength'].substring(0, 2)
                params['strength'] = filters['strength'].substring(2, 4)
            }
        }
    }
    if ('averages' in filters && filters['averages'] !== NULL_FILTER_VALUE &&
        'norm' in filters && filters['norm'] !== NULL_FILTER_VALUE) {
        var useMetastats = false
        // Check each metastatsRequest filter set
        metastatsRequests.forEach(filts => {
            // If we have each filter and values match, then we req. metastats
            if (Object.keys(filts).reduce((acc, currKey) => {
                return acc & (currKey in params && params[currKey] == filts[currKey])
            }, true)) {
                useMetastats = true
                params['metastats'] = filters['averages'] == 'league' ? 'league' : 'team'
            }
        })
        if (!useMetastats) { params['averages'] = filters['averages'] }
    }
    return params
}

export function getLeagueChoices(withData=true) {
    return [
        {label: 'NHL', value: 'NHL'},
        {label: 'AHL', value: 'AHL'},
        {label: 'OHL', value: 'OHL'},
        {label: 'QMJHL', value: 'QMJHL'},
        {label: 'WHL', value: 'WHL'},
        {label: 'USHL', value: 'USHL'},
        {label: 'SHL', value: 'SHL'},
        {label: 'NCAA', value: 'NCAA'},
        {label: 'Allsvenskan', value: 'ALL'},
        {label: 'MHL', value: 'MHL'},
        {label: 'Sweden J20', value: 'SUPE'},
        {label: 'Liiga', value: 'SML'},
        {label: 'AJHL', value: 'AJHL'},
        {label: 'BCHL', value: 'BCHL'},
        {label: 'OJHL', value: 'OJHL'},
        {label: 'VHL', value: 'VHL'},
        {label: 'CZE', value: 'CZE'},
        {label: 'KHL', value: 'KHL'},
        {label: 'SM-Sarja U20', value: 'NSML'},
        {label: 'Tipos Extraliga', value: 'TIPS'},
        {label: 'Mestis', value: 'MES'},
        {label: 'EHL', value: 'EHL'},
        {label: 'NL', value: 'NL'},
        {label: 'SWEJ18', value: 'SWEJ18'},
        {label: 'PREP', value: 'PREP'},
        {label: 'KAR', value: 'KAR'},
        {label: 'SC', value: 'SC'},
        {label: 'WC', value: 'WC'},
        {label: 'WJC', value: 'WJC'},
        {label: 'Hlinka Gretzky', value: 'HG'},
        {label: 'IIHF U18', value: 'IIHFU18'},
        {label: '5NAT U18', value: '5NAT'},
        {label: '5NAT U20', value: '5NATU20'},
        {label: '4NAT U20', value: '4NATU20'},
        {label: '3NAT', value: '3NAT'},
        {label: 'World Junior A', value: 'WJAC'},
        {label: 'Olympics', value: 'OLY'},

    ]
}

const CF_NHL_START_SEASON = 20002001
const REST_START_SEASON = 20182019
const AHL_START_SEASON = 20162017
const NHL_START_SEASON = 20152016
export function getSeasonChoices(league='NHL', cfSeasons) {
    const startSeason = league === 'NHL' ? cfSeasons? CF_NHL_START_SEASON : NHL_START_SEASON
        : league === 'AHL' ? AHL_START_SEASON
        : REST_START_SEASON
    const currSeason = getNextOrCurrentSeason()
    var season = startSeason 
    const ret = []
    while (season <= currSeason) {
        ret.push({ label: season8to4(season, true), value: season })
        season = getSeasonPlusMinusYears(season, 1)
    }
    return ret.reverse()
}

export function getFutureSeasonChoices(numYears) {
  const currentSeason = getNextOrCurrentSeason()

  return range(1, numYears).map(year => {
    const season = getSeasonPlusMinusYears(currentSeason, year)
    return {
      label: season8to4(season, true), value: season
    }
  })
}

export function getGameTypeChoices(league_mode) {
  if (!league_mode) return []
    switch (league_mode.toLowerCase()) {
        case 'nhl': {
            return [
                {label: 'Regular Season', value: 'nhl_regular'},
                {label: 'Preseason', value: 'nhl_preseason'},
                {label: 'Postseason', value: 'nhl_postseason'}
            ]
        } default: { 
            return [{label: league_mode, value: league_mode.toLowerCase()}] 
        }
    }
}

export function getGameTypeChoicesFromLeagues(leagueOptions) {
    return [
      {
        label: 'Regular Season',
        value: 'nhl_regular,ahl',
        // value: leagueOptions.map((l) =>
        //   l.value == 'NHL' ? 'nhl_regular' : l.value.toLowerCase()
        // ),
      },
      { label: 'Preseason', value: 'nhl_preseason' },
      { label: 'Postseason', value: 'nhl_postseason' },
    ];
}

export function getDefaultGameType(league_mode) {
  if (!league_mode) return league_mode
  switch (league_mode.toLowerCase()) {
      case 'nhl': {
          return 'nhl_regular'
      } default: { return league_mode.toLowerCase() }
  }   
}

export function getStrengthChoices(precalc=false) {
    if (precalc) {
        return [{label: 'All Sit.', value: NULL_FILTER_VALUE},
                {label: 'Even Strength', value: 'ES'},
                {label: '5v5', value: 'ES55'},
                {label: 'Power Play', value: 'PP'},
                {label: 'Penalty Kill', value: 'SH'}]
    }
    return [
      {
        label: '',
        options: [{ label: 'All Sit.', value: 'None' }],
      },
      {
        label: 'Even Strength',
        options: [
          { label: 'All ES', value: 'ES', oppValue: 'ES' },
          { label: '5v5', value: 'ES55', oppValue: 'ES55' },
          { label: '4v4', value: 'ES44', oppValue: 'ES44' },
          { label: '3v3', value: 'ES33', oppValue: 'ES33' },
          { label: '6v5', value: 'ES65', oppValue: 'ES56' },
          { label: '5v6', value: 'ES56', oppValue: 'ES65' },
        ],
      },
      {
        label: 'Power Play',
        options: [
          { label: 'All PP', value: 'PP', oppValue: 'SH' },
          { label: '5v4', value: 'PP54', oppValue: 'SH45' },
          { label: '5v3', value: 'PP53', oppValue: 'SH35' },
          { label: '4v3', value: 'PP43', oppValue: 'SH34' },
          { label: '6v4', value: 'PP64', oppValue: 'SH46' },
          { label: '6v3', value: 'PP63', oppValue: 'SH36' },
        ],
      },
      {
        label: 'Short Handed',
        options: [
          { label: 'All SH', value: 'SH', oppValue: 'PP' },
          { label: '4v5', value: 'SH45', oppValue: 'PP54' },
          { label: '3v5', value: 'SH35', oppValue: 'PP53' },
          { label: '3v4', value: 'SH34', oppValue: 'PP43' },
          { label: '4v6', value: 'SH46', oppValue: 'PP64' },
        ],
      },
    ];
}


export function getPositionChoices() {
    return [{label: 'All Positions', value: NULL_FILTER_VALUE},
            {label: 'F', value: 'f'},
            {label: 'D', value: 'd'}]
}

export function getLocationChoises() {
  return [{label: 'All', value: NULL_FILTER_VALUE},
          {label: 'Home', value: 'home'},
          {label: 'Away', value: 'away'}]
}

export function getDetailedPositionChoices(includeGoalies=false) {
  const ret = [
    {label: 'Forward', options: [
      { label: 'LW', value: 'leftwing' },
      { label: 'C', value: 'center' },
      { label: 'RW', value: 'rightwing' },
    ]},
    {label: 'Defense', options: [
      { label: 'LD', value: 'leftdefence' },
      { label: 'RD', value: 'rightdefence' },
  ]}]
  if (includeGoalies) {
    ret.push({label: 'Goalie', options: [
      { label: 'G', value: 'goalie' },
    ]})
    return ret;
  }
}

export const parseStrength = (strength, camelCase=true) => {
  // "ES55" -> { manpower_situation, strength }
  const mpKey = camelCase ? 'manpowerSituation' : 'manpower_situation'
  if (strength === NULL_FILTER_VALUE || !strength) return {};
  if (strength.length === 4) {
    return { [mpKey]: strength.slice(0, 2), strength: strength.slice(2) };
  } else if (strength.length === 2) {
    return { [mpKey]: strength }
  }
  return {};
}

export const strengthToFilters = (strength) => {
  // "ES55" -> [{ col: manpower_situation, val }, {col: strength, val }]
  if (strength === NULL_FILTER_VALUE || !strength) return [];
  if (strength.length === 4) {
    return [
      { col: 'manpower_situation', val: strength.slice(0, 2) },
      { col: 'strength', val: strength.slice(2) }
    ]
  } else if (strength.length === 2) {
    return [{ col: 'manpower_situation', val: strength }]
  }
  return [];
}

export const strengthFiltersToLabel = (filters) =>{
  const mpsit = 'manpower_situation' in filters 
    ? filters['manpower_situation'] 
    : 'manpowerSituation' in filters
    ? filters['manpowerSituation']
    : null;
  const strength = filters.strength;
  if (strength) {
    return `${strength}`[0] + 'v' + `${strength}`[1];
  } else if (mpsit) {
    return mpsit;
  } else {
    return 'All Sit.';
  }

}

export function getNormChoices() {
    return [{label: 'Totals', value: NULL_FILTER_VALUE},
            {label: '20', value: 20},
            {label: '60', value: 60},
            {label: 'GP', value: 'gp'}]
}

export function getPeriodChoices() {
    return [{label: 'All Periods', value: NULL_FILTER_VALUE},
            {label: '1st', value: 1},
            {label: '2nd', value: 2},
            {label: '3rd', value: 3},
            {label: 'OT', value: 4}]
}

export function getDifferentialChoices() {
    return [{label: '3+', value: '3+'},
            {label: '+2', value: 2},
            {label: '+1', value: 1},
            {label: 'Tied', value: 0},
            {label: '-1', value: -1},
            {label: '-2', value: -2},
            {label: '3-', value: '3-'}
        ]
}

export function getPredictedPositionChoices() {
    return [{label: 'Any', value: NULL_FILTER_VALUE},
            {label: 'LW', value: 'leftwing'},
            {label: 'C', value: 'center'},
            {label: 'RW', value: 'rightwing'},
            {label: 'LD', value: 'leftdefence'},
            {label: 'RD', value: 'rightdefence'}
        ]
}

export function getIndexChoices(target='Player') {
    if (['Player', 'skaters', 'goalies'].includes(target)) {
        return [{label: 'Player', value: ['Player'].join(',')},
            {label: 'Player, Season', value: ['Player', 'league_slug', 'season'].join(',')},
            {label: 'Player, Team', value: ['Player', 'team', 'team_slug'].join(',')},
            {label: 'Player, Season, Team', value: ['Player', 'season', 'league_slug', 'team', 'team_slug'].join(',')}
        ]
    } else if (['teams'].includes(target)) {
        return [
          {label: 'Team', value: 'team'},
          {label: 'Team, Opponent', value: ['team', 'league_slug', 'opp_team'].join(',')},
          {label: 'Team, Season', value: ['team', 'league_slug', 'season'].join(',')},
        ]
    }
}

export function getSmoothOptions() {
    return [
        {label: 'None', value: 1},
        {label: 'Light', value: 3},
        {label: 'Medium', value: 9},
        {label: 'Heavy', value: 21}
    ]
}

export function getExtraPlayerInfoChoices(league) {
    const ret = [
        {label: 'Current Team', value: 'currentteam_shorthand'},
        {label: 'Position', value: 'position'},
        {label: 'Age', value: 'age'}, 
        {label: 'Height', value: 'height'},
        {label: 'Weight', value: 'weight'},
        {label: 'Detailed Position', value: 'position_detail'},
        {label: 'Handedness', value: 'handedness'},
        {label: 'Draft Eligible', value: 'isdrafteligible'},
        {label: 'Draft Year Offset', value: 'draft_year_offset'},
        {label: 'Draft Year', value: 'draftyear'},
        {label: 'Draft Round', value: 'draft_round'},
        {label: 'EliteProspects ID', value: 'eliteprospectsid'},
        {label: 'Rinknet ID', value: 'rinknet_id'},
        {label: 'SL UID', value: 'sl_uid'},

    ]
    if (league === 'NHL') ret.push(...[
        {label: 'AAV', value: 'AAV'},
        {label: 'Cap Hit', value: 'cap_hit'},
        {label: 'Free Agent Year', value: 'free_agent_year'},
        {label: 'Expiry Status', value: 'expiry_status'}
    ])
    return ret
}

export function getBDDPlayerReportChoices() {
    return [
        {label: 'Amateur Draft', value: 'draft'},
        {label: 'Underage Draft', value: 'underage'},
        {label: 'College Free Agent', value: 'college'},
        {label: 'Euro Free Agent', value: 'euro'},
        {label: 'Reserve List', value: 'reservelist'},
        {label: 'North American Pro', value: 'napro'}
    ]
}

export function getSeasonPhaseChoices() {
  return [
    { label: 'In-season', value: 'INSEASON' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Offseason', value: 'OFFSEASON' },
    { label: 'Post-deadline', value: 'POSTDEADLINE' },
  ]
}

export function qToDescription(q, target='') {
    if (!q) return null
    if (!(q instanceof Object)) return null
    var fo = {}
    if ('filters' in q) {
        if (Array.isArray(q.filters)) {
            q.filters.forEach(f => fo[f.col] = f.val)
        } else {
            var fo = q.filters
        }
    }

    var retArr = []
    if ('season' in fo) {
        if (Array.isArray(fo.season)) {
            const seasArr = fo.season.map(s => parseInt(s))
            if (seasArr.length > 1) {
                retArr.push(`${season8to4(Math.min(...seasArr))}-${season8to4(Math.max(...seasArr))}`)
            } else {
                retArr.push(`${season8to4(seasArr[0], true)}`)
            }
        } else {
            retArr.push(season8to4(fo.season, true))
        }
    }
    if ('strength' in fo) {
        retArr.push(fo.strength.toString()[0] + 'v' + fo.strength.toString()[1])
    } else if ('manpower_situation' in fo) {
        retArr.push(fo.manpower_situation)
    }
    
    retArr.push(`${target.charAt(0).toUpperCase() + target.slice(1)}${target.length ? ' ' : ''}Stats`)
    if ('norm' in q && q.norm instanceof Number) {
        retArr.push(`per ${q.norm.toString().toUpperCase()}`)
    }
    retArr.push()
    return retArr.join(' ')
}