import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const FORM_PERSIST_KEY = `form-persist`;

export const isFormPersisted = (customKey) => {
  return !!localStorage.getItem(`${FORM_PERSIST_KEY}-${customKey}`);
};

export const useFormPersist = ({ key: customKey, values, setValues, isSubmitting }) => {
  const [status, setStatus] = useState();
  const { pathname } = useLocation();
  const key = `${FORM_PERSIST_KEY}-${customKey || pathname}`;

  const storedValues =
    !!localStorage.getItem(key) && JSON.parse(localStorage.getItem(key));

  useEffect(() => {
    if (!status && storedValues) {
      setValues?.(storedValues);
    }

    setStatus('initialized');
  }, [status]);

  useEffect(() => {
    if (!isSubmitting) return;
    localStorage.removeItem(key);
  }, [isSubmitting]);

  useEffect(() => {
    if (!!status) {
      localStorage.setItem(key, JSON.stringify(values));
    }
  }, [values]);

  return {
    status,
    storedValues,
    resetFormPersist: () => {
      localStorage.removeItem(key);
    },
  };
};
