import { useQuery } from '@apollo/client';
import { GET_RINKNET_LEAGUES } from 'apollo/queries/league.queries';
import { useSelect } from 'components/bdd/Select';
import usePlaceholder from 'components/Placeholder/usePlaceholder';

export const useRinknetLeagueSelect = ({ initialLeagueIds, selectProps={} } = {}) => {
  const { data: rinknetLeagueData } = usePlaceholder(
    useQuery(GET_RINKNET_LEAGUES)
  );

  const leagueOptions = rinknetLeagueData?.rinknetLeagues
    ?.map((l) => ({
      value: l.id_,
      label: l.name,
      priority: l.priority,
    }))
    .sort((a, b) => b.priority - a.priority);

  const { select, selected } = useSelect({
    options: leagueOptions || [],
    initialSelectedValue: initialLeagueIds,
    selectProps: {
      isLoading: !leagueOptions,
      isMulti: true,
      placeholder: 'Filter leagues...',
      isSearchable: true,
      ...selectProps
    },
  });

  const getMultiLeagueFilters = (col) =>
    selected && selected.length > 0
      ? [
          {
            cond: 'isin',
            col,
            val: selected.map((s) => s.value),
          },
        ]
      : [];

  return {
    rinknetLeagueSelect: select,
    rinknetLeagueSelected: selected,
    getMultiLeagueFilters,
  };
};
