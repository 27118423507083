import { toiSecToString } from 'helpers/data';
import { SkillHighlightBoxes } from './components';

const MINIMUM_TOI_PER_GP = 20;

const getToiPerGP = (rankings, manpowerSituation) => {
  const toiPerGP = rankings.find(
    (r) =>
      r.stat == 'toiPerGP' && r.filterSet.manpowerSituation == manpowerSituation
  );

  return toiPerGP?.value || 0;
};

const checkForToiPerGP = (rankings, manpowerSituation) => {
  return getToiPerGP(rankings, manpowerSituation) <= MINIMUM_TOI_PER_GP;
};

const overviewCategory = {
  hidden: true,
  statDefinitions: [
    {
      label: 'TOI',
      flatLabel: 'TOI',
      slug: 'toi',
    },
  ],
};

const goalieCategories = [
  {
    label: 'Overall',
    statDefinitions: [
      {
        label: 'Wins',
        flatLabel: 'Wins',
        slug: 'Win',
      },

      {
        label: 'SV%',
        flatLabel: 'SV%',
        slug: 'savePercentage',
      },
      {
        label: 'GSAA',
        flatLabel: 'GSAA',
        slug: 'goalsSavedAboveAverage',
      },
      {
        label: 'HDSV%',
        flatLabel: 'High Danger Save %',
        slug: 'highDangerSavePercentage'
      },
      {
        label: 'Rebound %',
        flatLabel: 'Rebound %',
        slug: 'dangerousReboundsGivenUpRate',
      },
      {
        label: 'Puck Play %',
        flatLabel: 'Puck Play %',
        slug: 'goaliePuckPlayPercentage',
      },
      {
        label: 'Puck Play Att',
        flatLabel: 'Puck Play Att',
        slug: 'goaliePuckPlayAttempts'
      }
    ],
  },
  {
    label: 'GBR',
    statDefinitions: [
      {
        label: 'GBR',
        flatLabel: 'GBR',
        slug: 'GBR',
      },
      {
        label: 'Quality %',
        flatLabel: 'gbrQualityGamePct',
        slug: 'gbrQualityGamePct',
      },
      {
        label: 'Extreme %',
        flatLabel: 'gbrExtremeGamePct',
        slug: 'gbrExtremeGamePct',
      },
      {
        label: 'Excellent %',
        flatLabel: 'gbrGoodGamePct',
        slug: 'gbrGoodGamePct',
      },
    ]
  },
  {
    label: 'Support',
    statDefinitions: [
      {
        label: 'Goals For',
        flatLabel: 'Goals For',
        slug: 'goalsFor',
      },
      {
        label: '% DZ Time',
        flatLabel: '% DZ Time',
        slug: 'percentageDZTime', // NOTE we want Green to mean harder for the goalie
        negative: true
      },
      {
        label: 'Workload',
        flatLabel: 'Workload',
        slug: 'saves',
      },
      {
        label: 'Difficulty',
        flatLabel: 'Difficulty',
        slug: 'expectedSavePercentage',
      },
      {
        label: 'High Dangers',
        flatLabel: 'High Dangers',
        slug: 'shotsOnNetAgainstHighDanger',
        negative: true
      }
    ]
  },
  {
    label: '5v5',
    statDefinitions: [
      {
        label: 'GSAA',
        flatLabel: '5v5 GSAA',
        slug: 'goalsSavedAboveAverage',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'Workload',
        flatLabel: '5v5 Workload',
        slug: 'saves',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'Difficulty',
        flatLabel: '5v5 Difficulty',
        slug: 'expectedSavePercentage',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'HDSV%',
        flatLabel: '5v5 High Danger Save %',
        slug: 'highDangerSavePercentage',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'Rebound %',
        flatLabel: '5v5 Rebound %',
        slug: 'dangerousReboundsGivenUpRate',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'Puck Play %',
        flatLabel: '5v5 Puck Play %',
        slug: 'goaliePuckPlayPercentage',
        manpowerSituation: 'ES',
        strength: 55
      },
      {
        label: 'Puck Play Att',
        flatLabel: '5v5 Puck Play Att',
        slug: 'goaliePuckPlayAttempts',
        strength: 55,
        manpowerSituation: 'ES',
      }
    ],
  },
  {
    label: 'Penalty Kill',
    statDefinitions: [
      {
        label: 'GSAA',
        flatLabel: 'SH GSAA',
        slug: 'goalsSavedAboveAverage',
        manpowerSituation: 'SH',
      },
      {
        label: 'Workload',
        flatLabel: 'SH Workload',
        slug: 'saves',
        manpowerSituation: 'SH',
      },
      {
        label: 'Difficulty',
        flatLabel: 'SH Difficulty',
        slug: 'expectedSavePercentage',
        manpowerSituation: 'SH',
      },
      {
        label: 'HDSV%',
        flatLabel: 'SH High Danger Save %',
        slug: 'highDangerSavePercentage',
        manpowerSituation: 'SH'
      },
      {
        label: 'Rebound %',
        flatLabel: 'SH Rebound %',
        slug: 'dangerousReboundsGivenUpRate',
        manpowerSituation: 'SH',
      },
      {
        label: 'Puck Play %',
        flatLabel: 'SH Puck Play %',
        slug: 'goaliePuckPlayPercentage',
        manpowerSituation: 'SH',
      },
      {
        label: 'Puck Play Att',
        flatLabel: 'SH Puck Play Att',
        slug: 'goaliePuckPlayAttempts',
        manpowerSituation: 'SH',
      }
    ],
  },
];

const categories = [
  {
    label: 'Offense',
    statDefinitions: [
      {
        label: 'WOI',
        flatLabel: 'XPGF',
        slug: 'XPGF',
      },
      {
        label: 'Inv',
        flatLabel: '%SCGP',
        slug: 'percentageSCGP',
      },
      {
        label: 'Pass',
        flatLabel: 'iXA',
        slug: 'iXA',
      },
      {
        label: 'Shoot',
        flatLabel: 'iXG',
        slug: 'iXG',
      },
      {
        label: 'Finish',
        flatLabel: 'A2E',
        slug: 'Act2Expected',
      },
      {
        label: '% OZ Poss',
        flatLabel: '% OZ Poss',
        slug: 'percentOZPossession',
      },
    ],
  },
  {
    label: 'Defense',
    statDefinitions: [
      {
        label: 'WOI',
        flatLabel: 'XPGA',
        slug: 'XPGA',
      },
      {
        label: 'Inv',
        flatLabel: '% DPlays',
        slug: 'percentageDPlays',
      },
      {
        label: 'DPlays',
        flatLabel: 'DPlays',
        slug: 'defensivePlays',
      },
      {
        label: 'Denials',
        flatLabel: 'Denials',
        slug: 'controlledEntryDenialRate',
        position: 'd',
      },
      {
        label: 'OZ Take',
        flatLabel: 'OZ Take',
        slug: 'ozTakeaways',
        position: 'f',
      },
      {
        label: 'Battles',
        flatLabel: 'Battles',
        slug: 'contestedLPRAttempts',
      },
      {
        label: 'LPRS',
        flatLabel: 'LPRS',
        slug: 'LPRs',
      },
    ],
  },
  {
    label: 'Puck Play',
    statDefinitions: [
      {
        label: 'WOI',
        flatLabel: '% OZ Time',
        slug: 'percentageOZTime',
      },
      {
        label: 'Inv',
        flatLabel: '% PDPs',
        slug: 'percentagePDP',
      },
      {
        label: 'PDPs',
        flatLabel: 'PDPs',
        slug: 'PDPs',
      },
      {
        label: 'Entries',
        flatLabel: 'Entries',
        slug: 'controlledEntryAttempts',
      },
      {
        label: 'NZDZ TO%',
        flatLabel: 'NZDZ TO%',
        slug: 'nzdzTurnoverRate',
      },
      {
        label: 'OZ TO%',
        flatLabel: 'OZ TO%',
        slug: 'ozTurnoverRate',
        position: 'f',
      },
      {
        label: 'Breakout',
        flatLabel: 'Breakout',
        slug: 'breakoutSuccessRateOffDumpIns',
        position: 'd',
      },
    ],
  },
  {
    label: 'Impact',
    statDefinitions: [
      {
        label: 'PBR',
        flatLabel: 'PBR',
        slug: 'pbrXPG',
      },
      {
        label: 'Impact',
        flatLabel: 'Impact',
        slug: 'impactXPG',
      },
      {
        label: 'Role',
        flatLabel: 'Role',
        slug: 'roleXPG',
      },
      {
        label: 'SOT',
        flatLabel: 'SOT',
        slug: 'SOT_iXP',
      },
      {
        label: 'SOO',
        flatLabel: 'SOO',
        slug: 'SOO_iXP',
      },
      {
        label: 'SDiff',
        flatLabel: 'SDiff',
        slug: 'SDiff_iXP',
      },
    ],
  },
  {
    label: 'Powerplay',
    getSubLabel: (rankings) =>
      `${toiSecToString(getToiPerGP(rankings, 'PP') || 0)} TOI/GP`,
    statDefinitions: [
      {
        label: 'Use',
        flatLabel: 'PP TOI/GP',
        slug: 'toiPerGP',
        manpowerSituation: 'PP',
      },
      {
        label: 'WOI',
        flatLabel: 'PP XPGF',
        slug: 'XPGF',
        manpowerSituation: 'PP',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'PP'),
      },
      {
        label: 'iXG',
        flatLabel: 'PP iXG',
        slug: 'iXG',
        manpowerSituation: 'PP',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'PP'),
      },
      {
        label: 'iXA',
        flatLabel: 'PP iXA',
        slug: 'iXA',
        manpowerSituation: 'PP',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'PP'),
      },
      {
        label: '% OZ Poss',
        flatLabel: 'PP % OZ Poss',
        slug: 'percentOZPossession',
        manpowerSituation: 'PP',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'PP'),
      },
      {
        label: 'Entries',
        flatLabel: 'PP Entries',
        slug: 'controlledEntryAttempts',
        manpowerSituation: 'PP',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'PP'),
      },
    ],
  },
  {
    label: 'Penalty Kill',
    getSubLabel: (rankings) =>
      `${toiSecToString(getToiPerGP(rankings, 'SH') || 0)} TOI/GP`,
    statDefinitions: [
      {
        label: 'Use',
        flatLabel: 'SH TOI/GP',
        slug: 'toiPerGP',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
      {
        label: 'WOI',
        flatLabel: 'SH XPGA',
        slug: 'XPGA',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
      {
        label: 'DPlays',
        flatLabel: 'SH DPlays',
        slug: 'defensivePlays',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
      {
        label: 'DZ LPRs',
        flatLabel: 'SH DZ LPRs',
        slug: 'dzLPRs',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
      {
        label: 'Dumpouts',
        flatLabel: 'SH Dumpouts',
        slug: 'dumpouts',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
      {
        label: 'DZ TO%',
        flatLabel: 'SH DZ TO%',
        slug: 'dzTurnoverRate',
        manpowerSituation: 'SH',
        hideStatDefinition: (rankings) => checkForToiPerGP(rankings, 'SH'),
      },
    ],
  },
];

const productionCategories = [
  {
    label: 'Production',
    statDefinitions: [
      {
        label: 'G',
        flatLabel: 'G',
        slug: 'goals',
      },
      {
        label: 'A',
        flatLabel: 'A',
        slug: 'assists',
      },
      {
        label: 'P',
        flatLabel: 'P',
        slug: 'points',
      },
      {
        label: 'GF',
        flatLabel: 'GF',
        slug: 'goalsFor',
      },
      {
        label: 'GA',
        flatLabel: 'GA',
        slug: 'goalsAgainst',
      },
    ],
  },
];

const deploymentCategories = [
  {
    label: 'Deployment',
    statDefinitions: [
      {
        label: 'OZS%',
        flatLabel: 'OZS%',
        slug: 'ozStartPercentage',
      },
      {
        label: 'SOO iXP',
        flatLabel: 'SOO iXP',
        slug: 'SOO_iXP',
      },
      {
        label: 'SOT iXP',
        flatLabel: 'SOT iXP',
        slug: 'SOT_iXP',
      },
      {
        label: 'SDiff iXP',
        flatLabel: 'SDiff iXP',
        slug: 'SDiff_iXP',
      },
    ],
  },
];


export const positionToSkillCategories = posFDG => {
  //lowercase position! fdg or all
  let ret;
  if (posFDG === 'all') ret = categories.concat(goalieCategories).concat([overviewCategory])
  if (posFDG === 'skaters') ret = categories
    .concat(deploymentCategories)
    .concat(productionCategories)
  if (posFDG === 'g') ret = goalieCategories.concat([overviewCategory])
  ret = ret.concat([overviewCategory])

  const defaultSit = posFDG === 'g' ? {} : { manpowerSituation: 'ES', strength: 55 }
  // set manpower and strength explicitly for each (ES 5v5)
  ret.forEach(c => {
    c.statDefinitions = c.statDefinitions.map(sd => {
      if ('manpowerSituation' in sd) return sd
      return {
        ...defaultSit,
        ...sd
      }
    })
  })
  return ret
}

export const SkillsOverview = ({ bddPlayer, leagueSlug, showPlaceholder=false }) => {
  const posFDG = bddPlayer?.rinknetPlayer.posFDG.toLowerCase();
  const categoriesToUse = (
    posFDG == 'g' ? goalieCategories : categories
  ).concat([overviewCategory]);

  const defaultSit = posFDG === 'g' ? {} : { manpowerSituation: 'ES', strength: 55 }
  // set manpower and strength explicitly for each (ES 5v5)
  categoriesToUse.forEach(c => {
    c.statDefinitions = c.statDefinitions.map(sd => {
      if ('manpowerSituation' in sd) return sd
      return {
        ...defaultSit,
        ...sd
      }
    })
  })

  return (
    <SkillHighlightBoxes
      bddPlayer={bddPlayer}
      leagueSlug={leagueSlug}
      categories={categoriesToUse}
      showPlaceholder={showPlaceholder}
    />
  );
};
