import { useQuery } from '@apollo/client';
import {
  FilterTag,
  Filters,
  useFilterContext,
  useFilterValues,
} from 'components/bdd/Filters';
import { useEPStatsContext } from '../context';
import { Column, Container, Row } from 'components/bdd/Layout';
import { GET_EP_STAT_FILTERS } from 'apollo/queries/stat.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useEffect, useState } from 'react';
import {
  DETAILED_POSITIONS,
  DRAFT_YEAR_OPTIONS,
  LEAGUES_TO_IGNORE_BY_LEVEL,
  LEAGUE_LEVEL_OPTIONS,
  NATIONALITY_OPTIONS,
  SEASON_OPTIONS,
} from '../constants';
import { Query } from 'components/bdd/Query';
import { QueryBuilder } from 'components/bdd/Query/Query';
import { Typography } from 'components/bdd/Typography';
import { getDraftEligibilityFilter, getStatQueryOptions } from '../helpers';
import { FiltersButton } from './FiltersButton';
import { useMultiPlayerSearch } from 'components/DraftApp/DraftPickSelection/SimulateDraftPicks';
import { GET_BDD_PLAYERS } from 'apollo/queries/players.queries';
import { useRegisterFilterTag } from 'components/bdd/Filters/Group';
import { LinkButton } from 'components/bdd/Button';
import { HoverInteractive } from 'components/bdd';
import { getFilterValues } from 'components/bdd/Filters/helpers';
import { getCurrentDraftYear, heightInchesToStr } from 'helpers/hockeyutils';
import { FilterOptionsLoader } from './FilterOptionsLoader';
import { BDD_LEAGUES } from 'components/navigation/Sidebar/LeagueSidebar';
import { QueryTagField } from 'components/bdd/Query/QueryTags';

export const StatFilters = ({ children, useSearchParams = true }) => {
  const filters = useEPStatsContext((state) => state.filters);
  const { setFilters } = useEPStatsContext((state) => state.actions);

  return (
    <Filters
      useSearchParams={useSearchParams}
      onChange={(updated) => {
        setFilters(updated);
      }}
      defaults={filters}
    >
      <FilterOptionsLoader />
      <FiltersButton>{children || <DefaultFilters />}</FiltersButton>
    </Filters>
  );
};

export const DefaultFilters = ({}) => {
  return (
    <Column gap={2}>
      <Row columnGap={3} alignItems="start" rowGap={1} flexWrap>
        <LeagueAndLevelFilters />
        <SeasonSelectAndRangeFilter />
        <TeamFilters />
        <GameTypeFilter />
        <GroupByFilter />
      </Row>
      <Row columnGap={3} alignItems="start" rowGap={1} flexWrap>
        <Row columnGap={3} alignItems="start" rowGap={1} flexWrap>
          <PlayerFilter />
          <AgeFilter />
          <HeightFilter />
          <WeightFilter />
          <DraftEligibleYearFilter />
          <NationalityFilter />
          <PositionFilter />
          <DetailedPositionsFilter />
        </Row>
      </Row>
      <StatQuery />
    </Column>
  );
};

export const AgeFilter = ({}) => {
  return (
    <Filters.InputRange
      id={'age'}
      label="Age"
      scale={{ min: 14, max: 47 }}
      unit={'yrs'}
      includeInputBoxes
      hideRange
    />
  );
};

export const HeightFilter = ({}) => {
  return (
    <Filters.InputRange
      id={'height_inches'}
      label="Height"
      scale={{ min: 66, max: 84 }}
      unit={'ft'}
      formatLabel={(value) => heightInchesToStr(value)}
      includeInputBoxes
      hideRange
    />
  );
};

export const WeightFilter = ({}) => {
  return (
    <Filters.InputRange
      id={'weight'}
      label="Weight"
      scale={{ min: 140, max: 300 }}
      unit={'lbs'}
      includeInputBoxes
      hideRange
    />
  );
};

export const PositionFilter = ({}) => {
  const filters = useEPStatsContext((state) => state.filters);
  const { setSort, applyFilters } = useEPStatsContext((state) => state.actions);
  const groupBy = useEPStatsContext((state) => state.groupBy);
  const { mapping, positionGroupByFilters } = useEPStatsContext(
    (state) => state.filterOptions
  );

  const options = positionGroupByFilters
    ? [...new Set(positionGroupByFilters.map((df) => df.position))].map((p) => ({
        value: p,
        label: mapping.position[p],
      }))
    : [];

  return (
    <Filters.Select
      width={150}
      id={'position'}
      options={options}
      loading={!positionGroupByFilters}
      label={'Position FDG'}
      onChange={(value) => {
        if (value != filters['position'].value) {
          setSort([{ id: value == 'G' ? 'stats_GP' : 'stats_PTS', desc: true }]);
          applyFilters();
        }
      }}
    />
  );
};

export const DetailedPositionsFilter = ({}) => {
  return (
    <Filters.Select
      width={200}
      id={'detailed_positions'}
      options={DETAILED_POSITIONS.map((p) => ({ value: p, label: p }))}
      cond={'includes'}
      selectProps={{
        isMulti: true,
      }}
      isClearable
      label={'Detailed Positions'}
    />
  );
};

export const GroupByFilter = ({}) => {
  const filters = useEPStatsContext((state) => state.filters);
  const groupBy = useEPStatsContext((state) => state.groupBy);
  const { mapping, positionGroupByFilters } = useEPStatsContext(
    (state) => state.filterOptions
  );
  const { setGroupBy } = useEPStatsContext((state) => state.actions);

  const position = filters['position']?.value || 'skaters';
  const options = positionGroupByFilters
    ? [...new Set(positionGroupByFilters.map((df) => df.group_by))].map((gb) => ({
        value: gb,
        label: mapping.group_by[gb],
      }))
    : [];

  return (
    <Filters
      useSearchParams
      searchParamKey={'groupBy'}
      defaults={{
        groupBy: {
          id: 'groupBy',
          type: 'select',
          value: groupBy,
        },
      }}
      onChange={(filters) => {
        setGroupBy(filters['groupBy'].value);
      }}
    >
      <Filters.Select
        width={150}
        id={'groupBy'}
        options={options}
        loading={!positionGroupByFilters}
        label={'Group By'}
      />
    </Filters>
  );
};

export const LeagueFilters = ({ subLabel, hide }) => {
  const filters = useFilterContext((state) => state.filters);
  const { removeFilter } = useFilterContext((state) => state.actions);
  const { leagueSeasonTeamFilters } = useEPStatsContext((state) => state.filterOptions);

  const options = leagueSeasonTeamFilters
    ? [
        ...new Set(
          [...leagueSeasonTeamFilters]
            .sort((a, b) => b.priority - a.priority)
            .map((df) => df.league_slug)
        ),
      ].map((l) => ({
        value: l,
        label: l.toUpperCase(),
      }))
    : [];

  return (
    <Filters.Select
      hide={hide}
      width={350}
      id={'league_slug'}
      options={options}
      loading={!leagueSeasonTeamFilters}
      isClearable
      label={'League'}
      subLabel={subLabel}
      cond="isin"
      selectProps={{
        isMulti: true,
      }}
      onChange={(value) => {
        if (value != filters['league_slug']?.value) {
          removeFilter('team_slug');
        }

        removeFilter('level');
      }}
    />
  );
};

export const SeasonFilters = ({ subLabel, hide }) => {
  const filters = useFilterContext((state) => state.filters);
  const { removeFilter } = useFilterContext((state) => state.actions);

  return (
    <Filters.Select
      hide={hide}
      width={300}
      id={'season'}
      subLabel={subLabel}
      options={SEASON_OPTIONS}
      isClearable
      label={'Seasons'}
      cond="isin"
      selectProps={{
        isMulti: true,
      }}
      onChange={(value) => {
        if (value != filters['season']?.value) {
          removeFilter('team_slug');
        }
      }}
    />
  );
};

export const TeamFilters = ({}) => {
  const filters = useFilterContext((state) => state.filters);
  const { mapping, leagueSeasonTeamFilters } = useEPStatsContext(
    (state) => state.filterOptions
  );

  const leagues = filters['league_slug']?.value || [];
  const seasons = filters['season']?.value || [];

  const options = leagueSeasonTeamFilters
    ? [
        ...new Set(
          leagueSeasonTeamFilters
            .filter((df) => leagues.includes(df.league_slug))
            .map((df) => df.team_id)
            .flat()
        ),
      ].map((t) => ({
        value: t,
        label: mapping.team_id[t],
      }))
    : [];

  if (!!leagueSeasonTeamFilters && options.length == 0) return null;
  return (
    <Filters.Select
      id={'team_id'}
      isClearable
      width={300}
      options={options}
      loading={!leagueSeasonTeamFilters}
      label={'Teams'}
      cond="isin"
      selectProps={{
        isMulti: true,
      }}
      subLabel={
        !!leagueSeasonTeamFilters && options.length == 0 ? (
          <Typography variant="stat" stat="danger">
            Select a league first
          </Typography>
        ) : null
      }
    />
  );
};

export const GameTypeFilter = ({}) => {
  const { mapping, gameTypeFilters } = useEPStatsContext((state) => state.filterOptions);

  const options = !!gameTypeFilters
    ? gameTypeFilters
        .map((gt) => ({
          value: gt,
          label: mapping.game_type[gt],
        }))
        .filter((gt) => !!gt.value)
    : [];

  return (
    <Filters.Select
      id={'game_type'}
      width={150}
      loading={!gameTypeFilters}
      options={options}
      label={'Game Type'}
      isClearable
    />
  );
};

export const DraftEligibleYearFilter = ({}) => {
  const { draftEligibleYearsRange } = useEPStatsContext((state) => state.filterOptions);

  const options = draftEligibleYearsRange
    ? Array.from(
        { length: draftEligibleYearsRange.max - draftEligibleYearsRange.min + 1 },
        (_, index) => ({
          value: draftEligibleYearsRange.min + index,
          label: draftEligibleYearsRange.min + index,
        })
      ).reverse()
    : [];

  return (
    <Filters.Select
      id={'draft_eligible_years'}
      isClearable
      width={250}
      options={options}
      loading={!draftEligibleYearsRange}
      label={'Draft Eligible Years'}
      cond="includes"
      selectProps={{
        isMulti: true,
      }}
    />
  );
};

export const NationalityFilter = ({}) => {
  return (
    <Filters.Select
      id={'nationality'}
      isClearable
      width={150}
      options={NATIONALITY_OPTIONS}
      label={'Nationality'}
      selectProps={{
        isMulti: true,
      }}
      cond="isin"
    />
  );
};

export const PlayerFilter = ({}) => {
  const filter = useFilterContext((state) => state.filters);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  const { data, loading } = useQuery(GET_BDD_PLAYERS, {
    skip: !filter['slug'] || filter['slug'].value.length == 0,
    variables: {
      slugs: filter['slug']?.value || [],
    },
  });

  const defaultSelectedPlayers = (filter['slug']?.value || []).map((sp) => {
    const bddPlayer = data?.bddPlayers.find((bdd) => bdd.slug == sp);

    return {
      value: sp,
      label: bddPlayer ? `${bddPlayer.firstname} ${bddPlayer.lastname}` : 'Loading...',
      key: sp,
    };
  });

  const { multiSearchComponent, selectedPlayerIds } = useMultiPlayerSearch({
    defaultSelectedPlayers,
    selectProps: {
      menuPlacement: 'auto',
    },
    idType: 'bdd_player_slug',
  });

  useEffect(() => {
    if (selectedPlayerIds.length > 0) {
      setFilters('slug', {
        id: 'slug',
        type: 'select',
        cond: 'isin',
        value: selectedPlayerIds,
      });
    } else {
      removeFilter('slug');
    }
  }, [selectedPlayerIds.join('')]);

  useRegisterFilterTag(
    'slug',
    defaultSelectedPlayers.length > 0 && (
      <FilterTag
        name={'Players'}
        label={defaultSelectedPlayers.map((sp) => sp.label).join(', ')}
      />
    )
  );

  return (
    <Container>
      <Typography variant="label">Player</Typography>
      {multiSearchComponent}
    </Container>
  );
};

const StatQuery = ({}) => {
  const { position } = useFilterValues();
  const statFilters = useEPStatsContext((state) => state.statFilters)?.filters;
  const { setStatFilters } = useEPStatsContext((state) => state.actions);
  const options = [...draftFilters, ...getStatQueryOptions({ position })];

  return (
    <Query defaults={statFilters} options={options}>
      <Query.Builder
        variant="horizontal"
        onChange={(filters, queryFilters) => {
          setStatFilters({ filters, queryFilters });
        }}
      />
    </Query>
  );
};

export const LeagueLevelFilter = ({ subLabel, hide }) => {
  const { removeFilter } = useFilterContext((state) => state.actions);
  const { leagueSeasonTeamFilters } = useEPStatsContext((state) => state.filterOptions);
  const { level: levels } = useFilterValues();

  const leagueNames = levels &&
    leagueSeasonTeamFilters &&
    levels.length > 0 && [
      ...new Set(
        levels.flatMap((level) =>
          leagueSeasonTeamFilters
            .filter((league) => league.level == level)
            .filter(
              (league) =>
                !(league.level in LEAGUES_TO_IGNORE_BY_LEVEL) ||
                !LEAGUES_TO_IGNORE_BY_LEVEL[league.level].includes(league.league_slug)
            )
            .sort((a, b) => b.priority - a.priority)
            .map((league) => league.league_slug.toUpperCase())
        )
      ),
    ];

  return (
    <Filters.Select
      hide={hide}
      id={'level'}
      isClearable
      width={350}
      options={LEAGUE_LEVEL_OPTIONS}
      label={'Level'}
      subLabel={
        <Row columnGap={2}>
          {leagueNames && (
            <HoverInteractive
              placement="top"
              content={
                <Container maxWidth={300}>
                  <Row columnGap={2} flexWrap>
                    {leagueNames.map((ln) => (
                      <Typography key={ln} variant="label">
                        {ln.toUpperCase()}
                      </Typography>
                    ))}
                  </Row>
                </Container>
              }
            >
              <LinkButton>
                <Typography variant="label">({leagueNames.length} Leagues)</Typography>
              </LinkButton>
            </HoverInteractive>
          )}
          {subLabel}
        </Row>
      }
      selectProps={{
        isMulti: true,
      }}
      cond="isin"
      onChange={(value) => {
        removeFilter('league_slug');
        removeFilter('team_slug');
      }}
    />
  );
};

export const LeagueAndLevelFilters = ({}) => {
  const { league_slug: leagues, level: levels } = useFilterValues();

  const [showLevelFilters, setShowLevelFilters] = useState(!!levels);

  const toggle = (
    <LinkButton onClick={() => setShowLevelFilters(!showLevelFilters)}>
      <Typography variant="stat" state="link">
        <b>{showLevelFilters ? 'Filter by league' : 'Filter by league level'}</b>
      </Typography>
    </LinkButton>
  );

  return (
    <>
      <LeagueLevelFilter subLabel={toggle} hide={!showLevelFilters} />
      <LeagueFilters subLabel={toggle} hide={showLevelFilters} />
    </>
  );
};

export const SeasonRangeFilter = ({ subLabel, hide }) => {
  return (
    <Filters.SelectRange
      hide={hide}
      id="season_range"
      label="Season Range"
      subLabel={subLabel}
      options={SEASON_OPTIONS}
      width={300}
      isReverse
    />
  );
};

export const SeasonSelectAndRangeFilter = ({}) => {
  const { season, season_range: seasonRange } = useFilterValues();
  const { removeFilter } = useFilterContext((state) => state.actions);
  const [showRangeFilters, setShowRangeFilters] = useState(
    !!seasonRange && !!seasonRange.min
  );

  const toggle = (
    <LinkButton
      onClick={() => {
        setShowRangeFilters(!showRangeFilters);
        removeFilter('season');
        removeFilter('season_range');
      }}
    >
      <Typography variant="stat" state="link">
        <b>{showRangeFilters ? 'Filter by seasons' : 'Filter by range'}</b>
      </Typography>
    </LinkButton>
  );

  return (
    <>
      <SeasonRangeFilter subLabel={toggle} hide={!showRangeFilters} />
      <SeasonFilters subLabel={toggle} hide={showRangeFilters} />
    </>
  );
};

export const draftFilters = [
  {
    id: 'league_experience',
    col: 'league_experience',
    label: 'League Experience',
    valueLabel: 'GP',
    type: 'number',
    defaultCond: '>',
    defaultValue: 5,
    extraFilters: [
      {
        ignoreTag: true,
        col: 'league_slug',
        label: 'League',
        type: 'select',
        options: BDD_LEAGUES.map((l) => ({ value: l, label: l.toUpperCase() })),
        defaultValue: 'nhl',
      },
    ],
    buildQueryFilter: (filter) => {
      return {
        ...filter,
        parentCol: filter.col,
        label: `${filter.extraFilters
          .find((ef) => ef.col == 'league_slug')
          .val.toUpperCase()} Experience`,
        col: `${
          filter.extraFilters.find((ef) => ef.col == 'league_slug').val
        }_league_experience`,
      };
    },
  },
  {
    id: 'is_drafted',
    col: 'is_drafted',
    label: 'Is Drafted',
    type: 'boolean',
    defaultValue: true,
    buildQueryFilter: (filter) => {
      return {
        col: 'draft_year',
        cond: filter.cond == '=' ? '!=' : '=',
        val: null,
      };
    },
  },
  {
    id: 'is_late_birth',
    col: 'is_late_birth',
    label: 'Is Late Birth',
    type: 'boolean',
    defaultValue: true,
    buildQueryFilter: (filter) => {
      if (!!filter.val) {
        return [
          {
            col: 'day_of_year',
            cond: '>=',
            val: 259,
          },
          {
            col: 'day_of_year',
            cond: '<=',
            val: 366,
          },
        ];
      }

      return [
        {
          col: 'day_of_year',
          cond: '<=',
          val: 258,
        },
      ];
    },
  },
  {
    id: 'year_after_draft_year',
    col: 'year_after_draft_year',
    label: 'Year after draft',
    type: 'number',
    defaultValue: 1,
  },
  {
    id: 'draft_year',
    col: 'draft_year',
    label: 'Year Drafted',
    type: 'inputRange',
    defaultValue: 2024,
    scale: {
      min: DRAFT_YEAR_OPTIONS[0].value,
      max: DRAFT_YEAR_OPTIONS[DRAFT_YEAR_OPTIONS.length - 1].value,
    },
    includeInputBoxes: true,
    hideRange: true,
  },
  {
    id: 'round',
    col: 'round',
    label: 'Round',
    type: 'inputRange',
    scale: {
      min: 1,
      max: 9,
    },
    includeInputBoxes: true,
    hideRange: true,
  },
  {
    id: 'overall',
    col: 'overall',
    label: 'Overall',
    type: 'inputRange',
    scale: {
      min: 1,
      max: 300,
    },
    includeInputBoxes: true,
    hideRange: true,
  },
  {
    id: 'birth_date',
    col: 'birth_date',
    label: 'Date of Birth',
    type: 'date',
    defaultCond: '>',
    defaultValue: new Date(),
  },
  {
    id: 'draft_class',
    col: 'draft_class',
    label: 'Draft Class',
    type: 'custom',
    operatorOptions: [],
    renderFilter: (filter, onChange) => {
      return <DraftClassFilter filter={filter} onChange={onChange} />;
    },
    buildQueryFilter: (filter) => {
      const { draftYear, draftClass } = getFilterValues(filter.val);

      if (!draftYear) return [];
      return getDraftEligibilityFilter(draftYear, draftClass);
    },
    renderFilterTag: (filter) => {
      return <DraftClassFilter filter={filter} renderAsTag />;
    },
  },
];

export const DraftClassFilter = ({ filter, onChange, renderAsTag }) => {
  const { draftYear, draftClass } = filter?.val ? getFilterValues(filter?.val) : {};

  const { draftEligibleYearsRange } = useEPStatsContext((state) => state.filterOptions);
  const draftYearOptions = draftEligibleYearsRange
    ? Array.from(
        { length: draftEligibleYearsRange.max - draftEligibleYearsRange.min + 1 },
        (_, index) => ({
          value: draftEligibleYearsRange.min + index,
          label: draftEligibleYearsRange.min + index,
        })
      ).reverse()
    : [];

  const draftClassOptions = [
    {
      value: '0',
      label: 'First Year',
    },
    {
      value: '1',
      label: 'DY+1',
    },
    {
      value: '2',
      label: 'DY+2',
    },
  ];

  const defaults = {
    draftYear: {
      id: 'draftYear',
      value: draftYear || getCurrentDraftYear(),
    },
    draftClass: {
      id: 'draftClass',
      value: draftClass || '0',
    },
  };

  useEffect(() => {
    onChange && onChange(defaults);
  }, []);

  if (renderAsTag) {
    return (
      <QueryTagField
        cond={'='}
        label={'Draft Class'}
        value={`${draftYearOptions.find((o) => o.value == draftYear)?.label} ${
          draftClassOptions.find((o) => o.value == draftClass)?.label
        }`}
      />
    );
  }

  return (
    <Filters defaults={defaults} onChange={(filters) => onChange(filters)}>
      <Row columnGap={1}>
        <Filters.Select
          id={'draftYear'}
          width={125}
          options={draftYearOptions}
          loading={!draftEligibleYearsRange}
          label={'Draft Year'}
          cond="="
        />
        <Filters.Select
          id={'draftClass'}
          width={125}
          options={draftClassOptions}
          label={'Draft Class'}
          cond="="
        />
      </Row>
    </Filters>
  );
};
