import { gql } from '@apollo/client';

export const SLTeamFragment = gql`
  fragment slTeamFragment on SLTeam {
    id
    slug
    longname
    shorthand
    nhlid
  }
`;

export const GET_TEAMS_BY_LEAGUE = gql`
  query GetTeamsByLeague($league: ID, $activeOnly: Boolean) {
    slTeams(leagueSlug: $league, active: $activeOnly) {
      id
      nhlid
      slug
      shorthand
      longname
    }
  }
`;

export const GET_ACTIVE_TEAMS_BY_LEAGUE = gql`
  query GetActiveTeamsByLeague($league: String) {
    activeSlTeams(league: $league) {
      id
      nhlid
      slug
      shorthand
      longname
    }
  }
`;



export const GET_RINKNET_TEAMS_BY_LEAGUE = gql`
  query GetRinknetTeamsByLeague($leagueid: ID, $leagueids: [ID], $active: Boolean) {
    rinknetTeams(
      leagueid: $leagueid
      leagueids: $leagueids
      active: $active
    ) {
      id
      id_
      nhlid
      cityname
      leagueid
      leaguename
      longname
      shortname
    }
  }
`;

export const GET_BDD_TEAMS = gql`
  query GetBDDTeamsQuery($leagueSlug: ID, $leagueSlugs: [ID!], $slLeagueSlug: ID, $slTeamSlugs: [ID!], $activeOnly: Boolean) {
    bddTeams(
      leagueSlug: $leagueSlug
      leagueSlugs: $leagueSlugs
      slLeagueSlug: $slLeagueSlug
      slTeamSlugs: $slTeamSlugs
      activeOnly: $activeOnly
    ) {
      id
      longname
      shorthand
      nhlid
      epTeamId
      rinknetTeamId
      slTeamSlug
      cfTeamSlug
      bfTeamSlug
      bddLeagueSlug
    }
  }
`;

export const GET_RINKNET_PLAYERS_FOR_TEAM = gql`
  query GetRinknetPlayersForTeam($team_nhlid: Int!) {
    rinknetPlayers(currentteamnhlid: $team_nhlid) {
      id
      nhlid
      firstname
      lastname
      currentteamnhlid
      currentteam
      position
      bddPlayerSlug {
        slug
        slUid
      }
    }
  }
`;

export const GET_RINKNET_PLAYERS_FOR_TEAM_SLUG = gql`
  query GetRinknetPlayersForTeamBySlug($teamSlug: String!) {
    slTeam(slug: $teamSlug) {
      id
      slug
      longname
      shorthand
      depthChart {
        id
        nhlid
        firstname
        lastname
        currentteamnhlid
        currentteam
        currentleague
        position
        bddPlayerSlug {
          slug
          slUid
        }
      }
    }
  }
`;

export const GET_TEAM_MOST_RECENT_AUDITED_LINEUP = gql`
  query GetRinknetPlayersForTeamBySlug($teamSlug: String!) {
    slTeam(slug: $teamSlug) {
      id
      slug
      longname
      shorthand
      mostRecentAuditedLineup {
        slUid
        position
        gameDate
        teamSlug
        lineNum
        lineupSpot
        slPlayerMaster {
          slUid
          firstname
          lastname
          bddPlayerSlug {
            slug
          }
        }
      }
    }
  }
`;

export const GET_TEAM = gql`
  query GetTeam($slug: String!) {
    slTeam(slug: $slug) {
      id
      slug
      nhlid
      league
      leagueSlug
      longname
      shorthand
      nhlTeamData
      rinknetTeam {
        id_
      }
      bddTeam {
        id
        epTeamId
        bfTeamSlug
      }
    }
  }
`;

export const GET_TEAM_WITH_NHL_DATA = gql`query GetTeamNHLData($slug: String!) {
  slTeam(slug: $slug) {
      id
      slug
      nhlid
      leagueSlug
      longname
      shorthand
      nhlTeamData
  }
}`

export const GET_TEAM_NOTES = gql`
  query GetTeamNotes(
    $slTeamSlugs: [ID!]
    $noteType: String
    $startDate: Date
    $endDate: Date
    $onlyPinned: Boolean
  ) {
    bddTeamNotes(
      slTeamSlugs: $slTeamSlugs
      noteType: $noteType
      endDate: $endDate
      startDate: $startDate
      onlyPinned: $onlyPinned
    ) {
      id
      bddTeamId
      user {
        id
        username
        __typename
      }
      parentTeamNoteId
      dateCreated
      dateEdited
      note
      noteType
      requiresPermission
      isPrivate
      isEditable
      isPinned
      files {
        name
        s3Key
        s3Url
        url
        __typename
      }
      __typename
    }
  }
`;

export const CREATE_TEAM_NOTE = gql`
  mutation CreateBDDTeamNote(
    $bddTeamId: ID!
    $note: JSONString!
    $noteType: String!
    $requiresPermission: String
    $isPrivate: Boolean
    $files: [BDDTeamNoteFileInput]
    $isPinned: Boolean
  ) {
    createBddTeamNote(
      input: {
        bddTeamId: $bddTeamId
        note: $note
        files: $files
        noteType: $noteType
        requiresPermission: $requiresPermission
        isPrivate: $isPrivate
        isPinned: $isPinned
      }
    ) {
      bddTeamNote {
        id
      }
    }
  }
`;

export const UPDATE_TEAM_NOTE = gql`
  mutation UpdateTeamNote(
    $id: Int!
    $note: JSONString
    $requiresPermission: String
    $isPrivate: Boolean
    $files: [BDDTeamNoteFileInput]
    $isArchived: Boolean
    $isPinned: Boolean
  ) {
    updateBddTeamNote(
      input: {
        id: $id
        note: $note
        requiresPermission: $requiresPermission
        isPrivate: $isPrivate
        files: $files
        isArchived: $isArchived
        isPinned: $isPinned
      }
    ) {
      bddTeamNote {
        id
        parentTeamNoteId
      }
    }
  }
`;
