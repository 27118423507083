import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_DRAFT } from 'apollo/queries/draft.queries';
import { Divider } from 'components/bdd/Divider';
import { Column, Container, Grid, Row } from 'components/bdd/Layout';
import { NumberInput } from 'components/bdd/NumberInput/NumberInput';
import { Typography } from 'components/bdd/Typography';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton, LinkButton, LoadingPlaceholder } from 'components/bdd/Button';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { TooltipSpan } from 'components/reports';
import { toastInfo } from 'components/bdd/bddtoasts';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import {
  GET_PLAYER_LIST_POSITIONS,
  GET_SIM_DRAFT_LISTS,
} from 'apollo/queries/playerlist.queries';
import { GET_BDD_PLAYERS } from "apollo/queries/players.queries";
import useBDDSearchSelect from 'components/Search/useBDDSearchSelect';
import {
  GET_PLAYER_SEARCH_QUERY,
  GET_RINKNET_PLAYER_SEARCH_QUERY,
} from 'apollo/queries/search.queries';
import { theme } from 'constants/theme';
import { XLg } from 'react-bootstrap-icons';
import { getCurrentSeason } from 'helpers/hockeyutils';

export const useSimulatePicks = ({ nextPick, picks, excludePlayerIds = [] }) => {
  const { data: simDraftListData, loading: simDraftListLoading } = useQuery(
    GET_SIM_DRAFT_LISTS,
    {
      variables: {
        draftYear: nextPick?.year || parseInt(getCurrentSeason().toString().slice(4)),
      },
    }
  );

  const simDraftLists = simDraftListData?.simDraftLists;
  const rinknetLists = simDraftLists?.filter((sdl) => !!sdl.rinknetListId);
  const bddLists = simDraftLists?.filter((sdl) => !!sdl.bddListId);

  const { data, loading } = usePlaceholder(
    useQuery(GET_PLAYER_LIST_POSITIONS, {
      skip: !simDraftListData,
      variables: {
        rinknetLists: rinknetLists?.map((sdl) => sdl.rinknetListId),
        bddLists: bddLists?.map((sdl) => sdl.bddListId),
      },
    })
  );

  const playerLists = data?.playerListPositions.lists.map((l) => ({
    list: l,
    startingAtPick: simDraftLists.find(
      (sdl) => sdl.rinknetListId == l.id || sdl.bddListId == l.id
    ).startingAtPick,
    players: data?.playerListPositions.listPositions
      .filter((lp) => lp.listId == l.id)
      .sort((a, b) => a.listNumber - b.listNumber),
  }));

  const selectedPlayerIds = useMemo(() => {
    if (!picks.fetched) return [];
    return [
      ...new Set(
        picks.picks
          .filter((p) => p.is_selected && !!p.selected_player_rinknet_id)
          .map((p) => p.selected_player_rinknet_id.toString())
          .concat(excludePlayerIds)
      ),
    ];
  }, [picks, excludePlayerIds]);

  const getPlayerListsWithAvailablePlayers = (excludePlayerIds = [], overall = 1) => {
    return playerLists
      ? playerLists
          .filter((pl) => !pl.startingAtPick || pl.startingAtPick <= overall)
          .map((pl) => ({
            ...pl,
            players: pl.players.filter(
              (p) =>
                !excludePlayerIds.find((ep) => ep == p.rinknetId) &&
                !selectedPlayerIds.includes(p.rinknetId)
            ),
          }))
          .filter((pl) => pl.players.length > 0)
      : [];
  };

  const runSim = (excludePlayerIds, pick) => {
    const availableLists = getPlayerListsWithAvailablePlayers(
      excludePlayerIds,
      pick.overall
    );

    if (availableLists.length == 0) return null;

    const selectedListIdx = parseInt(Math.random() * availableLists.length);
    const selectedList = availableLists[selectedListIdx];

    // Grab the top available player on the selected list
    const selectedPlayer = selectedList.players[0];

    return {
      player: selectedPlayer,
      sourceList: selectedList.list,
      pick,
      playerListPosition: selectedPlayer.listNumber,
    };
  };

  const runSims = (simUptoOverall, excludePlayerIds = []) => {
    let results = [];
    let simCount = 0;

    while (simCount <= simUptoOverall - nextPick.overall) {
      const selectedPlayerIds = results
        .map((r) => r.player.rinknetId)
        .concat(excludePlayerIds);

      const pick = picks.picks.find((p) => p.overall == nextPick.overall + simCount);

      const result = runSim(selectedPlayerIds, pick);
      results.push(result);
      simCount++;
    }

    return results;
  };

  return {
    playerLists,
    loading: !data,
    runSims,
  };
};

export const SimulateDraftPicks = ({
  draftSlug,
  nextPick,
  picks,
  focusTeamNHLID,
  idToTeam,
  onPlayersSelected,
}) => {
  const [updateDraft, { loading }] = useMutation(UPDATE_DRAFT);
  // const [numSims, setNumSims] = useState(1000);
  const [simUpToOverall, setSimUpToOverall] = useState(nextPick.overall);
  const [simResults, setSimResults] = useState();

  useEffect(() => {
    setSimResults();
    setSimUpToOverall(nextPick.overall);
  }, [nextPick.overall]);

  const handleSelect = () => {
    updateDraft({
      variables: {
        input: { slug: draftSlug },
        draftPickSelections: simResults.map((sr) => ({
          overall: sr.pick.overall,
          isSelected: true,
          selectedPlayerRinknetId: sr.player.rinknetId,
        })),
      },
      onCompleted: () => {
        toastInfo('Draft picks selected');
        onPlayersSelected && onPlayersSelected();
      },
    });
  };

  const nextFocusTeamPick =
    picks.fetched &&
    picks.picks.find((p) => p.curr_team_nhlid == focusTeamNHLID && !p.is_selected);

  // const { select, selected } = usePlayerListSelect({
  //   initialSelectedValue: DEFAULT_LIST_IDS.map(String),
  //   variables: {
  //     listTypes: [PLAYER_LIST_TYPES.DRAFT_LIST, PLAYER_LIST_TYPES.AGGREGATED_RANK_LIST],
  //   },
  //   isMulti: true,
  //   selectProps: { noOverflow: true },
  //   size: 'sm',
  //   includeRinknetDraftLists: true,
  // });
  const { multiSearchComponent, selectedPlayerIds: excludePlayerIds } =
    useMultiPlayerSearch({});

  const { runSims, loading: simLoading } = useSimulatePicks({
    nextPick,
    picks,
    excludePlayerIds,
  });

  const { data: playerData, loading: bddPlayerLoading } = useQuery(GET_BDD_PLAYERS, {
    variables: {
      slugs: simResults?.map((r) => r.player.bddPlayerSlug),
      skip: !simResults,
    },
  });

  const simResultsWithPlayerData =
    !!simResults &&
    !!playerData &&
    simResults.map((r) => {
      const bddPlayer = playerData.bddPlayers.find(
        (bdd) => bdd.rinknetId == r.player.rinknetId
      );
      return {
        ...r,
        bddPlayer,
      };
    });

  const simResultView = !simResults ? null : bddPlayerLoading ? (
    <Typography variant="body2">Loading results...</Typography>
  ) : (
    <Container maxHeight={300} overflow="auto">
      {simResultsWithPlayerData.map((result) => (
        <Container key={result?.player?.rinknetId}>
          {!!result ? (
            <Row alignItems="baseline" columnGap={3}>
              <DraftPickDescription idToTeam={idToTeam} pick={result.pick} size="sm" />
              <Grid gridTemplateColumns="150px 160px 1fr" alignItems="baseline">
                <PlayerLinkHover
                  rinknetId={result.player.rinknetId}
                  bd={result.bddPlayer}
                  variant="body1"
                />
                <Typography variant="caption">
                  {result.bddPlayer.rinknetPlayer?.height} |{' '}
                  {result.bddPlayer.rinknetPlayer?.weight} |{' '}
                  {result.bddPlayer.rinknetPlayer?.nationality}
                </Typography>
                <Typography variant="caption">
                  {`#${result.playerListPosition} from ${result.sourceList.name}`}
                </Typography>
              </Grid>
            </Row>
          ) : null}
        </Container>
      ))}
    </Container>
  );

  return (
    <Container>
      <Column gap={2}>
        <Row columnGap={3} rowGap={2} flexWrap alignItems="end">
          <Container>
            <Row columnGap={2}>
              <Typography variant="label">Sim up to pick</Typography>
              {!!nextFocusTeamPick && (
                <LinkButton
                  onClick={() => setSimUpToOverall(nextFocusTeamPick.overall - 1)}
                >
                  <Typography variant="label" state="link">
                    NEXT BOS PICK
                  </Typography>
                </LinkButton>
              )}
            </Row>
            <NumberInput
              min={nextPick.overall}
              max={picks.length}
              value={simUpToOverall}
              onChange={(e) => setSimUpToOverall(e.target.value)}
            />
          </Container>
          <Container>
            <Typography variant="label">Exclude players</Typography>
            {multiSearchComponent}
          </Container>
          <Container style={{ alignSelf: simLoading ? 'center' : 'end' }}>
            <LoadingPlaceholder loading={simLoading}>
              <Button
                variant="dark"
                size="sm"
                disabled={!!simLoading}
                onClick={() => {
                  const results = runSims(simUpToOverall);
                  setSimResults(results);
                }}
              >
                <Typography variant="body1">{`Simulate ${
                  simUpToOverall - nextPick.overall + 1
                } pick(s)`}</Typography>
              </Button>
            </LoadingPlaceholder>
          </Container>
        </Row>
        {/* <Divider /> */}
        <Container>
          <Column>
            <Row columnGap={1}>
              <Typography variant="body1">Results:</Typography>
            </Row>
            {simResultView}
          </Column>
        </Container>
        <Divider />
        <Container>
          <Row columnGap={1} justifyContent="end">
            <TooltipSpan
              content={!simResults ? 'No simulation results' : 'Apply draft picks'}
            >
              <Button
                variant="primary"
                size="sm"
                disabled={!simResults || loading}
                onClick={handleSelect}
              >
                <Typography variant="body1">
                  {loading ? 'Loading...' : `Make selection(s)`}
                </Typography>
              </Button>
            </TooltipSpan>
          </Row>
        </Container>
      </Column>
    </Container>
  );
};

export const DraftPickDescription = ({ pick, idToTeam, size = 'default' }) => {
  if (!pick) return null;
  const team = idToTeam[pick.curr_team_nhlid];
  return size == 'sm' ? (
    <Typography variant={'body2'}>
      <Grid gridTemplateColumns="30px 90px">
        <b>
          {pick.round}.{pick.overall}
        </b>
        <Row>
          <img src={getNHLTeamLogoUrl(pick.curr_team_nhlid)} style={{ height: '20px' }} />
          {/* {team ? team.longname : null} */}
          {pick.curr_team_nhlid != pick.orig_team_nhlid ? (
            <>
              from
              <img
                src={getNHLTeamLogoUrl(pick.orig_team_nhlid)}
                style={{ height: '20px' }}
              />
            </>
          ) : null}
        </Row>
      </Grid>
    </Typography>
  ) : (
    <Typography variant={'subtitle2'}>
      <Row columnGap={1}>
        <b>
          {pick.round}.{pick.overall}
        </b>
        <Row columnGap={1}>
          <img src={getNHLTeamLogoUrl(pick.curr_team_nhlid)} style={{ height: '20px' }} />
          {team ? <div>{team.longname}</div> : null}
          {pick.curr_team_nhlid != pick.orig_team_nhlid ? (
            <>
              from
              <img
                src={getNHLTeamLogoUrl(pick.orig_team_nhlid)}
                style={{ height: '20px' }}
              />
            </>
          ) : null}
        </Row>
      </Row>
    </Typography>
  );
};

const idTypeToSearch = {
  rinknet_id: {
    query: GET_RINKNET_PLAYER_SEARCH_QUERY,
    resultToOptions: (data) =>
      data?.search?.map((s) => ({
        label: `${s.result.firstname} ${s.result.lastname}`,
        value: s.result.realId,
        subLabel: `${s.result.currentteam}, ${s.result.currentleagueabbr}, #${s.result.jerseynumber} ${s.result.position}`,
      })),
  },
  bdd_player_slug: {
    query: GET_PLAYER_SEARCH_QUERY,
    resultToOptions: (data) =>
      data?.search?.map((s) => ({
        teamid: s.result.rinknetPlayer?.currentteamnhlid,
        label: `${s.result.firstname} ${s.result.lastname}`,
        value: s.result.slug,
        subLabel: s.result.rinknetPlayer?.currentteam,
        bddPlayer: s.result,
      })),
  },
};

export const useMultiPlayerSearch = ({
  selectProps = {},
  defaultSelectedPlayers = [],
  idType = 'rinknet_id',
  width = 150,
}) => {
  const [selectedPlayers, setSelectedPlayers] = useState(defaultSelectedPlayers);

  useEffect(() => {
    setSelectedPlayers(defaultSelectedPlayers);
  }, [JSON.stringify(defaultSelectedPlayers)]);

  const { searchComponent, resetSearch } = useBDDSearchSelect({
    ...idTypeToSearch[idType],
    placeholder: 'Search for player',
    onSelect: (opt) => {
      if (!!opt) setSelectedPlayers((sp) => [...sp, opt]);
    },
    size: 'sm',
    ...selectProps,
  });

  const multiSearchComponent = (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Row columnGap={1} rowGap={1} flexWrap>
        <Container width={width}>{searchComponent}</Container>
        {selectedPlayers.map((sp) => (
          <Container
            key={sp.key || sp.label}
            padding={1}
            backgroundColor={theme.colors.light.secondary}
          >
            <Row columnGap={1} rowGap={1} flexWrap>
              <Typography variant="stat">{sp.label}</Typography>
              <IconButton
                icon={<XLg />}
                onClick={() => {
                  setSelectedPlayers((selectedPlayers) =>
                    selectedPlayers.filter((player) => player.value != sp.value)
                  );
                }}
              />
            </Row>
          </Container>
        ))}
      </Row>
    </Container>
  );

  return {
    multiSearchComponent,
    setSelectedPlayers,
    selectedPlayers,
    selectedPlayerIds: selectedPlayers.map((sp) => sp.value),
    resetSearch,
  };
};
