import localforage from 'localforage';
import { SearchParams } from './searchParams';
import { createContext, useContext, useRef } from 'react';
import { immer } from 'zustand/middleware/immer';
import { create, useStore } from 'zustand';

export function defaultSet(set, key) {
  return (data) =>
    set((state) => {
      state[key] = data;
    });
}

export function buildSetActions(set, state) {
  return Object.keys(state).reduce((actions, key) => {
    const actionKey = `set${key.charAt(0).toUpperCase() + key.slice(1)}`;
    actions[actionKey] = defaultSet(set, key);

    return actions;
  }, {});
}

export function buildSearchParamStorage({ ignoreKeys = [] } = {}) {
  return {
    getItem: async (key) => {
      const searchParams = new SearchParams();
      const searchParamsState = searchParams.getSerialized(key);
      const persistItem = JSON.parse(await localforage.getItem(key));
      const persistState = persistItem['state'];

      return JSON.stringify({
        ...persistItem,
        state: {
          ...persistState,
          ...searchParamsState,
        },
      });
    },
    setItem: async (key, value) => {
      const searchParams = new SearchParams();

      let state = JSON.parse(value)['state'];
      Object.keys(state).forEach((stateKey) => {
        if (ignoreKeys.includes(stateKey)) {
          delete state[stateKey];
        }
      });

      searchParams.setSerialized(key, state);
      searchParams.push();
      await localforage.setItem(key, value);
    },
    removeItem: async (name) => {
      await localforage.removeItem(name);
    },
  };
}

export const buildStore = (initialState) => {
  const createStore = (props) => {
    let store = immer((set, get) => ({
      ...initialState,
      ...props,
      actions: {
        ...buildSetActions(set, initialState),
      },
    }));

    return create(store);
  };

  const Context = createContext();
  const ContextProvider = ({ children, ...props }) => {
    const store = useRef(createStore(props)).current;

    return <Context.Provider value={store}>{children}</Context.Provider>;
  };

  return {
    Context,
    ContextProvider,
    useStore: (selector) => {
      const context = useContext(Context);
      if (!context) return null;

      return useStore(context, selector);
    },
  };
};
