import { useQuery } from "@apollo/client";
import { GET_PLAYER_GENERATED_REPORT_SUMMARIES } from "apollo/queries/generative.queries";
import usePlaceholder from "components/Placeholder/usePlaceholder";

export const useGeneratedSummaries = ({ rinknetIds, skip }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_GENERATED_REPORT_SUMMARIES, {
      variables: { rinknetIds },
      skip,
    })
  );
  return {
    data,
    placeholder,
    generatedSummaries: data?.playerGeneratedReportSummaries,
  };
};
