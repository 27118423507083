import React, { cloneElement } from 'react';
import { DASHBOARD_ROUTE } from '../../constants';
import { getNHLTeamLogoUrl } from '../../helpers/logos';
import { trackError } from 'helpers/tracker';
import { Column, Container, Row } from './Layout';
import { Typography } from './Typography';
import { LinkButton } from './Button';
import { FeedbackPrompt } from 'components/Feedback/components/FeedbackPrompt';
import { BUG_TYPE_OPTIONS, FEEDBACK_TYPES } from 'components/Feedback/constants';

export default class BDDErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    trackError(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.errorContent) {
        return cloneElement(this.props.errorContent, { error: this.state.error });
      }
      const feedbackPrompt = !this.props.hideFeedbackPrompt && (
        <FeedbackPrompt
          promptText="Report Bug"
          defaultValues={{
            feedbackType: FEEDBACK_TYPES.BUG,
            bugType: BUG_TYPE_OPTIONS.FRONTEND,
            sourceUrl: window.location.href,
            payload: { error: this.state.error },
          }}
        />
      );
      return (
        <Container padding={3}>
          <Column gap={1}>
            <h4>
              Error: Something's{' '}
              <img
                src={getNHLTeamLogoUrl(6)}
                height="30px"
                style={{ marginRight: '-7px' }}
              />
              roken
            </h4>

            <Container>
              <Row gap={8}>
                <Typography variant="body2">It's probably Josh's fault.</Typography>
                {feedbackPrompt}
              </Row>
            </Container>

            {this.props.homeButton && (
              <div>
                <a href={`${DASHBOARD_ROUTE}`}>Go To Dashboard</a>
              </div>
            )}
          </Column>
        </Container>
      );
    }

    return this.props.children;
  }
}
