import { gql } from '@apollo/client';
import { SLTeamFragment } from './teams.queries';
import { BDDTeamFragment } from './bddentity.queries';

const NHLGamesFragment = gql`
  fragment nhlGamesFragment on NHLScheduledGames {
    id
    season
    gameType
    gamePk
    gameDate
    homeTeam {
      slug
      longname
      shorthand
      nhlid
    }
    awayTeam {
      slug
      longname
      shorthand
      nhlid
    }
    homeScore
    awayScore
    isCompleted
    ot
    shootout
    broadcasts
    homeLeagueRecord
    awayLeagueRecord
    bddGame {
      id
    }
  }
`;

export const SLGameFragment = gql`
  fragment slGameFragment on Games {
    id
    leagueSlug
    season
    description
    gameType
    gameDate
    winner
    homeScore
    homeTeamSlug
    homeTeam {
      slug
      longname
      shorthand
      nhlid
    }
    awayScore
    awayTeamSlug
    awayTeam {
      slug
      longname
      shorthand
      nhlid
    }
    ot
    shootout
  }
`;

const SLGameNhlFragment = gql`
  fragment slGameNhlFragment on Games {
    nhlContent
  }
`;

export const RinknetGameFragment = gql`
  fragment rinknetGameFragment on RinknetGames {
    id
    date
    isCompleted
    leagueid
    leaguename
    season
    overtime
    shootout
    hometeamid
    hometeamname
    hometeamshortname
    hometeamscore
    awayteamid
    awayteamname
    awayteamshortname
    awayteamscore
    hometeam {
      id
      longname
      shortname
    }
    awayteam {
      id
      longname
      shortname
    }
    bddHomeTeam {
      slTeamSlug
    }
    bddAwayTeam {
      slTeamSlug
    }
    bddGame {
      id
      slGameUid
    }
  }
`;

export const GET_TEAM_GAMES = gql`
  query GetGamesForTeam($slug: String!, $season: Int, $isCompleted: Boolean) {
    teamGames(teamSlug: $slug, season: $season, isCompleted: $isCompleted) {
      id
      gameDate
      homeScore
      homeTeam {
        id
        slug
        shorthand
      }
      awayScore
      awayTeam {
        id
        slug
        shorthand
      }
      auditedLineupTeamSlugs
    }
  }
`;

export const GET_NHL_TEAM_GAMES = gql`
  query GetNHLTeamGames(
    $teamSlug: String!
    $season: Int
    $numRecent: Int
    $numUpcoming: Int
  ) {
    nhlTeamGames(
      teamSlug: $teamSlug
      season: $season
      numRecent: $numRecent
      numUpcoming: $numUpcoming
    ) {
      ...nhlGamesFragment
    }
  }
  ${NHLGamesFragment}
`;

export const GET_NHL_GAMES = gql`
  query GetNHLGames($teamSlug: String, $season: Int, $startDate: Date, $endDate: Date) {
    nhlGames(
      teamSlug: $teamSlug
      season: $season
      startDate: $startDate
      endDate: $endDate
    ) {
      ...nhlGamesFragment
    }
  }
  ${NHLGamesFragment}
`;

export const GET_RINKNET_GAMES_BY_TEAM = gql`
  query GetGames($leagueid: ID, $season: Int, $teamid: ID) {
    rinknetGames(
      leagueid: $leagueid
      season: $season
      teamid: $teamid
    ) {
      ...rinknetGameFragment
    }
  }
  ${RinknetGameFragment}
`;

export const GET_RINKNET_GAMES_WITH_REPORTS = gql`
  query GetReportGames($filters: JSONString, $limit: Int!, $offset: Int) {
    reportGames(filters: $filters, limit: $limit, offset: $offset) {
      pageInfo {
        hasNextPage
        totalRows
        endIndex
      }
      data {
        gameId
        date
        leagueshorthand
        hometeamname
        awayteamname
        reportAuthors
        rinknetReports {
          gameid
          scoutid
          playerreportid
          playerrinknetid
        }
      }
    }
  }
`;

export const GET_RINKNET_TEAM_GAMES = gql`
  query GetRinknetTeamGames(
    $teamId: ID!
    $season: Int
    $numRecent: Int
    $numUpcoming: Int
  ) {
    rinknetTeamGames(
      teamId: $teamId
      season: $season
      numRecent: $numRecent
      numUpcoming: $numUpcoming
    ) {
      id
      date
      leagueid
      leaguename
      season
      gameType
      hometeamid
      hometeamname
      hometeamshortname
      hometeamscore
      awayteamid
      awayteamname
      awayteamshortname
      awayteamscore
      overtime
      shootout
      isCompleted
      gameUid
    }
  }
`;

export const GET_SL_GAMES = gql`
  query GetSLGames(
    $leagueSlug: String!
    $season: Int
    $gameType: String
    $teamSlug: ID
    $gameUids: [String]
  ) {
    slGames(
      leagueSlug: $leagueSlug
      season: $season
      gameType: $gameType
      teamSlug: $teamSlug
      gameUids: $gameUids
    ) {
      ...slGameFragment
    }
  }
  ${SLGameFragment}
`;

export const GET_SL_GAME = gql`
  query GetSLGame($gameId: ID!) {
    slGame(gameId: $gameId) {
      ...slGameFragment
    }
  }
  ${SLGameFragment}
`;

export const BDDGameFragment = gql`
  fragment bddGameFragment on BDDGame {
    id
    rinknetGameId
    slGameUid
    nhlPkey
    date
    homeTeam {
      id
      shorthand
      longname
      nhlid
      slTeamSlug
    }
    awayTeam {
      id
      shorthand
      longname
      nhlid
      slTeamSlug
    }
    gameType
    bddLeagueSlug
    homeTeamScore
    awayTeamScore
    homeTeamSoScore
    awayTeamSoScore
    status
    scoreType
    epStats
    nhlGame {
      id
      oasisGameId
      gameDate
      league
      season
      gameType
      venueName
      isCompleted
      broadcasts
      homeLeagueRecord
      awayLeagueRecord
    }
    epStats
    scoutingReports {
      __typename
      ... on RinknetReports {
        author
        gameid
        playerreportid
        scoutid
        playerrinknetid
        scoutid
        notes
        ratings
        position
      }
    }
    countPlayers
    rinknetPlayersMapping
    homeTeamRinknetPlayersMapping
    awayTeamRinknetPlayersMapping
  }
`;

export const GET_BDD_GAME = gql`
  query GetBDDGame($id: ID!) {
    bddGame(id: $id) {
      ...bddGameFragment
    }
  }
  ${BDDGameFragment}
`;

export const GET_INGAME_SL_GAME = gql`
  query GetInGame($bddGameId: ID!, $teamSlug: ID!) {
    inGameReport(bddGameId: $bddGameId, teamSlug: $teamSlug) {
      id
      bddGameId
      bddGame {
        ...bddGameFragment
      }
      bddTeam {
        ...bddTeamFragment
      }
      oppBddTeam {
        ...bddTeamFragment
      }
      teamSlug
      oppTeamSlug
      isHome
      slTeam {
        ...slTeamFragment
      }
      slOpponent {
        ...slTeamFragment
      }
      slGame {
        ...slGameFragment
      }
      periodsIngested
      liveNhlData
      liveNhlDataV2
      nhlContent
      error
    }
  }
  ${BDDGameFragment}
  ${SLGameFragment}
  ${SLTeamFragment}
  ${BDDTeamFragment}
`;

export const GET_BDD_GAMES = gql`
  query GetAllBddGames($startDate: Date!, $endDate: Date!) {
    allBddGames(startDate: $startDate, endDate: $endDate) {
      id
      rinknetGameId
      slGameUid
      date
      season
      homeTeam {
        id
        shorthand
        longname
        nhlid
        slTeamSlug
        __typename
      }
      awayTeam {
        id
        shorthand
        longname
        nhlid
        slTeamSlug
        __typename
      }
      rinknetGame {
        date
        hometeamscore
        awayteamscore
        __typename
      }
      gameType
      bddLeagueSlug
      homeTeamScore
      awayTeamScore
      homeTeamSoScore
      awayTeamSoScore
      status
      scoreType
    }
  }
`;
