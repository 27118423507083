import PlayerHover from "components/PlayersV2/Hover/playerhover";
import BDDDepthChart from "components/ProScouting/bdddepthchart";
import Icon from "components/bdd/Icon";
import { Column, Container, Row } from "components/bdd/Layout";
import useTeamSelect from "components/bdd/Select/useTeamSelect";
import useToggle from "components/bdd/Toggle/useToggle";
import { Typography } from "components/bdd/Typography";
import { toastInfo } from "components/bdd/bddtoasts";
import { bruinsGold } from "helpers/plotting";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CaretLeft, CaretRight } from "react-bootstrap-icons";

export const FindTargetsByTeam = ({
  focusTeamSlug = "nhl.bos",
  existingTargets,
  onAddTargets,
}) => {
  const { select, selectedValue, bddTeams, forceSetSelected } = useTeamSelect({
    leagueSlug: "NHL",
    initialSelectedValue: "nhl.ana",
    selectProps: { isClearable: false },
  });

  const handleShiftTeam = (shift) => {
    if (!bddTeams) return;
    const currIdx = bddTeams.findIndex((t) => t.slTeamSlug === selectedValue);
    if (
      (currIdx === 0 && shift < 0) ||
      (currIdx === bddTeams.length - 1 && shift > 0)
    )
      return;
    forceSetSelected(bddTeams[currIdx + shift].slTeamSlug);
  };

  const { toggleComponent, toggled, forceSetToggle } = useToggle({
    initialToggled: false,
    label: 'Are these players controlled "assets"?',
  });

  useEffect(() => {
    // When selected team changes, update our "asset" toggle
    if (selectedValue === focusTeamSlug) forceSetToggle(true);
    else forceSetToggle(false);
  }, [selectedValue]);

  return (
    <Container padding={2}>
      <Column rowGap={2}>
        <Row justifyContent="center" columnGap={4}>
          <Button
            variant="outline-dark"
            disabled={
              bddTeams?.findIndex((t) => selectedValue === t.slug) === 0
            }
            onClick={() => handleShiftTeam(-1)}
          >
            <Icon icon={<CaretLeft />} />
          </Button>
          <Container width={300}>{select}</Container>
          <Button
            variant="outline-dark"
            disabled={
              bddTeams?.findIndex((t) => selectedValue === t.slug) ===
              bddTeams?.length - 1
            }
            onClick={() => handleShiftTeam(1)}
          >
            <Icon icon={<CaretRight />} />
          </Button>
        </Row>
        <FindTargetsByFocusTeam
          teamSlug={selectedValue}
          existingTargets={existingTargets}
          onAddTargets={onAddTargets}
          toggled={toggled}
        />
      </Column>
    </Container>
  );
};

export const FindTargetsByFocusTeam = ({
  teamSlug,
  existingTargets,
  onAddTargets,
  toggled = false,
}) => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const existingTargetSet = new Set(
    existingTargets?.map((t) => t.bddPlayer.slug)
  );

  const targetExists = (slug) => existingTargetSet.has(slug);
  const playerIsSelected = (slug) => !!selectedPlayers.find((s) => s === slug);
  const handleTogglePlayer = (slug) => {
    if (targetExists(slug)) return;
    if (playerIsSelected(slug)) {
      setSelectedPlayers(selectedPlayers.filter((p) => p !== slug));
    } else {
      setSelectedPlayers(selectedPlayers.concat([slug]));
    }
  };

  const handleAddTargets = () => {
    if (!selectedPlayers?.length) {
      toastInfo("No players selected!");
      return;
    }
    setSelectedPlayers([]);
    onAddTargets(selectedPlayers, toggled);
  };

  return (
    <Column>
      <Container>
        <BDDDepthChart
          activeTeamSlug={teamSlug}
          editable={false}
          showButtons={false}
          showProspects={true}
          showLists={false}
          showChanges={false}
          playerSize={"sm"}
          renderPlayer={({ bddPlayer }) => {
            if (!bddPlayer) return;
            return (
              <Container
                onClick={() => handleTogglePlayer(bddPlayer.slug)}
                style={{
                  cursor: targetExists(bddPlayer.slug)
                    ? "not-allowed"
                    : "pointer",
                  backgroundColor: targetExists(bddPlayer.slug) ? "#ddd" : "",
                  boxShadow: playerIsSelected(bddPlayer.slug)
                    ? `0 0 5px 2px ${bruinsGold}`
                    : "",
                }}
              >
                <Typography variant="body2" textAlign="center">
                  <PlayerHover
                    playerSlug={bddPlayer.slug}
                    delay={{ show: 500, hide: 50 }}
                  >
                    {bddPlayer.firstname} {bddPlayer.lastname}
                  </PlayerHover>
                </Typography>
              </Container>
            );
          }}
          nonNHLDisplayMode={"expandable"} // either visible, expandable or hidden
        />
      </Container>
      <Row justifyContent="end" columnGap={4}>
        <Button
          variant="primary"
          onClick={handleAddTargets}
          disabled={!selectedPlayers?.length}
          style={{ minWidth: "100px" }}
        >
          Add Players
        </Button>
      </Row>
    </Column>
  );
};
