import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

export const PageRouter = ({ routes, noRedirect = false }) => {
  const { url: baseRoute } = useRouteMatch();

  return (
    <Switch>
      {routes.map((r) => {
        const RouteComponent = r.hidden ? RestrictedRoute : Route;

        return (
          <RouteComponent
            key={r.route}
            path={`${baseRoute}/${r.route}`}
            render={r.render}
            component={r.component}
            accessible={!r.hidden}
          />
        );
      })}
      {!noRedirect && <Redirect to={`${baseRoute}/${routes[0].route}`} />}
    </Switch>
  );
};
