import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Container, Row } from '../Layout';
import { VerticalDivider } from '../Divider';
import { Typography } from '../Typography';
import { BackButton } from './BackButton';
import { theme } from 'constants/theme';
import { ShareButton } from './ShareButton';

const BreadcrumbsContext = createContext();

const DEFAULT_BREADCRUMBS = [
  {
    path: '/',
    pathname: '/',
    label: 'Home',
  },
];

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(DEFAULT_BREADCRUMBS);

  const addBreadcrumb = (breadcrumb) => {
    setBreadcrumbs((prev) => {
      const existingIndex = prev.findIndex((b) => b.path === breadcrumb.path);
      if (existingIndex !== -1) {
        const newBreadcrumbs = [...prev];
        newBreadcrumbs[existingIndex] = breadcrumb;
        return newBreadcrumbs;
      }
      return [...prev, breadcrumb];
    });
  };

  const resetBreadcrumbs = () => {
    setBreadcrumbs(DEFAULT_BREADCRUMBS);
  };

  const sliceBreadcrumbs = (index) => {
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        sliceBreadcrumbs,
        addBreadcrumb,
        resetBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const Breadcrumbs = () => {
  const { breadcrumbs, sliceBreadcrumbs } = useBreadcrumbs();

  return (
    <Container paddingTop={0.5} height={20}>
      <Row columnGap={2}>
        <BackButton />
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <Link to={breadcrumb.pathname} onClick={() => sliceBreadcrumbs(index)}>
              <Typography
                variant={index == breadcrumbs.length - 1 ? 'body1' : 'body2'}
                color={theme.colors.light.text.primary}
              >
                {breadcrumb.label}
              </Typography>
            </Link>
            {index < breadcrumbs.length - 1 && <VerticalDivider />}
          </React.Fragment>
        ))}
        <ShareButton />
      </Row>
    </Container>
  );
};

export const BreadcrumbListener = ({ breadcrumb }) => {
  const { addBreadcrumb } = useBreadcrumbs();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!breadcrumb) return;
    addBreadcrumb({ path, pathname, label: breadcrumb.label });
  }, [breadcrumb?.label]);
};
