import React, { useState, useEffect } from "react"
import { Button, Row, Col, Spinner } from "react-bootstrap"
import { useQuery, useMutation } from "@apollo/client"
import Markdown from "react-remarkable"
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

import { GET_REPORTDATA_BY_ID } from "../../apollo/queries/prescout.queries"
import { GET_CLIENT_REPORT_STATE, UPDATE_REPORT_STATE } from "../../apollo/queries/gamereport.queries"
import { Unprinted } from "./report.elements"

export function ReportNoteEditor(props) {
    const [updateState, extras] = useMutation(UPDATE_REPORT_STATE)
    const updating = extras.loading
    const [text, setText] = useState('')    
    const {data, loading, error} = useQuery(GET_REPORTDATA_BY_ID, {variables: {id: props.reportId}})
    const clientReportData = useQuery(GET_CLIENT_REPORT_STATE)
    useEffect(() => {
        if (!loading && !!data) {
            try {
                const existingNotes = JSON.parse(data.reportData.reportState)[props.notesSection]
                if (existingNotes) {
                    setText(existingNotes)
                }    
            } catch {}
        }
        return () => {
        }
    }, [loading])

    if (loading || clientReportData.loading) return "Loading..."
    if (error) return "Error"

    const parsedJson = JSON.parse(data.reportData.reportState)
    const existingNotes = parsedJson ? parsedJson[props.notesSection] : ''
    
    return <>
        <Markdown source={text}/>
        {clientReportData.data.reportState.adminMode ?
        <Unprinted>
            <MdEditor
                value={text}
                style={{ height: props.height || "500px" }}
                onChange={(ev) => {
                    setText(ev.text)
                }}
            />
            <Row>
                <Col></Col>
                <Col sm='auto' className='ml-auto'>
                    <Button size="sm" variant="danger"
                        onClick={() => setText(existingNotes)}
                    >
                        Reset Notes
                    </Button>
                    {' '}
                    <Button size="sm" variant="primary" disabled={updating}
                        onClick={() => updateState({ variables: {
                                id: props.reportId,
                                reportState: JSON.stringify({
                                    [props.notesSection]: text
                                })
                            }
                        })}
                    >
                        {updating ? <><Spinner/> Saving...</> : 'Save Notes'}
                    </Button>
                </Col>
            </Row>
        </Unprinted>
        : null }
    </>
}