import React, { createContext, useContext } from 'react';
import useWindowDimensions from '../hooks/usewindowdimensions';
import { theme } from 'constants/theme';

export const WindowContext = createContext();

export const WindowContextProvider = ({ children }) => {
  const windowDimensions = useWindowDimensions();

  return (
    <WindowContext.Provider value={windowDimensions}>{children}</WindowContext.Provider>
  );
};

export const useBreakpoints = () => {
  const { width } = useContext(WindowContext);
  return {
    isMobile: width < theme.breakpoints.sm,
    isTablet: width >= theme.breakpoints.sm && width < theme.breakpoints.lg,
    isDesktop: width >= theme.breakpoints.lg && width < theme.breakpoints.xl,
    isLargeDesktop: width >= theme.breakpoints.xl,
  };
};
