import { Container } from "components/bdd/Layout"
import { useContext } from "react"
import { ProObjectiveContext } from "../ProObjectiveContextProvider"
import { toastInfo } from "components/bdd/bddtoasts"
import { useBDDTradeForm } from "components/ProScouting/BDDTrades/useBDDTradeForm"
import { ScenarioContext } from "../Scenarios/ScenarioContextProvider"

export const useCreateObjectiveTrade = ({ 
  proObjectiveSlug, 
  tradeFormProps={}, // { defaultTeams: ['nhl.bos', 'nhl.ana'], defaultAssets: { assetType (PLAYER/DRAFT_PICK), assetId, fromTeamSlug, toTeamSlug } }
  onCompleted 
}) => {

  const objectiveCtx = useContext(ProObjectiveContext);
  const scenarioCtx = useContext(ScenarioContext);
  const { createPOTransaction, transactionMutationLoading } = !!objectiveCtx ? objectiveCtx : scenarioCtx;

  const handleSubmit = (teams, assets) => {
    createPOTransaction({
      onCompleted: ({
        createProObjectiveTransaction: { proObjectiveTransaction },
      }) => {
        toastInfo("Trade created");
        if (!!onCompleted) onCompleted(proObjectiveTransaction);
      },
      variables: {
        proObjectiveSlug,
        transactionType: "TRADE",
        trade: {
          tradedPlayers: assets
            .filter((a) => a.assetType === "PLAYER")
            .map((a) => ({
              assetType: a.assetType,
              fromTeamSlug: a.fromTeamSlug,
              toTeamSlug: a.toTeamSlug,
              bddPlayerSlug: a.bddPlayer.slug,
              capHit: a.bddPlayer?.bfCapHit?.finalCapHit,
              retainedSalary: a.retainedSalary,
              retainedSalaryPct: a.retainedSalaryPct,
            })),
          tradedPicks: assets
            .filter((a) => a.assetType === "DRAFT_PICK")
            .map((a) => ({
              assetType: a.assetType,
              fromTeamSlug: a.fromTeamSlug,
              toTeamSlug: a.toTeamSlug,
              pickId: a.bfDraftPick.id,
              isConditional: a.isConditional,
              conditionalDetails: a.conditionalDetails,
            })),
        },
      },
    });
  }

  const { form, submitButton, teams, assets } = useBDDTradeForm({
    showSubmit: true,
    handleSubmit,
    submitting: transactionMutationLoading,
    ...tradeFormProps
  });
  console.log("!!", teams)

  return {
    form,
    submitButton,
    teams,
    assets,
    loading: transactionMutationLoading
  }
}

export const CreateObjectiveTrade = ({ 
  proObjectiveSlug, 
  tradeFormProps={}, // { defaultTeams: ['nhl.bos', 'nhl.ana'], defaultAssets: { assetType (PLAYER/DRAFT_PICK), assetId, fromTeamSlug, toTeamSlug } }
  onCompleted 
}) => {
  const { form } = useCreateObjectiveTrade({
    proObjectiveSlug,
    tradeFormProps,
    onCompleted
  });

  return <Container>
    {form}
  </Container>
}

