import { useQuery } from '@apollo/client';
import { GET_EP_DRAFT_PICKS_BY_PLAYER } from 'apollo/queries/draft.queries';
import { HoverInteractive } from 'components/bdd';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { numberToOrdinal } from 'helpers/helpers';
import { ClockHistory } from 'react-bootstrap-icons';

const EPDraftPickList = ({ epDraftPicks }) => {
  return (
    <Column>
      {epDraftPicks.map((p) => (
        <Container key={p.id}>
          <Row gap={8}>
            <Container>
              <Typography variant="body1">
                {p.draftTypeName} ({p.leagueSlug.toUpperCase()}) {p.year}
              </Typography>
            </Container>
            <Typography variant="body2">
              {numberToOrdinal(p.overall)} overall
            </Typography>
            <Typography variant="body2">by {p.team.name}</Typography>
          </Row>
        </Container>
      ))}
    </Column>
  );
};

export const PlayerEPDraftPickHistory = ({ epId }) => {
  const { data } = useQuery(GET_EP_DRAFT_PICKS_BY_PLAYER, {
    variables: {
      id: epId,
    },
    skip: !epId,
  });
  if (!!data?.epDraftPicksByPlayer.length) {
    return (
      <HoverInteractive
        content={<EPDraftPickList epDraftPicks={data.epDraftPicksByPlayer} />}
      >
        <Icon icon={<ClockHistory />} />
      </HoverInteractive>
    );
  }
};
