import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext, useModal } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { SectionHeader, TooltipSpan } from 'components/reports';
import { roundToX, strContains } from 'helpers/data';
import {
  getNextOrCurrentSeason,
  seasonToEndYear,
  seasonToStartYear,
} from 'helpers/hockeyutils';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { IRIcon, LTIRIcon, PerfBonusIcon, WaiverExemptIcon } from './components/BFBadges';
import BFContractBubble from './components/BFContractBubble';
import BFPlayerBreakdown from './components/BFPlayerBreakdown';
import { formatDollars } from 'helpers/contracts';
import { capitalize } from 'helpers/string';
import Icon from 'components/bdd/Icon';
import { theme } from 'constants';
import { Asterisk } from 'react-bootstrap-icons';
import { BFPlayerHeader } from './BFPlayerHeader';

/* This entry point is useful if you have only a player slug */
export function BFPlayerContractsWrapper({ bddPlayerSlug }) {
  // TODO this
}

export const BFContractTooltip = ({ bfPlayer }) => {
  const retainedAmt = bfPlayer?.retainedCapHit || 0;
  const aav = bfPlayer?.aav || 0;
  const finalCapHit = bfPlayer?.finalCapHit || 0;
  const hasPerfBonus = finalCapHit + retainedAmt != aav;
  return (
    <Column gap={2} style={{ textAlign: "center" }}>
      <Row gap={4}>
        <Typography variant="body1">AAV:</Typography>
        <Typography variant="body2">
          {formatDollars(bfPlayer.aav)} (NHL Active)
        </Typography>
      </Row>

      {!!bfPlayer.retainedPct > 0 && (
        <Container>
          <Row gap={4}>
            <Typography variant="body1">Retention:</Typography>
            <Typography variant="body2">{bfPlayer.retainedPct}%</Typography>
          </Row>
          {!!bfPlayer.retainedDetails &&
            JSON.parse(bfPlayer.retainedDetails)?.map((d) => (
              <Row gap={4}>
                <Typography variant="caption">
                  {d.team_slug.split(" ").map(capitalize).join(" ")}
                </Typography>
                <Typography variant="caption">
                  {formatDollars(d.cap_hit)}
                </Typography>
                <Typography variant="caption">
                  ({roundToX(d.percent, 1)}%)
                </Typography>
              </Row>
            ))}
        </Container>
      )}

      {!!bfPlayer && (
        <Container>
          {bfPlayer.waiverExempt && (
            <Row gap={4}>
              <WaiverExemptIcon showTooltip={false} /> <div>Waiver exempt</div>
            </Row>
          )}
          {bfPlayer.status === "LongTermInjuredReserve" && (
            <Row gap={4}>
              <LTIRIcon showTooltip={false} /> <div>Player is on LTIR</div>
            </Row>
          )}
          {bfPlayer.status === "InjuredReserve" && (
            <Row gap={4}>
              <IRIcon showTooltip={false} /> <div>Player is on IR</div>
            </Row>
          )}
          {hasPerfBonus && (
            <Row gap={4}>
              <PerfBonusIcon showTooltip={false} color={"white"} />{" "}
              <div>Player has perf bonus</div>
            </Row>
          )}
        </Container>
      )}

      <div>
        <em>Click for more info</em>
      </div>
    </Column>
  );
};

export function BFPlayerContracts({ bfPlayer, playerName }) {
  const aavStr = formatDollars(bfPlayer?.aav);
  const capHitStr = formatDollars(bfPlayer?.capHit);
  const futureAavStr = formatDollars(bfPlayer?.futureAav);
  // Figure out if player has perf bonuses...
  // A little Hacky, would rather have a "hasPerfBonus" flag, but this should work
  const retainedAmt = bfPlayer?.retainedCapHit || 0;
  const aav = bfPlayer?.aav || 0;
  const finalCapHit = bfPlayer?.finalCapHit || 0;
  const hasPerfBonus = finalCapHit + retainedAmt != aav;

  const { renderModal, hideModal } = useContext(ModalContext);

  const handleShowModal = () => {
    renderModal({
      size: "xl",
      title: <BFPlayerHeader bfPlayer={bfPlayer} />,
      body: (
        <Container padding={2}>
          <BFPlayerBreakdown bfPlayerSlug={bfPlayer.slug} />
        </Container>
      ),
      footer: (
        <Button variant="secondary" onClick={() => hideModal()}>
          Close
        </Button>
      ),
    });
  };

  // No contracts ever
  if (
    !bfPlayer ||
    (!bfPlayer.hasPastContract &&
      !bfPlayer.hasActiveContract &&
      !bfPlayer.hasFutureContract)
  ) {
    return <Typography variant="caption">No contract</Typography>;
  }

  // Only past contracts
  if (!bfPlayer.hasActiveContract && !bfPlayer.hasFutureContract) {
    return (
      <Container>
        <BFContractBubble onClick={() => handleShowModal()}>
          <Row columnGap={1}>
            <Row gap={4}>
              <Typography>{bfPlayer.mostRecentExpiryStatus}</Typography>
            </Row>
          </Row>
        </BFContractBubble>
      </Container>
    );
  }

  // Only future contract
  if (!bfPlayer.hasActiveContract && bfPlayer.hasFutureContract) {
    return (
      <Container>
        <BFContractBubble onClick={() => handleShowModal()}>
          <Row columnGap={1}>
            <Row gap={4}>
              <Typography>Future</Typography>
            </Row>
          </Row>
        </BFContractBubble>

        <Typography variant="caption" style={{ fontSize: "7pt" }}>
          {!!bfPlayer.hasFutureContract ? (
            <>
              Signed: {futureAavStr} x{bfPlayer.futureRemainingTerm}
            </>
          ) : (
            <>
              Expiry: {bfPlayer.expiryStatus}{" "}
              {!!strContains(
                bfPlayer.expiryStatusDetails,
                "Arbitration Eligible"
              ) && <FontAwesomeIcon icon={faGavel} />}
            </>
          )}
        </Typography>
      </Container>
    );
  }

  // Has a current contract
  return (
    <Container>
      <TooltipSpan content={<BFContractTooltip bfPlayer={bfPlayer} />}>
        <BFContractBubble onClick={() => handleShowModal()}>
          {!!bfPlayer && (
            <Column>
              <Row columnGap={1}>
                <Column>
                  {bfPlayer.waiverExempt && (
                    <WaiverExemptIcon showTooltip={false} size="10px" />
                  )}
                  {bfPlayer.status === "LongTermInjuredReserve" && (
                    <LTIRIcon showTooltip={false} size="10px" />
                  )}
                  {bfPlayer.status === "InjuredReserve" && (
                    <IRIcon showTooltip={false} size="10px" />
                  )}
                  {hasPerfBonus && (
                    <PerfBonusIcon showTooltip={false} size="10px" />
                  )}
                </Column>
                <Row gap={2}>
                  <Row gap={4}>
                    <Typography>{capHitStr}</Typography>
                    <Typography>x{bfPlayer.currentRemainingTerm}</Typography>
                  </Row>
                  {bfPlayer.retainedPct > 0 && (
                    <Icon
                      color={theme.colors.categorical.red}
                      icon={<Asterisk />}
                      size={10}
                    />
                  )}
                </Row>
              </Row>
            </Column>
          )}
        </BFContractBubble>
      </TooltipSpan>

      <Typography variant="caption" style={{ fontSize: "7pt" }}>
        {!!bfPlayer.hasFutureContract ? (
          <>
            Re-signed: {futureAavStr} x{bfPlayer.futureRemainingTerm}
          </>
        ) : (
          <>
            Expiry: {bfPlayer.expiryStatus}{" "}
            {!!strContains(
              bfPlayer.expiryStatusDetails,
              "Arbitration Eligible"
            ) && <FontAwesomeIcon icon={faGavel} />}
          </>
        )}
      </Typography>
    </Container>
  );
}
