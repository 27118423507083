import { gql } from '@apollo/client';

export const GET_SEARCH_FROM_SHORT_LINK = gql`
  query GetSearchFromShortLink($shortKey: String!, $path: String!) {
    searchFromShortLink(shortKey: $shortKey, path: $path)
  }
`;

export const CREATE_SHORT_LINK = gql`
  mutation CreateShortLink($search: String!, $path: String!) {
    createShortLink(search: $search, path: $path) {
      key
    }
  }
`;
