import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { useUser } from '../../../helpers/user';
import { HoverInfo, ItemGrid } from '../../bdd';
import { currentRoleToText, proProjectionToText } from '../playerformhelpers';
import { formatDate, numberToOrdinal } from '../../../helpers/helpers';
import { SubSectionHeader } from '../../reports';
import { getBDDPlayerReportChoices } from '../../../helpers/filters';
import { BddPlayerScoutingReportFragment } from '../../../apollo/queries/scouting.queries';
import {
  getMetastatsAliasMapper,
  getMetastatsHover,
  getRatingHighlight,
} from './metastatshelpers';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { Collapsible } from 'components/bdd/Collapsible';

const DELETE_BDD_SCOUTING_REPORT = gql`
  mutation DeleteBDDScoutingReport($id: ID!) {
    deleteBddPlayerScoutingReport(id: $id) {
      id
    }
  }
`;

const ADD_USER_VIEW = gql`
  mutation AddBDDPlayerReportView($userId: ID!, $reportId: ID!) {
    addBddPlayerReportView(userId: $userId, reportId: $reportId) {
      bddPlayerScoutingReport {
        ...bddPlayerScoutingReportFragment
      }
    }
  }
  ${BddPlayerScoutingReportFragment}
`;

const Styles = styled.div`
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  padding-right: 15px;
  ${(props) =>
    props.noBorder
      ? `
        margin: 0;
        `
      : (props) =>
          props.unViewed
            ? `
            box-shadow: 0 0 8px #15F;
        `
            : `box-shadow: 0 0 5px #333;`}

  font-size: 0.9em;
  overflow: scroll;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .subheader {
    font-size: 0.8em;
    font-weight: bold;
  }
  table {
    width: 100%;
    font-size: 0.8em;
    text-align: center;
    th {
      font-size: 0.7em;
      background: #333;
      color: white;
      padding: 0px 5px;
    }
  }
`;

export default function BDDReport({
  report,
  metastats,
  // isViewed,
  includeHeader = true,
  includeGames = true,
  showDeleteButton = true,
  showTimestamp = true,
  expandable = true,
  noBorder = false,
}) {
  const { user, isUserAdmin } = useUser();
  const aliasToMetas = !!metastats ? getMetastatsAliasMapper(metastats) : {};

  const [open, setOpen] = useState(false);
  const rp = report.rinknetPlayer;
  const ratings = !!report.ratings ? JSON.parse(report.ratings) : {};
  const skillratings = !!report.skillcategoryratings
    ? JSON.parse(report.skillcategoryratings)
    : {};
  const notes = !!report.notes ? JSON.parse(report.notes) : {};
  const games = !!report.games ? report.games.edges.map((e) => e.node) : [];
  const ratingToValue = {};
  const isViewed = report.isViewed;
  Object.keys(ratings).forEach((key) => {
    if (key === 'proProjection' || key === 'currentRole') {
      if (key === 'proProjection') {
        try {
          ratingToValue[key] = proProjectionToText(ratings[key], report.position);
        } catch {
          ratingToValue[key] = ratings[key];
        }
      }
      if (key === 'currentRole') {
        try {
          ratingToValue[key] = currentRoleToText(ratings[key], report.position);
        } catch {
          ratingToValue[key] = ratings[key];
        }
      }
    } else {
      ratingToValue[key] = ratings[key];
    }
  });

  const [deleteReport, { loading, error }] = useMutation(DELETE_BDD_SCOUTING_REPORT, {
    update(cache, { data: { deleteBddPlayerScoutingReport } }) {
      const idToRemove = deleteBddPlayerScoutingReport.id;
      cache.modify({
        fields: {
          bddPlayerScoutingReports(existing = [], { readField }) {
            return existing.filter((ref) => idToRemove !== readField('id', ref));
          },
        },
      });
    },
  });
  const [addUserView, { addViewloading, addViewError }] = useMutation(ADD_USER_VIEW, {
    // update(cache, { data: { addBddPlayerReportView } }) {
    //     cache.modify({ fields: {
    //         bddPlayerReportViews(existing=[]) {
    //             return existing.concat(addBddPlayerReportView.view)
    //         }}
    //     });
    // }
  });

  const handleDelete = (ev) => {
    ev.stopPropagation();
    if (confirm('Are you sure you want to delete this report?')) {
      deleteReport({ variables: { id: report.id } });
    }
  };
  const handleClick = () => {
    // If this is the first time we're opening report, mark as read
    if (!open && isViewed == false) {
      addUserView({ variables: { userId: user?.id, reportId: report.id } });
    }

    setOpen(!open);
  };

  const isTrue = (value) => {
    if (!value || value == 'false') return false;
    return true;
  };

  const reportChoices = getBDDPlayerReportChoices();
  const reportTypeToName = {};
  reportChoices.forEach((opt) => (reportTypeToName[opt.value] = opt.label));

  return (
    <Styles
      onClick={handleClick}
      unViewed={!isViewed}
      noBorder={noBorder}
      styles={expandable ? { cursor: 'pointer' } : {}}
    >
      {includeHeader && (
        <Row>
          <Col>
            <SubSectionHeader style={{ textAlign: 'left' }}>
              <PlayerLinkHover
                name={`${rp.firstname} ${rp.lastname}
            `}
                slug={rp.bddPlayerSlug?.slug}
                variant="h5"
              />
            </SubSectionHeader>
          </Col>
          {!open ? (
            <>
              {report.reportType === 'draft' && !!ratingToValue.draftRound ? (
                <Col className="subheader">
                  <FontAwesomeIcon icon={faClock} /> {ratingToValue.draftRound}
                </Col>
              ) : (
                <Col></Col>
              )}
              {!!ratingToValue.proProjection ? (
                <Col className="subheader">
                  <FontAwesomeIcon icon={faAngleDoubleRight} />{' '}
                  {ratingToValue.proProjection}
                </Col>
              ) : null}
            </>
          ) : null}
          <Col md="auto" className="ml-auto">
            <small>
              <em>{reportTypeToName[report.reportType]}</em>
            </small>
          </Col>
        </Row>
      )}
      <Collapsible open={expandable ? open : true}>
        <Collapsible.Content>
          {includeHeader ? (
            <Row>
              <Col>
                <small>
                  <div>
                    {rp.currentteam} ({rp.currentleagueabbr})
                  </div>
                  <div>
                    {rp.heightStr}/{rp.weight}lbs
                  </div>
                  <div>{formatDate(rp.birthdate)}</div>
                  <div>Shoots/Catches: {rp.handedness}</div>
                  {!!rp.draftyear ? (
                    <div>
                      <b>Draft:</b> {numberToOrdinal(rp.overall)} OVR, {rp.draftyear} by{' '}
                      {rp.selectedbyteam}
                    </div>
                  ) : null}
                </small>
              </Col>
              <Col>
                <small>
                  <div>
                    {!!report.positionDetail ? (
                      <b>{report.positionDetail}</b>
                    ) : (
                      <em>No position specified</em>
                    )}
                  </div>
                  {includeGames && (
                    <>
                      <b>
                        {games.length} {games.length ? games[0].leaguename : null} Games
                      </b>
                      {games.map((g) => (
                        <div key={g.id}>
                          {formatDate(g.date)} {g.awayteam.shortname} ({g.awayteamscore})
                          @ {g.hometeam.shortname} ({g.hometeamscore})
                        </div>
                      ))}
                    </>
                  )}
                </small>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm>
                <small>
                  {!!report.positionDetail ? (
                    <b>{report.positionDetail}</b>
                  ) : (
                    <em>No position specified</em>
                  )}
                </small>
              </Col>
              <Col sm>
                <small>
                  {includeGames && (
                    <>
                      <b>
                        {games.length} {games.length ? games[0].leaguename : null} Games
                      </b>
                      {games.map((g) => (
                        <div key={g.id}>
                          {formatDate(g.date)} {g.awayteam.shortname} ({g.awayteamscore})
                          @ {g.hometeam.shortname} ({g.hometeamscore})
                        </div>
                      ))}
                    </>
                  )}
                </small>
              </Col>
            </Row>
          )}

          <hr></hr>

          <table>
            <tbody>
              <tr>
                {Object.keys(ratings).map((r) => (
                  <th key={r}>{r}</th>
                ))}
              </tr>
              <tr>
                {Object.keys(ratings).map((r) => (
                  <td key={r}>
                    {['powerPlay', 'penaltyKill'].includes(r)
                      ? isTrue(ratingToValue[r])
                        ? 'Yes'
                        : 'No'
                      : ratingToValue[r]}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <ItemGrid
            items={Object.keys(skillratings)}
            numCols={2}
            render={(field) =>
              !!skillratings[field] && (
                <>
                  <b>{field}</b>:{' '}
                  <span
                    style={{
                      padding: '2px',
                      borderRadius: '5px',
                      background: getRatingHighlight(
                        field,
                        skillratings[field],
                        aliasToMetas
                      ),
                    }}
                  >
                    <HoverInfo
                      content={getMetastatsHover(
                        field,
                        skillratings[field],
                        aliasToMetas
                      )}
                    >
                      {skillratings[field]}
                    </HoverInfo>
                  </span>
                  {!!notes[field] ? (
                    <>
                      {' '}
                      - "<small>{notes[field]}</small>"
                    </>
                  ) : null}
                </>
              )
            }
            keys={Object.keys(skillratings)}
          />
          <div>
            <b>Overall Notes</b>
          </div>
          <div>
            <p>"{notes.Overall}"</p>
          </div>
          <br></br>
          {isUserAdmin() && showDeleteButton ? (
            <div>
              <Button variant="outline-danger" size="sm" onClick={handleDelete}>
                Delete Report
              </Button>
            </div>
          ) : null}
        </Collapsible.Content>
      </Collapsible>
      {showTimestamp && (
        <small>
          <em>
            Created {formatDate(report.creationDate, { useTimeZone: true })} by{' '}
            {report.user.username}
          </em>
        </small>
      )}
    </Styles>
  );
}
