import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { DASHBOARD_ROUTE, theme } from 'constants';
import denTextTransparent from 'assets/images/den_text.jpg';
import { GET_NOTIFICATION_COUNT } from 'apollo/queries/navigation.queries';
import { useQuery } from '@apollo/client';
import { Typography } from '../Typography';
import client from 'apollo/client';
import { Container, Row } from '../Layout';
import {
  useNotificationStore,
  useNotifications,
} from 'components/Notifications/hooks/useNotificationStore';
import { useUser } from 'helpers/user';
import { useBreadcrumbs } from '../Page/Breadcrumbs';

const LogoButtonStyles = styled.div(({ variant }) => ({
  ...theme.buttons.base,
  '&: hover': {
    textDecoration: 'none',
    img: {
      transform: 'scale(1.1);',
    },
  },
}));

const NotificationBadge = styled(Container)(({ variant }) => ({
  position: 'absolute',
  borderRadius: '50%',
  ...(variant == 'horizontal'
    ? {
        top: theme.spacing[2],
        right: -18,
      }
    : {
        top: theme.spacing[1],
        right: theme.spacing[2],
      }),
}));

const Logo = styled.img(({ variant, hasNotifications }) => ({
  width: variant != 'horizontal' ? '70%' : 'unset',
  height: variant == 'horizontal' ? 36 : 'unset',
  marginLeft: variant == 'horizontal' ? theme.spacing[2] : 0,
  marginTop: theme.spacing[2],
  marginBottom: theme.spacing[2],
  transition: 'transform 0.2s ease-in-out',
}));

export default function LogoButton({ variant, logo }) {
  const { user } = useUser();
  const { resetBreadcrumbs } = useBreadcrumbs();

  useNotifications({
    userId: user.id,
    pollInterval: 30 * 1000,
    variables: {
      isViewed: false,
    },
  });

  const unreadNotificationCount = useNotificationStore(
    (state) => state.unreadNotificationCount
  );

  return (
    <LogoButtonStyles as={Link} to={DASHBOARD_ROUTE} onClick={resetBreadcrumbs}>
      <Container>
        <Row justifyContent={variant == 'horizontal' ? 'start' : 'center'}>
          <Logo
            variant={variant}
            hasNotifications={unreadNotificationCount > 0}
            src={logo || denTextTransparent}
          />
          {unreadNotificationCount > 0 && (
            <NotificationBadge
              variant={variant}
              width={18}
              height={18}
              backgroundColor={theme.colors.states.danger}
            >
              <Typography
                variant="caption"
                textAlign="center"
                color={theme.colors.dark.text.primary}
              >
                {unreadNotificationCount}
              </Typography>
            </NotificationBadge>
          )}
        </Row>
      </Container>
    </LogoButtonStyles>
  );
}
