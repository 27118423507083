import { useQuery } from "@apollo/client";
import { GET_PLAYER_GENERATED_REPORT_SUMMARY } from "apollo/queries/generative.queries";
import { Container } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import usePlaceholder from "components/Placeholder/usePlaceholder";
import { formatDate } from "helpers/helpers";

export const GeneratedScoutingSummaryBody = ({
  report, // a BDDGeneratedReportSummary object
}) => {
  const response = report?.response;
  if (!report)
    return (
      <Typography variant="body1">No generated report available</Typography>
    );
  return (
    <Container>
      <Typography variant="caption">
        Generated on {formatDate(response.createdAt)} using summarization AI.
        Based on reports from {formatDate(report.startDate)} to{" "}
        {formatDate(report.endDate)}.
        <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
          {response.generation.trimStart()}
        </Typography>
      </Typography>
    </Container>
  );
};

export const GeneratedScoutingSummary = ({ rinknetId }) => {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_GENERATED_REPORT_SUMMARY, {
      variables: { rinknetId },
    })
  );

  const report = data?.playerGeneratedReportSummary;
  if (!!placeholder) return placeholder;
  return <GeneratedScoutingSummaryBody report={report} />;
};
