import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { IconButton } from '../Button';
import { ArrowLeft } from 'react-bootstrap-icons';

export const BackButton = ({}) => {
  const history = useHistory();
  const location = useLocation();
  const [originPath] = useState(location.state?.origin);

  return (
    history.length > 1 && (
      <IconButton
        icon={<ArrowLeft />}
        size={14}
        onClick={() => {
          if (!!originPath) {
            history.push(originPath);
          } else {
            history.goBack();
          }
        }}
      />
    )
  );
};
