import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isHotkey, { isKeyHotkey } from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement, Range } from 'slate';
import { withHistory } from 'slate-history';
import styled from 'styled-components';
import {
  ListOl,
  ListUl,
  TypeBold,
  TypeH1,
  TypeH2,
  Image as IconImage,
  TypeItalic,
  TypeUnderline,
  Link,
} from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import { theme } from 'constants/theme';
import { VerticalDivider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { ToolbarIconButton } from '.';
import { HyperlinkField } from './components';
import {
  MentionField,
  MentionToolbarButton,
  withMentions,
  wrapMention,
} from './components/Mentions';
import {
  PlaylistField,
  PlaylistToolbarButton,
  withPlaylists,
} from './components/Playlists';
import { MediaField, MediaToolbarButton, withMedia } from './components/Media';
import { CURRENT_TEXT_EDITOR_VERSION } from './helpers/constants';

export const EMPTY_TEXT = [
  {
    type: 'p',
    version: CURRENT_TEXT_EDITOR_VERSION,
    children: [{ text: '' }],
  },
];

export const isTextEmpty = (text) => JSON.stringify(text) == JSON.stringify(EMPTY_TEXT);

const Header = styled.div({
  padding: theme.spacing[2],
  backgroundColor: theme.colors.light.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'auto',
});

const Body = styled.div({
  ...theme.typography.body3,
  minHeight: 80,
  padding: theme.spacing[3],
  backgroundColor: theme.colors.light.background,
});

const ReadOnlyBody = styled.div(({ size, backgroundColor }) => ({
  ...theme.typography[size == 'sm' ? 'stat' : 'body3'],
  // minHeight: 80,
  ...(size == 'sm'
    ? { paddingLeft: theme.spacing[0.5], paddingRight: theme.spacing[0.5] }
    : { margin: theme.spacing[1], padding: theme.spacing[2] }),
  backgroundColor: backgroundColor || theme.colors.light.background,
  'h5': {
    ...theme.font.base
  },
  'h4': {
    ...theme.font.base
  }
}));

const TableStyles = styled.div({
  table: {
    tbody: {
      td: {
        ...theme.borders.light,
        ...theme.borders.thin,
        padding: theme.spacing[2],
      },
    },
  },
});

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const MENTION_KEY = '@';
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const TextEditor = ({
  header,
  text,
  onTextUpdated,
  placeholder,
  readOnly,
  size,
  backgroundColor,
  disableAutoFocus,
  actionButtons,
}) => {
  const renderElement = useCallback(
    (props) => <Element {...props} readOnly={readOnly} />,
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} readOnly={readOnly} />, []);

  const editor = useMemo(
    () => withMedia(withPlaylists(withMentions(withHistory(withReact(createEditor()))))),
    []
  );

  const onKeyDown = (event) => {
    const { selection } = editor;

    // https://github.com/ianstormtaylor/slate/blob/main/site/examples/mentions.tsx
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }

      if (event.key == MENTION_KEY) {
        wrapMention(editor);
      }
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];

        toggleMark(editor, mark);
      }
    }
  };

  // const editorRef = useRef();
  // if (!editorRef.current) editorRef.current = withHistory(withReact(createEditor()));
  // const editor = editorRef.current;

  useEffect(() => {
    // Reset editor if text is empty
    if (JSON.stringify(text) == JSON.stringify(EMPTY_TEXT)) {
      editor.history = {
        redos: [],
        undos: [],
      };

      editor.selection = null;
      editor.focus = null;
    }

    editor.children = text;
    editor.onChange();
  }, [text]);

  return (
    <TableStyles>
      <Slate
        editor={editor}
        initialValue={text}
        onKeyDown={onKeyDown}
        onChange={onTextUpdated}
      >
        {readOnly ? (
          <ReadOnlyBody size={size} backgroundColor={backgroundColor}>
            <Editable
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder || 'Enter some rich text…'}
              value={text}
            />
          </ReadOnlyBody>
        ) : (
          <Container>
            <Header>
              {/* <Title>{header}</Title> */}
              <Typography variant="h6">
                <Row columnGap={3} alignItems="center">
                  <Row columnGap={1}>
                    <MarkButton format="bold" icon={<TypeBold />} />
                    <MarkButton format="italic" icon={<TypeItalic />} />
                    <MarkButton format="underline" icon={<TypeUnderline />} />
                    <MarkButton format="hyperlink" icon={<Link />} />
                    <MentionToolbarButton />
                  </Row>
                  <VerticalDivider />
                  <Row columnGap={2}>
                    <BlockButton format="heading-one" icon={<TypeH1 />} />
                    <BlockButton format="heading-two" icon={<TypeH2 />} />
                  </Row>
                  <VerticalDivider />
                  <Row columnGap={2}>
                    <BlockButton format="numbered-list" icon={<ListOl />} />
                    <BlockButton format="bulleted-list" icon={<ListUl />} />
                  </Row>
                  <VerticalDivider />
                  <Row columnGap={2}>
                    <MediaToolbarButton />
                    <PlaylistToolbarButton />
                    {actionButtons}
                  </Row>
                  {/* <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" /> */}
                </Row>
              </Typography>
            </Header>
            <Body>
              <Editable
                style={{ outline: 'none' }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder || 'Enter some rich text…'}
                spellCheck
                autoFocus={!disableAutoFocus}
                onKeyDown={onKeyDown}
              />
            </Body>
          </Container>
        )}
      </Slate>
    </TableStyles>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element, readOnly }) => {
  const style = { margin: 0, textAlign: element.align };
  const props = { readOnly, attributes, children, element };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case 'heading-two':
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'media':
      return <MediaField {...props} />;
    case 'table':
      return (
        <table>
          <tbody {...attributes}>{children}</tbody>
        </table>
      );
    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-cell':
      return <td {...attributes}>{children}</td>;
    case 'mention':
      return <MentionField {...props} />;
    case 'playlist':
      return <PlaylistField {...props} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ readOnly, attributes, children, leaf, text }) => {
  const props = { readOnly, attributes, children, element: text };

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.hyperlink) {
    children = <HyperlinkField {...props} />;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToolbarIconButton
      as={IconButton}
      icon={icon}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToolbarIconButton
      as={IconButton}
      icon={icon}
      active={isMarkActive(editor, format)}
      onClick={() => {
        toggleMark(editor, format);
      }}
    />
  );
};
