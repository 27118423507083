import React, { useState } from 'react';
import { BDDTabLink, BDDTabLinkContainer } from 'components/bdd';
import { LIST_ID_TYPES } from 'constants';
import CustomListPlayerModal from './CustomListPlayerModal';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { Gear } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { useUser } from "helpers/user";

export const PlayerModes = ({
  listIdType,
  getLink,
  showCustom = false,
  customGearIconButton,
}) => {
  const { isUserAdmin } = useUser();
  const [show, setShow] = useState(false);

  const getBddTabLink = (mode, label) => (
    <BDDTabLink to={getLink(mode)} matchSearchStart>
      {label}
    </BDDTabLink>
  );

  return (
    <>
      <BDDTabLinkContainer leftAlign>
        <>
          {getBddTabLink("all", "All")}
          {getBddTabLink("bio", "Bio")}
          {getBddTabLink("scouting", "Scouting")}
          {getBddTabLink("sportlogiq", "SL")}
          {listIdType === LIST_ID_TYPES.bdd_player_slug &&
            getBddTabLink("war55", "WAR")}
          {getBddTabLink("eliteprospects", "EP")}
          {isUserAdmin() &&
            getBddTabLink("generatedsummaries", "Gen. Summaries")}
          {!!showCustom && (
            <BDDErrorBoundary errorContent={<b>Error</b>}>
              <BDDTabLink
                to={getLink("custom")}
                matchSearchStart
                style={{ margin: "2px" }}
              >
                Custom
              </BDDTabLink>
              {customGearIconButton}
            </BDDErrorBoundary>
          )}
        </>
      </BDDTabLinkContainer>
    </>
  );
};
