import React from 'react';
import {
  Button,
  ButtonGroup as BootstrapButtonGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from 'constants';
import { TooltipSpan } from 'components/reports';
import { Typography } from '../Typography';
import { ChevronDown } from 'react-bootstrap-icons';
import Icon from '../Icon';
import { Container, Row } from '../Layout';
import { useBreakpoints } from '../context';
import { StyledBDDSelect } from '../Select/StyledBDDSelect';

const DropdownStyles = styled.div({
  '.dropdown-toggle::after': {
    display: 'none',
  },
});

const StyledButtonGroup = styled(BootstrapButtonGroup)({
  alignItems: 'stretch',
  width: (props) => props.width,
  whiteSpace: ({ bddnowrap }) => (bddnowrap ? 'nowrap' : 'normal'),
  overflowX: ({ bddnowrap }) => (bddnowrap ? 'auto' : 'normal'),
  // paddingBottom: ({ bddnowrap }) => bddnowrap ? '10px' : 0,
});

const ButtonGroupLabel = styled.div({
  ...theme.typography.body1,
});

const ButtonGroupCaption = styled.div({
  ...theme.typography.body2,
});

export const ButtonGroup = ({
  options,
  selectedValue,
  onClick,
  width,
  noWrap,
  responsive,
  tooltipPlacement = 'top',
  variant = 'body2',
}) => {
  const { isMobile } = useBreakpoints();

  if (isMobile && responsive) {
    return (
      <StyledBDDSelect
        options={options}
        selectedValue={selectedValue}
        onChange={(_, v) => {
          const option = options.find((o) => o.value === v);
          onClick?.(option);
        }}
        width={width}
        variant={'outlined'}
        size={'sm'}
      />
    );
  }

  return (
    <StyledButtonGroup width={width} bddnowrap={noWrap ? 1 : 0}>
      {options.map((option, i) => {
        const color = option.color || 'dark';

        if (!!option.options) {
          const optionToDisplay =
            option.options.find((o) => o.value === selectedValue) || option;

          return (
            <Dropdown
              key={`button-group-${i}`}
              as={Button}
              size="sm"
              variant={
                option.options.find((o) => o.value === selectedValue)
                  ? color
                  : `outline-${color}`
              }
              style={{ padding: 0 }}
            >
              <DropdownStyles>
                <Dropdown.Toggle
                  as={Container}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: `${theme.spacing[0.5]}px ${theme.spacing[3]}px`,
                  }}
                >
                  <Row columnGap={1}>
                    <Typography variant={variant}>
                      <b>{optionToDisplay.label}</b>
                    </Typography>
                    <Icon icon={<ChevronDown />} />
                  </Row>
                  {optionToDisplay.caption && (
                    <Typography variant={variant}>{optionToDisplay.caption}</Typography>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* {loading && <Typography variant="body2">Loading..</Typography>} */}
                  {option.options?.map((o, j) =>
                    o.renderOption ? (
                      <Dropdown.Item key={o.value || j} onClick={() => onClick(o)}>
                        {o.renderOption()}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item key={o.value || j} onClick={() => onClick(o)}>
                        <Typography variant={variant}>
                          <b>{o.label}</b>
                        </Typography>
                        {o.caption && (
                          <Typography variant={variant}>{o.caption}</Typography>
                        )}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </DropdownStyles>
            </Dropdown>
          );
        }

        return (
          <Button
            key={`button-${option.value || i}`}
            variant={option.value === selectedValue ? color : `outline-${color}`}
            size="sm"
            style={{ padding: 0 }}
            onClick={() => onClick(option)}
            disabled={option.disabled}
          >
            {option.renderOption ? (
              option.renderOption()
            ) : (
              <Container
                style={{ padding: `${theme.spacing[0.5]}px ${theme.spacing[3]}px` }}
              >
                <TooltipSpan placement={tooltipPlacement} content={option.hover}>
                  <Typography variant={variant}>
                    <b>{option.label}</b>
                  </Typography>
                  {option.caption && (
                    <Typography variant={variant}>{option.caption}</Typography>
                  )}
                </TooltipSpan>
              </Container>
            )}
          </Button>
        );
      })}
    </StyledButtonGroup>
  );
};
