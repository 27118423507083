import React, { useState } from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { Row } from './Layout';
import { FeedbackPrompt } from 'components/Feedback/components/FeedbackPrompt';
import { BUG_TYPE_OPTIONS, FEEDBACK_TYPES } from 'components/Feedback/constants';
import { Collapsible } from 'components/bdd/Collapsible';

const Style = styled.div`
  margin: auto;
  max-width: 500px;

  // background: #A00;
  padding: 10px;
  color: #a00;
  border-radius: 10px;
  border: 2px solid #a00;

  cursor: pointer;
`;

export default function BDDApiError({ title, error }) {
  const [open, setOpen] = useState(false);

  console.log(error.message);
  try {
    if (error.message) {
      var details = {
        type: null,
        code: null,
        msg: error.message,
      };
    }
    if (error.networkError) {
      var details = {
        type: error.networkError.response.statusText,
        code: error.networkError.response.status,
        msg: error.networkError.result.message,
      };
    } else if (error.graphQLErrors.length > 0) {
      var details = {
        msg: error.graphQLErrors[0].message,
        code: null,
        type: null,
      };
    }
  } catch {
    var details = {
      type: null,
      code: null,
      msg: 'Details not found',
    };
  }
  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <Style>
          <Row gap={4} justifyContent="space-between">
            <h3>{title || 'Error fetching data'}</h3>

            <div onClick={(ev) => ev.stopPropagation()}>
              <FeedbackPrompt
                promptText="Report bug"
                defaultValues={{
                  feedbackType: FEEDBACK_TYPES.BUG,
                  bugType: BUG_TYPE_OPTIONS.API,
                  sourceUrl: window.location.href,
                  payload: { apiError: error },
                }}
              />
            </div>
          </Row>
          <small>
            {details.type} {!!details.code ? <>({details.code}) - </> : null}{' '}
            {details.msg}
          </small>
        </Style>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <pre>{JSON.stringify(error, null, 4)}</pre>
      </Collapsible.Content>
    </Collapsible>
  );
}
