import { getCurrentSeason } from 'helpers/hockeyutils';

export const DEFAULT_SKATER_SEASON_QUERY = {
  target: 'ep_player_season_stats',
  extras: {},
  format: {},
  id_type: 'eliteprospects',
};

export const SKATER_STATS = [
  'GP',
  'G',
  'A',
  'PTS',
  'PM',
  'PIM',
  'G/GP',
  'A/GP',
  'PTS/GP',
  'PM/GP',
  'PIM/GP',
];

export const SKATER_GAME_STATS = ['G', 'A', 'PTS', 'SOG', 'PPG', 'SHG', 'PM', 'PIM'];
export const targetToSkaterStats = {
  ep_player_season_stats: SKATER_STATS,
  ep_player_game_stats: SKATER_GAME_STATS,
};

export const GOALIE_STATS = [
  'GP',
  'SVP',
  'GAA',
  'GA',
  'SA',
  'SVS',
  'GA/GP',
  'SA/GP',
  'SVS/GP',
];

export const GOALIE_GAME_STATS = ['SVP', 'GAA', 'GA', 'SA', 'SVS'];
export const targetToGoalieStats = {
  ep_player_season_stats: GOALIE_STATS,
  ep_player_game_stats: GOALIE_GAME_STATS,
};

export const NATIONALITIES = [
  'Australia',
  'Austria',
  'Belarus',
  'Belgium',
  'Bulgaria',
  'Canada',
  'China',
  'Croatia',
  'Czechia',
  'Denmark',
  'England',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Hungary',
  'Israel',
  'Italy',
  'Japan',
  'Kazakhstan',
  'Latvia',
  'Lithuania',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Poland',
  'Romania',
  'Russia',
  'Scotland',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sweden',
  'Switzerland',
  'Turkey',
  'U.K.',
  'Ukraine',
  'USA',
  'Wales',
];

export const NATIONALITY_OPTIONS = NATIONALITIES.map((nationality) => ({
  label: nationality,
  value: nationality,
}));

export const MIN_SEASON = '20002001';
export const SEASON_OPTIONS = Array.from(
  {
    length:
      parseInt(getCurrentSeason().toString().slice(4)) - parseInt(MIN_SEASON.slice(0, 4)),
  },
  (_, i) => {
    const startYear = parseInt(MIN_SEASON.slice(0, 4)) + i;
    const endYear = startYear + 1;
    return {
      label: `${startYear}-${endYear}`,
      value: `${startYear}-${endYear}`,
    };
  }
).reverse();

export const DRAFT_YEAR_OPTIONS = [...SEASON_OPTIONS].reverse().map((o) => ({
  value: o.value.slice(5),
  label: parseInt(o.value.slice(5)),
}));

export const POSITIONS_FDG = ['F', 'D', 'G', 'All Skaters'];
export const DETAILED_POSITIONS = ['G', 'F', 'D', 'W', 'LW', 'RW', 'C'];

export const LEAGUE_LEVEL_OPTIONS = [
  { value: 'professional', label: 'Professional' },
  { value: 'major-junior', label: 'Major Junior' },
  { value: 'junior-a', label: 'Junior A' },
  { value: 'college', label: 'College' },
  { value: 'junior-u20', label: 'Junior U20' },
  { value: 'junior-u18', label: 'Junior U18' },
  { value: 'semi-professional', label: 'Semi-Professional' },
  { value: 'high-school', label: 'High School' },
];

export const LEAGUES_TO_IGNORE_BY_LEVEL = {
  'junior-u18': ['usdp'],
};
