import { useSearchParamsState } from 'components/bdd/hooks';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDInputField from 'components/form/bddinputfield';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Notes } from './Notes';
// import PlateTextEditor from 'components/bdd/TextEditor/PlateTextEditor';
// import { EMPTY_TEXT, TextEditor } from 'components/bdd/TextEditor';

export const TestNotes = ({}) => {
  const [entity, setEntity] = useSearchParamsState(null, 'entity');

  return (
    <Container padding={3}>
      <Column gap={2}>
        <Typography variant="h4">Testing Notes</Typography>
        <Formik initialValues={entity || {}} onSubmit={(values) => setEntity(values)}>
          {({ isSubmitting, values, errors }) => {
            return (
              <Form>
                <Row columnGap={2}>
                  <Field
                    type="text"
                    name="entityId"
                    placeholder="Entity ID"
                    component={BDDInputField}
                  />
                  <Field
                    type="text"
                    name="entityType"
                    placeholder="Entity Name"
                    component={BDDInputField}
                  />
                  <Button
                    type="submit"
                    variant="outline-dark"
                    disabled={!values?.entityId || !values?.entityType}
                  >
                    <Typography variant="body1">Get Notes</Typography>
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
        {/* <Border>
          <PlateTextEditor
            text={EMPTY_TEXT}
            onTextUpdated={(text) => {
              // console.log('new', text);
            }}
          />
        </Border>
        <Border>
          <TextEditor
            text={EMPTY_TEXT}
            onTextUpdated={(text) => {
              console.log('old',text);
            }}
          />
        </Border> */}
        {entity && <Notes entityId={entity.entityId} entityType={entity.entityType} />}
      </Column>
    </Container>
  );
};
