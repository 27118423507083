

export const CAP_TERM_DEFINITIONS = {
  projectedCapHit: 'System cap hit if roster remains the same. Sum of 1) past daily cap hits based on active roster on every day, 2) the  projected remaining daily cap hits if the roster stays the same and 3) dead cap',
  projectedCapSpace: 'System cap space accrued by opearting under the upper limit Sum of 1) past daily accrued cap space, 2) the projected remaining accrued cap space if roster stays the same. When a team is using LTI this also includes their remaining LTI salary pool and any space',
  currentCapHit: "Full season cap hit of today's roster. Sum of cap hits from 1) active roster including injured players, 2) dead cap, and 3) buried contract penalties",
  currentCapSpace: 'The largest cap hit that could be added to the roster today. When a team is using LTI this is equal to their remaining LTI salary pool',
  rosterCapHit: 'Cap hit of current roster only, excluding dead cap and buried penaltys',
  projectedLtirUsed: 'Projected cap space gained by operating over the upper limit while in LTI',
  accruedCapSpace: 'Daily accrued cap space by operating under the Upper Limit OR in LTI and operating below the ACSL',
  accumulatedSalary: 'Season-to-date accumulated daily cap hits',
  tradeDeadlineCapSpace: 'The largest cap hit that could be added to the roster at the trade deadline. When a team is using LTI this is equal to their remaining LTI salary pool',
  retentionCount: 'Number of contract retention spots used, max is 3.',
  ltiACSL: 'Daily LTI Accruable Cap Space Limit (ACSL) currently applicable to team. If team is in LTI and operating below this value, they will continue to accrue cap space',
  ltiCapture: 'The difference between a team\'s upper limit (reduced by past season perf. overages) and the teams cap hit when entering LTI (ACSL).  You want this number to be as close to $0 as possible.',
  ltirSalaryRemaining: 'The remaining salary pool available while in LTI. Current Cap Hit - LTI ACSL - LTI Bonus Overage',
  ltirBonusRemaining: 'The remaining bonus pool available while in LTI. Current Perf Bonus - LTI Starting Perf Bonus',
  deadCapHit: 'All dead cap from buyouts, rentention, terminations, and aggreements. Does not include past season performance bonus overage',
  buriedCapHit: 'Cap hit from buried contract penalties for active players not on NHL roster',
  pastSeasonBonusOverage: 'Cap hit penalty from past season performance bonus overage',
  projectedPerfBonus: 'Projected perf bonus if roster remains the same'
}