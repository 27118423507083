import { GET_RINKNET_GAMES_BY_TEAM } from "apollo/queries/game.queries"
import { useSelect } from "."
import { useQuery } from "@apollo/client"
import { formatDate } from "helpers/helpers"


export const useRinknetGamesSelect = ({
  initialValue,
  leagueid,
  teamid,
  season,
  isMulti=false,
  selectProps={},
  onChange
}) => {
  const { data, loading, error } = useQuery(GET_RINKNET_GAMES_BY_TEAM, {
    variables: { leagueid: leagueid, season, teamid: teamid }
  })

  const gameOptions = !!data
    ? data.rinknetGames.map((g) => ({
        label: `${formatDate(g.date)} ${g.hometeam.shortname} vs ${
          g.awayteam.shortname
        }`,
        value: g.id,
      }))
    : [];

  const { select, selected, selectedValue } = useSelect({
    options: gameOptions || [],
    initialSelectedValue: initialValue,
    selectProps: {
      isLoading: loading,
      isMulti,
      placeholder: 'Filter games...',
      isSearchable: true,
      ...selectProps
    },
    onChange
  })

  return {
    select,
    selectedValue,
    selected,
    gameOptions
  }
}