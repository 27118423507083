import { gql } from "@apollo/client";


export const BDDTradeFragment = gql`fragment bddTradeFragment on BDDTrades {
  id
  numTeams
  includesRetention
  includesMoneyLaundering
  createdBy { id name }
  createdAt
  lastModified
  isExecuted
  executionDetails
  isAnalyzed
  tradedPlayers {
    tradeId
    playerSlug
    toTeamSlug
    fromTeamSlug
    contractId
    capHit
    capHitAffecting
    salary
    retainedSalary
    retainedSalaryPct
    isMinor
    signingRights
    remainingTerm
    isLayover
    analysis
    bddPlayer {
      id
      slug
      firstname
      lastname
      bfSlug
      bfPlayer {
        slug
        capHit
        aav
        retainedCapHit
        retainedPct
        retainedDetails
        hasPastContract
        hasActiveContract
        hasFutureContract
        currentRemainingTerm
        expiryStatus
        expiryStatusDetails
        futureCapHit
        futureRemainingTerm
      }
    }
  }
  tradedDraftPicks {
    tradeId
    toTeamSlug
    fromTeamSlug
    pickId
    year
    round
    originalTeamSlug
    isConditional
    conditionalDetails
    bfDraftPick {
      id
      year
      round
      overall
      originalTeam { id slug name abbreviation nhlid }
      currentTeam { id slug name abbreviation nhlid }
    }
    analysis
  }
  teamSummaries {
    teamSlug
    slTeam {
      id
      nhlid
      slug
      longname
      shorthand
    }
    numPlayersIn
    numPlayersOut
    numPicksIn
    numPicksOut
    capHitIn
    capHitOut
    capHitChange
    capHitAffectingIn
    capHitAffectingOut
    capHitAffectingChange
    salaryIn
    salaryOut
    salaryChange
    analysis
  }
}`

export const BDDSigningFragment = gql`fragment bddSigningFragment on BDDSignings {
  id
  createdByUserId
  createdBy { id name }
  lastModified
  createdAt
  bddPlayerSlug
  bddPlayer {
    slug
    firstname
    lastname
  }
  capHitStr
  capHitNhl
  capHitAhl
  term
}`


export const UPDATE_BDD_TRADE = gql`
  mutation UpdateBddTrade(
    $tradeId: ID!
    $input: BDDTradeInput 
    $analyze: Boolean
  ) {
    updateBddTrade (
      tradeId: $tradeId
      input: $input
      analyze: $analyze
    ) {
      bddTrade {
        ...bddTradeFragment
      }
    }
  }
${BDDTradeFragment}
`


export const UPDATE_BDD_SIGNING = gql`
  mutation UpdateBddSigning(
    $input: UpdateBDDSigningInput! 
  ) {
    updateBddSigning (
      input: $input
    ) {
      bddSigning {
        ...bddSigningFragment
      }
    }
  }
${BDDSigningFragment}
`

export const ANALYZE_TRADE = gql`
  query AnalyzeTrade(
    $trade: BDDTradeInput!
  ) {
    analyzeTrade (
      trade: $trade
    ) {
      teamSlug
      analysis
    }
  }
`