import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { theme, LIST_ID_TYPES } from 'constants';
import { WindowContext } from 'components/bdd/context';
import { useOnScreenRef } from 'components/bdd/hooks';
import {
  PLBio,
  PLEPData,
  PLListNotes,
  PLPlayerGeneratedSummary,
  PLPlayerNotes,
  PLScoutingRanks,
  PLSportlogiqData,
  PLSportlogiqSkills,
  PLWar,
} from "./ListPlayerComponents";
import { getCurrentSeason, seasonToEndYear } from "helpers/hockeyutils";
import { getDefaultListSkillStatDefs } from "./SportlogiqSkills";
import { PlayerName } from "./PlayerBio";

const ListPlayerContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  flexWrap: "wrap",
});

const FieldsContainer = styled.div(({ vertical, center, noWrap }) => ({
  display: "flex",
  flexWrap: noWrap ? "nowrap" : "wrap",
  columnGap: theme.spacing[2],
  rowGap: theme.spacing[1],
  justifyContent: center ? "center" : "start",
  width: "100%",
}));

const ListPlayer = ({
  rinknetPlayer,
  bddPlayer,
  slPlayerMaster,
  notes,
  mode,
  customFields = [],
  customSettings = {},
  list,
  filters,
  slStats,
  justName,
  allowHover = true,
  overridePosition = null, // can supply a jsx snippet to override position info (e.g. in pro objectives)
}) => {
  const { width } = useContext(WindowContext);
  const vertical = width <= theme.breakpoints.lg;
  const { ref, isVisible, hasBeenVisible } = useOnScreenRef({
    resetVariable: mode,
  });
  const shouldRender = isVisible;
  const rinknetPlayers = useMemo(
    () =>
      list.players
        .filter((player) => !!player.rinknetPlayer || !!player.bddPlayer)
        .map((player) =>
          list.idType == LIST_ID_TYPES.bdd_player_slug
            ? player.bddPlayer.rinknetPlayer
            : player.rinknetPlayer
        )
        .sort(),
    [list]
  );
  const rinknetIds = useMemo(
    () =>
      rinknetPlayers
        .filter((rp) => !!rp)
        .map((rp) => rp.realId)
        .sort(),
    [list]
  );

  const bddPlayerSlugs = useMemo(
    () =>
      list.players
        .filter(
          (player) =>
            (!!player.rinknetPlayer && !!player.rinknetPlayer?.bddPlayerSlug) ||
            !!player.bddPlayer
        )
        .map((player) =>
          list.idType == LIST_ID_TYPES.bdd_player_slug
            ? player.bddPlayer?.slug
            : player.rinknetPlayer.bddPlayerSlug?.slug
        )
        .sort(),
    [list]
  );

  const draftYear = list.draftYear || list.draftListYear;
  const isPastDraftYear =
    !!draftYear && draftYear != seasonToEndYear(getCurrentSeason());
  const defaultSeason = isPastDraftYear
    ? parseInt(`${draftYear - 1}${draftYear}`)
    : null;

  const renderField = (field, props) => {
    const fieldProps = {
      // shared props for each component
      rinknetPlayer,
      bddPlayer,
      slPlayerMaster,
      rinknetPlayers,
      rinknetIds,
      bddPlayerSlugs,
      list,
      listType: list.listType,
      draftYear: draftYear,
      defaultSeason,
      shouldRender,
      hideHeader: customSettings?.hideHeader,
      ...customSettings?.[field],
      ...props,
    };
    switch (field) {
      case "bio":
        return null;
      case "war":
        return (
          <PLWar
            key="war"
            bddPlayer={bddPlayer}
            listIdType={list.idType}
            shouldRender={shouldRender}
          />
        );
      case "scoutingranks":
        return <PLScoutingRanks key="scoutingranks" {...fieldProps} />;
      case "epstats":
        return <PLEPData key="epstats" {...fieldProps} />;
      case "slstats":
        return (
          <PLSportlogiqData
            key="slstats"
            {...fieldProps}
            filters={filters}
            slStats={slStats}
          />
        );
      case "listplayernotes":
        return (
          <PLListNotes key="listplayernotes" {...fieldProps} notes={notes} />
        );
      case "bddplayernotes":
        return <PLPlayerNotes key="bddplayernotes" {...fieldProps} />;
      case "slskills":
        return <PLSportlogiqSkills key="slskills" {...fieldProps} />;
      case "generatedsummaries":
        return (
          <PLPlayerGeneratedSummary key="generatedsummaries" {...fieldProps} />
        );
      default:
        return null;
    }
  };

  const getPlayerData = () => {
    switch (mode) {
      case "all":
        return (
          <>
            {renderField("war")}
            {renderField("scoutingranks")}
            {renderField("epstats")}
            {renderField("slskills", {
              statDefinitions: getDefaultListSkillStatDefs(),
            })}
            {renderField("listplayernotes")}
          </>
        );
      case "bio":
        return null;
      case "scouting":
        return renderField("scoutingranks");
      case "war55":
        return renderField("war");
      case "sportlogiq":
        return renderField("slskills", {
          statDefinitions: getDefaultListSkillStatDefs(),
        });
      case "eliteprospects":
        return renderField("epstats");
      case "generatedsummaries":
        return renderField("generatedsummaries");
      case "custom":
        return <>{customFields.map((f) => renderField(f))}</>;
      default:
        return null;
    }
  };

  return (
    <ListPlayerContainer
      ref={ref}
      key={`player-${!!rinknetPlayer ? rinknetPlayer.id : bddPlayer.slug}`}
    >
      <FieldsContainer vertical={vertical} noWrap={customSettings?.noWrap}>
        {shouldRender ? (
          <PLBio
            rinknetPlayer={rinknetPlayer}
            slPlayerMaster={slPlayerMaster}
            bddPlayer={bddPlayer}
            draftYear={
              draftYear || parseInt(getCurrentSeason().toString().slice(4))
            }
            bddPlayerSlugs={bddPlayerSlugs}
            rinknetIds={rinknetIds}
            allowHover={allowHover}
            overridePosition={overridePosition}
            justName={
              (justName && !!customFields && !customFields.includes("bio")) ||
              (mode === "custom" &&
                !!customFields &&
                !customFields.includes("bio"))
            }
          />
        ) : (
          <PlayerName bdd={bddPlayer} rp={rinknetPlayer} />
        )}
        {justName ? null : getPlayerData()}
      </FieldsContainer>
    </ListPlayerContainer>
  );
};

export default React.memo(ListPlayer);
