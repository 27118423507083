import { Card } from 'components/bdd/Card';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import useModal from 'components/bdd/Modal/useModal';
import { Typography } from 'components/bdd/Typography';
import { getNoteTypeOptions } from 'components/PlayerNotes/helpers';
import { PlayerNoteCommentForm } from 'components/PlayerNotes';
import { Button } from 'react-bootstrap';
import { BoxArrowInUpRight, PlusLg } from 'react-bootstrap-icons';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Link } from 'react-router-dom';
import { PLAYERS_V2_ROUTE } from 'constants';
import { LinkButton } from 'components/bdd/Button';
import { PLAYER_ENTITY_TYPE, useNotes } from 'components/Notes';
import { useContext } from 'react';
import { ModalContext } from 'components/bdd/Modal';

export const NotesOverview = ({ bddPlayer, onAddNote }) => {
  const { renderModal, hideModal } = useContext(ModalContext);
  const handleRenderModal = () => {
    renderModal({
      size: 'xl',
      title: (
        <Typography variant="h5">
          Add {bddPlayer.firstname} {bddPlayer.lastname} Note
        </Typography>
      ),
      body: (
        <Container padding={3}>
          <PlayerNoteCommentForm
            bddPlayer={bddPlayer}
            rinknetPlayer={bddPlayer.rinknetPlayer}
            onCancel={() => hideModal()}
            onSubmit={(values) => {
              createNote({ variables: values });
              hideModal();
            }}
          />
        </Container>
      ),
    });
  };

  const { noteItems: playerNoteItems, createNote } = useNotes({
    entityIds: [bddPlayer.slug],
    entityType: PLAYER_ENTITY_TYPE,
    startDate: null,
    endDate: null,
    onlyPinned: false,
    includeMentions: true,
    includeSubEntityTypes: [PLAYER_ENTITY_TYPE, 'TARGET_BDD_PLAYER'],
  });

  const noteTypeOptions = [
    {
      value: 'ALL',
      label: 'All Notes',
    },
    ...getNoteTypeOptions(bddPlayer),
  ];

  let filteredPlayerNoteItems = playerNoteItems;
  const { buttonGroup, selectedOption } = useButtonGroup({
    options: noteTypeOptions,
    variant: 'link',
  });

  if (selectedOption.value !== 'ALL') {
    filteredPlayerNoteItems = filteredPlayerNoteItems?.filter(
      (n) => n.note.noteType === selectedOption.value
    );
  }

  return (
    <>
      <Card
        header={
          <Link to={`${PLAYERS_V2_ROUTE}/${bddPlayer.slug}/notes`}>
            <LinkButton>
              <Typography variant="h6">
                <Row columnGap={1}>
                  {'Notes'} <Icon icon={<BoxArrowInUpRight />} />
                </Row>
              </Typography>
            </LinkButton>
          </Link>
        }
        actionContent={
          <Button
            size="sm"
            variant="outline-dark"
            onClick={() => {
              handleRenderModal();
              onAddNote && onAddNote();
            }}
          >
            <Row columnGap={1}>
              <Icon icon={<PlusLg />}></Icon>Add Note
            </Row>
          </Button>
        }
      >
        <Container height={500}>
          <Column gap={2}>
            <Container paddingTop={1}>{buttonGroup}</Container>
            <Container height="auto" overflowY="auto">
              <Column gap={2}>
                {filteredPlayerNoteItems && filteredPlayerNoteItems.length > 0 ? (
                  filteredPlayerNoteItems.map((pn) => (
                    <Container key={`pn-${pn.note.id}`}>
                      <Border>{pn.render()}</Border>
                    </Container>
                  ))
                ) : (
                  <Typography variant="body2">No notes found</Typography>
                )}
              </Column>
            </Container>
          </Column>
        </Container>
      </Card>
    </>
  );
};
