import { useQuery } from '@apollo/client';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { getRedGreenColor } from 'helpers/tables';
import { roundToX } from 'helpers/data';
import { GET_DRAFT_SIM_EXPECTED_RANGE } from 'apollo/queries/draft.queries';
import { TooltipSpan } from 'components/reports';

// TODO: We need a better way to define our default draft slugs
const DEFAULT_DRAFT_SLUG = '2025NHL';
export const DraftListPlayerExpectedPickRange = ({ draftSlug, listId, rinknetId }) => {
  const { data } = useQuery(GET_DRAFT_SIM_EXPECTED_RANGE, {
    variables: {
      draftSlug: draftSlug || DEFAULT_DRAFT_SLUG,
      listId,
    },
  });

  const expectedPickRange = data?.simDraftExpectedPicks.find(
    (ep) => ep.rinknetId == rinknetId
  );

  return expectedPickRange ? (
    <Container>
      <TooltipSpan
        content={
          <Typography variant="stat">
            #{expectedPickRange.expectedRangeLow} - #{expectedPickRange.expectedRangeHigh}
          </Typography>
        }
      >
        <Row columnGap={1}>
          <Typography variant="stat">Expected Pick</Typography>
          <Border>
            <Container
              paddingLeft={1}
              paddingRight={1}
              paddingTop={0.5}
              paddingBottom={0.5}
              style={{
                backgroundColor: getRedGreenColor(
                  expectedPickRange.expectedMedian,
                  1,
                  225,
                  true,
                  1.5
                ),
              }}
            >
              <Typography variant="stat">#{expectedPickRange.expectedMedian}</Typography>
            </Container>
          </Border>
        </Row>
      </TooltipSpan>
    </Container>
  ) : !data ? (
    <Container>
      <Typography variant="stat">Loading...</Typography>
    </Container>
  ) : null;
};
