// Utility function to set spacing
export const setSpacing = (value) =>
  value ? (value === 'auto' ? 'auto' : theme.spacing[value]) : value === 0 ? 0 : null;

// Define spacing variants
export const getSpacingVariants = (prefix) => {
  return [0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64].reduce(
    (acc, value) => {
      acc[value] = `${prefix}-${value}`;
      return acc;
    },
    {}
  );
};
