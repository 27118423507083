import { LinkButton } from "components/bdd/Button";
import { Column, Container, Row } from "components/bdd/Layout";
import { useModal } from "components/bdd/Modal";
import { getNextOrCurrentSeason } from "helpers/hockeyutils";
import { ExtendedIcon } from "./components/BFBadges";
import BFPlayerBreakdown from "./components/BFPlayerBreakdown";
import { formatDollars } from "helpers/contracts";
import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import { Typography } from "components/bdd/Typography";
import Icon from "components/bdd/Icon";
import { Asterisk } from "react-bootstrap-icons";
import { roundToX } from "helpers/data";
import { capitalize } from "helpers/string";
import { BFContractTooltip } from "./BFPlayerContracts";


export default function BFPlayerContractsSmall({
  name,
  bfPlayerSlug,
  bfPlayer,
}) {
  const { showModal, setShowModal, renderModal } = useModal({
    title: `${!!name ? name : bfPlayerSlug} Contract`,
  });

  const statusToColor = (status) => {
    return status === "UFA"
      ? theme.colors.categorical.red
      : status === "RFA"
      ? theme.colors.categorical.blue
      : "black";
  };

  return (
    <Container style={{ whiteSpace: "nowrap" }}>
      {showModal &&
        renderModal(<BFPlayerBreakdown bfPlayerSlug={bfPlayerSlug} />)}
      <Row gap={4}>
        <LinkButton
          onClick={(ev) => {
            ev.stopPropagation();
            setShowModal(true);
          }}
        >
          {!!bfPlayer?.hasActiveContract ? (
            <TooltipSpan content={<BFContractTooltip bfPlayer={bfPlayer} />}>
              <Row gap={2}>
                <Row gap={4}>
                  <Typography variant="body1">
                    {formatDollars(bfPlayer.capHit)}
                  </Typography>

                  <Typography variant="body1">
                    x{bfPlayer.currentRemainingTerm}
                  </Typography>
                </Row>
                {bfPlayer.retainedPct > 0 && (
                  <Icon
                    color={theme.colors.categorical.red}
                    icon={<Asterisk />}
                    size={10}
                  />
                )}
              </Row>
            </TooltipSpan>
          ) : !!bfPlayer?.hasFutureContract ? (
            "Future"
          ) : !!bfPlayer?.hasPastContract && !!bfPlayer?.expiryStatus ? (
            <span style={{ color: statusToColor(bfPlayer.expiryStatus) }}>
              {bfPlayer.expiryStatus}
            </span>
          ) : (
            "No contract"
          )}
        </LinkButton>
        {!!bfPlayer?.hasActiveContract && !!bfPlayer.hasFutureContract && (
          <TooltipSpan
            content={`${formatDollars(bfPlayer?.futureAav)} x${
              bfPlayer?.futureRemainingTerm
            }`}
          >
            <ExtendedIcon />
          </TooltipSpan>
        )}
      </Row>
    </Container>
  );
}