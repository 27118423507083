import { useEffect, useState } from 'react';
import { useSearchParamsStore } from './useSearchParamsStore';
import { SearchParams } from 'helpers/searchParams';

export const useSearchParamsState = (initialState, key = 'state') => {
  const store = new SearchParams();
  const storedState = store.paramExists(key) ? store.getSerialized(key) : null;
  const [state, setState] = useState(storedState || initialState);

  useEffect(() => {
    const store = new SearchParams();

    if (!state) {
      if (!store.getValue(key)) {
        return;
      }

      store.deleteKeys([key]);
      store.push();
    } else {
      store.setSerialized(key, state);
      store.push();
    }
  }, [state]);

  useEffect(() => {
    const handlePopState = () => {
      const store = new SearchParams();
      const storedState = store.getSerialized(key);

      if (JSON.stringify(state) !== JSON.stringify(storedState)) {
        setState(storedState);
      }
    };

    // Add event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [state, key]);

  return [state, setState];
};
