import React, { Suspense, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { EMPTY_TEXT, TextEditor } from '.';
import { CURRENT_TEXT_EDITOR_VERSION } from './helpers/constants';
import { extractVersionNumber } from './components/plate/plugins/VersionPlugin';
import { BDDLoader } from '../bddloader';

const PlateTextEditor = React.lazy(() => import('./PlateTextEditor'));

export const TextEditorField = ({ header, ...rest }) => {
  const [_, meta, helpers] = useField(rest.name);
  const { value } = meta;
  const { setValue } = helpers;

  const onTextUpdated = useCallback(
    (text) => {
      setValue(text);
    },
    [JSON.stringify(setValue)]
  );

  const valueIsEmpty = JSON.stringify(value) == JSON.stringify(EMPTY_TEXT);  
  const textEditor = useMemo(() => {
    return (
      <Suspense fallback={<BDDLoader />}>
        <PlateTextEditor header={header} onTextUpdated={onTextUpdated} text={value} />
      </Suspense>
    );
  }, [PlateTextEditor, header, onTextUpdated, valueIsEmpty]);

  const version = extractVersionNumber(value);
  return version == CURRENT_TEXT_EDITOR_VERSION ? (
    textEditor
  ) : (
    <TextEditor header={header} onTextUpdated={onTextUpdated} text={value} {...rest} />
  );
};
