import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/protectedroutes/privateroute';
import client from 'apollo/client';

import Login from 'components/Login/login';
import Logout from 'components/Logout/logout';

import {
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  ADMIN_LOGIN_ROUTE,
  SWITCHUSER_ROUTE,
} from 'constants';
import { BDDLoader } from 'components/bdd/bddloader';
import 'index.css';

const AppWrap = React.lazy(() => import('components/AppWrap'));

class Controller extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path={LOGIN_ROUTE} component={Login} />
            <Route path={ADMIN_LOGIN_ROUTE} render={(props) => <Login admin={true} />} />
            <Route exact path={LOGOUT_ROUTE} component={Logout} />
            <Route
              exact
              path={SWITCHUSER_ROUTE}
              render={(props) => <Logout switchUser={true} />}
            />
            <PrivateRoute
              path=""
              render={() => (
                <Suspense fallback={<BDDLoader />}>
                  <AppWrap />
                </Suspense>
              )}
            />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Controller />);
