import { gql } from '@apollo/client';

const DraftFragment = gql`
  fragment draftFragment on Draft {
    id
    slug
    name
    lastUpdateTime
    isPublic
    pinPriority
    picks {
      id
      draftSlug
      round
      overall
      pickInRound
      isSelected
      origTeamNhlid
      currTeamNhlid
    }
    nextSeasonPicks {
      id
      round
      origTeamNhlid
      currTeamNhlid
    }
  }
`;

const PickFragment = gql`
  fragment pickFragment on DraftPicks {
    id
    draftSlug
    round
    overall
    pickInRound
    isSelected
    origTeamNhlid
    currTeamNhlid
    origTeam {
      id
      slug
      longname
      shorthand
      nhlid
    }
    currTeam {
      id
      slug
      longname
      shorthand
      nhlid
    }
  }
`;

export const GET_DRAFTS = gql`
  query GetDrafts {
    drafts {
      id
      slug
      name
      year
      lastUpdateTime
      isPublic
      isReal
      userId
      pinPriority
    }
  }
`;
export const GET_DRAFT = gql`
  query GetDraft($slug: String!) {
    draft(slug: $slug) {
      id
      slug
      name
      year
      lastUpdateTime
      isPublic
      pinPriority
    }
  }
`;

export const UPDATE_DRAFT = gql`
  mutation UpdateDraft(
    $input: UpdateDraftInput!
    $newPickOrder: [DraftPickInput!]
    $copyPickOrderFromDraft: CopyPickOrderFromDraftInput
    $copyPickOrderFromCf: Boolean
    $nextSeasonPicksToUpdate: [NextSeasonDraftPickInput!]
    $forceCopy: Boolean
    $draftPickSelections: [DraftPickSelectionInput!]
    $draftTransactions: [DraftTransactionInput!]
  ) {
    updateDraft(
      input: $input
      newPickOrder: $newPickOrder
      copyPickOrderFromDraft: $copyPickOrderFromDraft
      nextSeasonPicksToUpdate: $nextSeasonPicksToUpdate
      copyPickOrderFromCf: $copyPickOrderFromCf
      forceCopy: $forceCopy
      draftPickSelections: $draftPickSelections
      draftTransactions: $draftTransactions
    ) {
      draft {
        ...draftFragment
      }
    }
  }
  ${DraftFragment}
`;

export const GET_DRAFT_SIM_EXPECTED_RANGE = gql`
  query DraftSimExpectedRange($draftSlug: String!, $listId: ID!) {
    simDraftExpectedPicks(draftSlug: $draftSlug, listId: $listId) {
      rinknetId
      offsetMedian
      offsetRangeLow
      offsetRangeHigh
      expectedMedian
      expectedRangeLow
      expectedRangeHigh
      numDrafted
    }
  }
`;


export const GET_MOCK_RESULTS = gql`
  query GetMockResults(
    $listId: ID!,
    $year: Int,
    $startDate: Date,
    $endDate: Date
    $draftSlugs: [ID],
    $userIds: [ID]
  ) {
    mockResults(
      listId: $listId,
      year: $year,
      startDate: $startDate
      endDate: $endDate
      draftSlugs: $draftSlugs,
      userIds: $userIds
    ) {
      user {
        id
        username
        name
      }
      draft {
        slug
        name
        year
        lastUpdateTime
        userId
      }
      bostonPicks {
        topAvailable {
          id
          listNumber
          rinknetPlayer {
            id
            realId
            firstname
            lastname
            position
          }
        }
        listNumber
        selectedRinknetId
        selectedRinknetPlayer {
          id
          realId
          firstname
          lastname
          position
        }
        pick {
          ...pickFragment 
        }
      }
    }
  }
${PickFragment}`


export const GET_PLAYERS_AVAILABLE = gql`
  query GetPlayersAvailable($draftSlug: ID!, $currentPick: Int!) {
    playersAvailable(draftSlug: $draftSlug, currentPick: $currentPick) {
      currentpick
      probs
    }
  }
`


export const GET_EP_DRAFT_TYPES = gql`
  query GetEPDraftTypes {
    epDraftTypes {
      slug
      leagueSlug
      name
      years
    }
  }
`;


const EPDraftPickFragment = gql`
  fragment epDraftPickFragment on EPDraftPicks {
    id
    draftTypeSlug
    draftTypeName
    leagueSlug
    year
    round
    overall
    playerId
    player {
      id
      firstName
      lastName
      bddPlayerSlug {
        slug
      }
    }
    teamId
    team {
      id
      name
      fullName
    }
  }
`;

export const GET_EP_DRAFT_PICKS = gql`
  query GetEPDraftPicks($draftTypeSlug: ID!, $leagueSlug: ID!, $year: Int!) {
    epDraftPicks(
      draftTypeSlug: $draftTypeSlug
      leagueSlug: $leagueSlug
      year: $year
    ) {
      ...epDraftPickFragment
    }
  }
  ${EPDraftPickFragment}
`;

export const GET_EP_DRAFT_PICKS_BY_PLAYER = gql`
  query GetEPDraftPicksByPlayer($id: ID!) {
    epDraftPicksByPlayer(id: $id) {
      ...epDraftPickFragment
    }
  }
  ${EPDraftPickFragment}
`;