import Trades from './Trades/Trades';
import TradesFilters from './Trades/TradesFilters';
import TradesTable from './Trades/TradesTable';
import { useState } from 'react';
import { Container } from 'components/bdd/Layout';
import { LinkButton } from 'components/bdd/Button';
import { Collapsible } from 'components/bdd/Collapsible';

export default function BFTrades({
  collapsedFilters = false,
  useSearchParams = true,
  hidePaginationControls,
  hideFilters = false,
  defaultPageSize = 50,
}) {
  const [showFilters, setShowFilters] = useState(!collapsedFilters);

  return (
    <Trades useStateParams>
      {!hideFilters && !!collapsedFilters && (
        <Container>
          <LinkButton onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? 'Hide' : 'Show'} Filters
          </LinkButton>
        </Container>
      )}

      {!hideFilters && (
        <Collapsible open={showFilters}>
          <Collapsible.Content>
            <TradesFilters />
          </Collapsible.Content>
        </Collapsible>
      )}

      <TradesTable
        useSearchParams={useSearchParams}
        hidePaginationControls={hidePaginationControls}
        defaultPageSize={defaultPageSize}
        showDelete={true}
      />
    </Trades>
  );
}
