import { useEPStatsContext } from './context';
import { useEffect } from 'react';
import { useEPMetadata } from '../hooks/useEPData';
import { getFilterValues } from 'components/bdd/Filters/helpers';
import { buildIndexColumns, buildStatColumns } from './columns';
import { usePaginated } from 'components/bdd/Paginated';
import { PAGINATED_EP_QUERY } from 'apollo/queries/eliteprospects.queries';
import { sortAndFilterColumns } from './helpers';
import { mergeLists } from 'helpers/data';

export const StatsData = ({}) => {
  const filters = useEPStatsContext((state) => state.filters);
  const filterOptions = useEPStatsContext((state) => state.filterOptions);
  const { gameType } = getFilterValues(filters);

  const sort = useEPStatsContext((state) => state.sort);
  const query = useEPStatsContext((state) => state.query);
  const targetGroup = useEPStatsContext((state) => state.targetGroup);
  const useZScore = useEPStatsContext((state) => state.useZScore);
  
  const { applyFilters, setResults, addColumns } = useEPStatsContext(
    (state) => state.actions
  );

  const { data, previousData, pageInfo, loading, pageControls } = usePaginated({
    key: 'paginatedEpStats',
    defaultLimit: 100,
    limitOptions: [100, 500, 1000, 10000],
    query: PAGINATED_EP_QUERY,
    skip: !query,
    variables: {
      sort,
      query,
    },
  }); 

  const stats = (data || previousData)?.paginatedEpStats.data[0];
  const metastats = stats?.extras?.metastats;

  const { highlightCallback, formatStatByZScore } = useEPMetadata({
    stats,
    metastats,
    gameType,
    targetGroup,
  });

  useEffect(() => {
    applyFilters();
  }, []);

  useEffect(() => {
    const tableData = stats?.data || [];
    setResults({
      stats,
      tableData,
      loading,
      pageControls,
      pageInfo,
    });
  }, [stats, loading]);

  useEffect(() => {
    const indexColumns = buildIndexColumns(filterOptions);
    addColumns(indexColumns);
  }, [filterOptions]);

  useEffect(() => {
    if (!stats || stats.data.length == 0) return;

    const statColumns = buildStatColumns({
      statDefinitions: stats.format.stat_definitions,
      formatStatByZScore: useZScore ? formatStatByZScore : null,
      highlightCallback,
    });

    addColumns(statColumns);
  }, [stats, loading, targetGroup, useZScore]);
};
