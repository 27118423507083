import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { BFWaiverTag } from './components/tags/BFWaiverTag';
import { BFPerformanceBonusTag } from './components/tags/BFPerformanceBonusTag';
import { BFThirtyFivePlusTag } from './components/tags/BFThirtyFivePlusTag';
import { BFGroupSixTag } from './components/tags/BFGroupSixTag';
import { BFTwoWayTag } from './components/tags/BFTwoWayTag';
import { BFNTCTag } from './components/tags/BFNMCTag';
import { BFBoughtOutTag } from './components/tags/BFBoughtOutTag';
import { BFTerminatedTag } from './components/tags/BFTerminatedTag';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useQuery } from '@apollo/client';
import {
  GET_BF_PLAYER_AND_CONTRACTS,
  GET_BF_TEAMS,
} from 'apollo/queries/bruinsfriendly.queries';
import { TeamLink } from 'components/TeamsV2';
import { formatDollars } from 'helpers/contracts';
import { roundToX } from 'helpers/data';
import { renderBFStatus } from './components/BFPlayerBreakdown';
import { contractTypeOptions } from 'components/ProObjectives/ContractSearch/constants';
import { BFElcTag } from './components/tags/BFELCTag';

export const BFPlayerHeader = ({ bfPlayer }) => {
  const name = `${bfPlayer?.firstName} ${bfPlayer?.lastName}`;

  const { data } = useQuery(GET_BF_PLAYER_AND_CONTRACTS, {
    variables: {
      bfPlayerSlug: bfPlayer.slug,
    },
  });

  const { data: bfTeamData } = useQuery(GET_BF_TEAMS, {
    variables: {
      isActive: true,
    },
  });

  const allContracts = data?.bfPlayer?.contracts;
  const contract =
    allContracts &&
    allContracts.length > 0 &&
    [...allContracts].sort((a, b) => new Date(b.signedDate) - new Date(a.signedDate))[0];

  const team = bfTeamData?.bfTeams.find((t) => t.slug == bfPlayer.teamSlug);

  return (
    <Container>
      <Column gap={2}>
        <Typography variant="h5">
          <Row columnGap={4} flexWrap>
            <Row>
              {team && (
                <TeamLink
                  // abbreviation={team.name}
                  nhlid={team.nhlid}
                  noLink
                  // slTeamSlug={d.signingTeam?.bddTeam?.slTeamSlug}
                  logoSize={28}
                />
              )}
              {name}
            </Row>
            <Row>
              <BFGroupSixTag bfPlayer={bfPlayer} />
              <BFWaiverTag bfPlayer={bfPlayer} />
              {data && (
                <>
                  <BFElcTag contract={contract} />
                  <BFPerformanceBonusTag
                    bfPlayer={bfPlayer}
                    contract={contract}
                  />
                  <BFThirtyFivePlusTag
                    bfPlayer={bfPlayer}
                    contract={contract}
                  />
                  <BFNTCTag bfPlayer={bfPlayer} contract={contract} />
                  <BFTerminatedTag bfPlayer={bfPlayer} contract={contract} />
                  <BFBoughtOutTag bfPlayer={bfPlayer} contract={contract} />
                </>
              )}
            </Row>
          </Row>
        </Typography>
        <Container paddingLeft={2}>
          <Typography variant="body2">
            <Row columnGap={4} flexWrap>
              {bfPlayer && (
                <Row columnGap={1}>
                  <b>Cap Hit:</b>
                  {formatDollars(bfPlayer.capHit, {
                    truncate: false,
                  })}
                </Row>
              )}
              <Row columnGap={1}>
                <b>Current Status:</b>
                <span>{renderBFStatus(bfPlayer.status)}</span>
              </Row>
              <Row columnGap={1}>
                <b>Age:</b>
                <span>
                  {roundToX(bfPlayer.age, 0)} ({bfPlayer.birthdate})
                </span>
              </Row>
              <Row columnGap={1}>
                <b>ELC Age:</b>
                {roundToX(bfPlayer.ageElcFa, 1)}
              </Row>
              {bfPlayer.waiversRequired && (
                <Row columnGap={1}>
                  <b>Waivers Age:</b>
                  {roundToX(bfPlayer.ageWaivers, 1)}
                </Row>
              )}
            </Row>
          </Typography>
        </Container>
      </Column>
    </Container>
  );
};
