import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_RINKNET_GAMES_WITH_REPORTS } from 'apollo/queries/game.queries';
import { Divider, VerticalDivider } from 'components/bdd/Divider';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { formatDate, formatDateGQL } from 'helpers/helpers';
import { Report } from './Report';
import { GET_ALL_REPORTS } from 'apollo/queries/scouting.queries';
import styled from 'styled-components';
import { theme } from 'constants';
import Icon from 'components/bdd/Icon';
import { ChevronDown, ChevronUp, Dot } from 'react-bootstrap-icons';
import VisibilityChangeWrapper from 'components/bdd/VisibilityChangeWrapper';
import FilterBank from './FilterBank';
import { filtersToGQL } from './useReportsSearchParams';
import { Collapsible } from 'components/bdd/Collapsible';

const Game = ({ game, filters = {} }) => {
  const [showReports, setShowReports] = useState(false);
  const [loadReports, setLoadReports] = useState(false);

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_ALL_REPORTS, {
      skip: !loadReports,
      variables: {
        limit: 200,
        filters: filtersToGQL({ ...filters, game_id: [game.gameId] }),
      },
      notifyOnNetworkStatusChange: true,
    })
  );

  const reportCount =
    'reportIds' in filters
      ? game.rinknetReports.filter((r) => filters.reportIds.includes(r.playerreportid))
          .length
      : 'rinknetIds' in filters
      ? game.rinknetReports.filter((r) => filters.rinknetIds.includes(r.playerrinknetid))
          .length
      : game.rinknetReports.length;

  return (
    <Container>
      <Divider />
      <Collapsible open={showReports} onOpenChange={setShowReports}>
        <Collapsible.Trigger className="w-full" asChild>
          <Container
            variant="button"
            backgroundColor={
              showReports ? theme.colors.light.focus : theme.colors.light.background
            }
            hoverBackgroundColor={theme.colors.light.focus}
            padding={3}
            onClick={() => {
              if (!showReports) {
                setLoadReports(true);
              }

              setShowReports(!showReports);
            }}
          >
            <Row justifyContent="space-between">
              <Row columnGap={2} flexWrap>
                <Typography variant="subtitle1">{formatDate(game.date)}</Typography>
                <Typography variant="subtitle1">{game.leagueshorthand}</Typography>
                <Typography variant="subtitle2">
                  {game.awayteamname} @ {game.hometeamname}
                </Typography>
                <Row>
                  <Typography variant="subtitle2">{reportCount} Reports</Typography>
                  <Icon icon={<Dot />} />
                  <Typography variant="subtitle2">
                    {game.reportAuthors.join(', ')}
                  </Typography>
                </Row>
              </Row>
              <Icon icon={showReports ? <ChevronDown /> : <ChevronUp />} />
            </Row>
          </Container>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <Container padding={3}>
            {/* <Container padding={3} style={{ maxHeight: 500, overflowY: 'auto' }}> */}
            {data ? (
              <Column gap={2}>
                {data.allReports.data.map((r) => (
                  <Report
                    key={`report-${r.id}`}
                    report={r}
                    statsData={data.allReports.extraData}
                  />
                ))}
              </Column>
            ) : (
              placeholder
            )}
          </Container>
        </Collapsible.Content>
      </Collapsible>
    </Container>
  );
};

export const ReportsByGame = ({ defaultFilters, hideFilters, hideDateRangeFilter }) => {
  const [filters, setFilters] = useState(defaultFilters || {});
  const [requestFilters, setRequestFilters] = useState(defaultFilters || {});

  const query = useQuery(GET_RINKNET_GAMES_WITH_REPORTS, {
    variables: {
      limit: 50,
      offset: 0,
      filters: JSON.stringify({
        ...requestFilters,
        startDate: requestFilters.startDate && formatDateGQL(requestFilters.startDate),
        endDate: requestFilters.endDate && formatDateGQL(requestFilters.endDate),
      }),
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: gamesData, placeholder: gamesPlaceholder } = usePlaceholder(query);

  const fetchMoreGames = () => {
    if (!gamesData || !gamesData.reportGames.pageInfo.hasNextPage) return;

    query.fetchMore({
      variables: {
        offset: gamesData.reportGames.pageInfo.endIndex,
      },
    });
  };

  return (
    <Container padding={3}>
      <Column>
        {!hideFilters && (
          <FilterBank
            hideDateRangeFilter={hideDateRangeFilter}
            filters={filters}
            setFilters={setFilters}
            requiresRefetch={JSON.stringify(filters) !== JSON.stringify(requestFilters)}
            handleFetch={() => setRequestFilters(filters)}
          />
        )}
        {gamesData
          ? gamesData.reportGames.data
              .filter((g) => g.rinknetReports.find((rr) => rr.playerreportid))
              .map((g) => (
                <Game key={`gc-${g.gameId}`} game={g} filters={requestFilters} />
              ))
          : gamesPlaceholder}
        {gamesData && gamesData.reportGames.pageInfo.hasNextPage && (
          <VisibilityChangeWrapper callback={fetchMoreGames} defaultOnScreen={false}>
            {gamesPlaceholder}
          </VisibilityChangeWrapper>
        )}
      </Column>
    </Container>
  );
};
