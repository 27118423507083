import { gql } from "@apollo/client"

const ReportDataFragment = gql`
    fragment reportDataFragment on ReportData {
        id
        team {
            nhlid
            slug
            shorthand
            longname
        }
        opponent {
            nhlid
            slug
            shorthand
            longname
        }
        reportType
        gameUid
        season
        round
        lastModified
        reportState
    }
`

export const GET_REPORTDATA_BY_ID = gql`
    query GetReportDataByID($id: ID!) {
        reportData(id: $id) {
          ...reportDataFragment
        }
    }
    ${ReportDataFragment}
`

export const CREATE_REPORT_DATA = gql`
    mutation CreateReportData($input: CreateReportDataInput!) {
        createReportData(input: $input) {
            reportData {
                ...reportDataFragment
            }
        }
    }, ${ReportDataFragment}`