import { theme } from 'constants';
import BDDErrorBoundary from '../bdderrorboundary';
import { Container } from '../Layout';
import { usePageStore } from './Page';

export const PageBody = ({
  children,
  backgroundColor = theme.colors.light.secondary,
  padding = 2,
}) => {
  const shortKeyExists = usePageStore((state) => state.shortKeyExists);

  return (
    <BDDErrorBoundary>
      <Container
        padding={padding}
        backgroundColor={backgroundColor}
        height="100%"
        overflow="auto"
      >
        {!shortKeyExists && children}
      </Container>
    </BDDErrorBoundary>
  );
};
