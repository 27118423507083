import styled from 'styled-components';
import { theme } from 'constants';

export const Row = styled.div(
  ({
    alignItems,
    alignContent,
    justifyContent,
    gap,
    columnGap,
    rowGap,
    wrap,
    flexWrap,
    height,
    style, // accept a style arg (object)
  }) => ({
    display: 'flex',
    alignContent,
    alignItems: alignItems || 'center',
    justifyContent: justifyContent || 'start',
    height: height || '100%',
    flexWrap: wrap || flexWrap ? 'wrap' : null,
    columnGap: columnGap ? theme.spacing[columnGap] : gap ? gap : 0,
    rowGap: rowGap ? theme.spacing[rowGap] : gap ? gap : 0,
    ...style,
    // gap: gap ? theme.spacing[gap] : 0,
  })
);

export const Column = styled.div(
  ({ alignItems, justifyContent, gap, style, ...rest }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignItems || 'unset',
    justifyContent: justifyContent || 'start',
    gap: gap ? theme.spacing[gap] : 0,
    height: '100%',
    ...style,
  })
);

