import { SearchParams } from 'helpers/searchParams';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { create, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { similarContractWeightPresets } from './constants';

const SimilarContractsContext = createContext();

const createStore = ({ defaults }) =>
  create(
    immer((set, get) => ({
      targetDetails: defaults.targetDetails || {
        targetMode: {
          id: 'targetMode',
          value: 'future',
        },
        statsMode: {
          id: 'statsMode',
          value: 'combined',
        },
        priorSeasons: {
          id: 'priorSeasons',
          value: 0,
        },
        presets: {
          id: 'presets',
          value: 'player_type',
        },
        nNeighbours: {
          id: 'nNeighbours',
          value: 20,
        },
      },
      targetVector: defaults.targetVector,
      targetWeights:
        defaults.targetWeights || similarContractWeightPresets['future']['player_type'],
      similarContracts: [],
      timeRange: () => {
        return get().targetDetails.targetMode.value == 'current'
          ? [
              {
                col: 'time-range',
                cond: '=',
                val: 'platform-stats',
              },
              {
                col: 'platform-prior-seasons',
                cond: '=',
                val: get().targetDetails.priorSeasons.value || 0,
              },
            ]
          : [
              {
                col: 'time-range',
                cond: '=',
                val: 'season-stats',
              },
              {
                col: 'prior-seasons',
                cond: '=',
                val: get().targetDetails.priorSeasons.value || 0,
              },
            ];
      },
      actions: {
        setState: (newState) => {
          set((state) => {
            return {
              ...state,
              ...newState,
            };
          });
        },
      },
    }))
  );

export const SimilarContractsContextProvider = ({
  children,
  targetDetails,
  targetVector,
  targetWeights,
  useSearchParams,
}) => {
  const searchParams = new SearchParams();
  const defaults = useSearchParams
    ? {
        targetWeights: searchParams.getSerialized('targetWeights') || targetWeights,
        targetVector: searchParams.getSerialized('targetVector') || targetVector,
        targetDetails: searchParams.getSerialized('targetDetails') || targetDetails,
      }
    : { targetDetails, targetWeights, targetVector };


  const store = useRef(createStore({ defaults })).current;

  return (
    <SimilarContractsContext.Provider value={store}>
      {children}
      <SearchParamsListener />
    </SimilarContractsContext.Provider>
  );
};

export const useSimilarContractsContext = (selector) =>
  useStore(useContext(SimilarContractsContext), selector);

const SearchParamsListener = ({}) => {
  const { targetDetails, targetVector, targetWeights } = useSimilarContractsContext();

  useEffect(() => {
    const searchParams = new SearchParams();
    searchParams.setSerialized('targetWeights', targetWeights);
    searchParams.setSerialized('targetVector', targetVector);
    searchParams.setSerialized('targetDetails', targetDetails);

    searchParams.push();
  }, [targetVector, targetDetails, targetWeights]);
};
