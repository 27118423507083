import React from "react"
import styled from "styled-components"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Draggable from "./draggable"
import Droppable from "./droppable"
import { buildEPLink, buildPlayerRoute } from "../../helpers/routing"
import { Link } from "react-router-dom"
import { Row } from './Layout'

const LineupSpotStyles = styled.div`
    .lineup-spot {
        width: 100%;
        min-height: ${props => props.minHeight ? props.minHeight : '30px'};
        margin: 3px;

        // border-radius: 5px;
        // box-shadow: 0 0 5px #ddd;
        border: 1px solid #77777777;
    }`
const PlayerBoxStyles = styled.div`
    padding: 5px 0px;
    width: 100%;
    font-size: 0.8em;
    border-radius: 5px;
    text-align: center;
    background: #fff;

    a {
        color: black;
    }

    ${props => props.highlight ? `
        border: 2px solid ${props.highlight};
    `: null}
    // box-shadow: 0 0 5px ${props => props.highlight ? props.highlight : '#ddd'};
`

export const LineupSpot = ({lineupSpot, updateLineupSpot, minHeight='30px', children}) => {
    return <LineupSpotStyles minHeight={minHeight}>
        <Droppable
            acceptItemTypes={['player']}
            onDrop={pdata => updateLineupSpot(lineupSpot, pdata.data.slug)}
            className='lineup-spot'
        >
            {children}
        </Droppable>
    </LineupSpotStyles>
}

export const DepthPlayer = ({
    children, 
    player, 
    renderPlayer, 
    showInfo, 
    highlight, 
    highlightCallback, 
    draggable, 
    removeable, 
    lineupSpot, 
    onRemovePlayer,
    style
}) => {
    const highlightColor = highlight ? highlight : !!highlightCallback ? highlightCallback(player) : null
    if (!player) return <PlayerBoxStyles highlight={highlightColor} style={style}/>
    const rp = player.__typename === 'RinknetPlayer' ? player : player.rinknetPlayer
    const bp = player.__typename === 'RinknetPlayer' ? player.bddPlayerSlug : player
    const content = !!children ? children
        : renderPlayer ? renderPlayer(player, showInfo, lineupSpot) 
        : showInfo ? <>
            <Link to={buildPlayerRoute(!!bp ? bp.slug : null)}>
                <b>{player.firstname} {player.lastname}</b>
            </Link>
            {' '}
            <EPLink firstname={rp.firstname} lastname={rp.lastname} epid={rp.eliteprospectsid} />
            {!!rp ? <div style={{fontSize: '0.7em'}}>
                {rp.caphitamount ? <>
                    ${(rp.caphitamount/1e6).toFixed(2)}M x{rp.contractstatus},{' '}
                </> : null }
                {rp.age.toFixed(1)}yo,{' '}
                {rp.round ? `Draft R${rp.round}` : 'Undrafted'}
            </div> : null}
        </> : <>
            <b>{player.firstname} {player.lastname}</b>
        </>

    if (draggable) return <PlayerBoxStyles highlight={highlightColor} style={style}>
        <Draggable itemType='player' data={player} className='player-box'>
            <Row columnGap={1} justifyContent="center">
                {removeable ? <>
                    <span style={{cursor: 'pointer'}} onClick={() => onRemovePlayer(lineupSpot)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </span>
                    {' '}            
                </> : null}
                {content}
            </Row>
        </Draggable>
        </PlayerBoxStyles>
    return <PlayerBoxStyles highlight={highlightColor} style={style}>
        {content}
    </PlayerBoxStyles>
}

export const EPLink = ({firstname, lastname, epid, logo}) => {
    const epLink = buildEPLink(epid, firstname, lastname)
    if (!epid) return null
    if (!logo) return <a
        style={{fontSize: '1.0em', color: 'black'}}
        href={epLink}
        target="_blank"
    >
        <em>EP</em>
    </a>
    return <a 
        style={{borderRadius: "5px", background: "#233C50", cursor: "pointer"}}
        href={epLink}
        target="_blank"
    >
        <img src='https://static.eliteprospects.com/images/ep-logo.svg' width="30px" />
    </a>
}

export const PositionHeaderStyles = styled.div`
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
`

export const LeagueHeaderStyles = styled.div`
    font-family: Oswald;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`