import { theme } from 'constants';
import { Divider } from '../Divider';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { PageTabs } from './PageTabs';
import { Helmet } from 'react-helmet';
import { BackButton } from './BackButton';
import { usePageStore } from './Page';
import { Breadcrumbs } from './Breadcrumbs';

export default function PageHeader({
  title,
  helmetTitle,
  caption,
  subtitle,
  showHistoryBack,
  content,
  children,
  actionContent,
  secondaryActionContent, // rendered across from routes
  routes,
  divider = true,
  persistSearchParams,
}) {
  const breadcrumb = usePageStore((state) => state.breadcrumb);

  return (
    <Container>
      {(typeof helmetTitle === 'string' || typeof title === 'string') && (
        <Helmet>
          <title>{helmetTitle || title}</title>
        </Helmet>
      )}
      <Container backgroundColor={theme.colors.light.background}>
        {children || (
          <Container padding={3} paddingTop={!!breadcrumb ? 1 : 3} paddingBottom={!!breadcrumb ? 1 : 3}>
            {!!breadcrumb && <Breadcrumbs />}
            <Row justifyContent="space-between" flexWrap rowGap={1} columnGap={1}>
              <Row columnGap={1} alignItems="center" flexWrap>
                {showHistoryBack && <BackButton />}
                <Row columnGap={1} alignItems="baseline" flexWrap>
                  <Typography variant="h5">{title}</Typography>
                  <Typography variant="subtitle2">{caption}</Typography>
                </Row>
              </Row>
              <Container>{actionContent}</Container>
            </Row>
            {subtitle && (
              <Container>
                <Typography variant="subtitle2">{subtitle}</Typography>
              </Container>
            )}
            {content && <Container>{content}</Container>}
            <Row justifyContent="space-between">
              {routes && (
                <PageTabs routes={routes} persistSearchParams={persistSearchParams} />
              )}
              {secondaryActionContent && <Container>{secondaryActionContent}</Container>}
            </Row>
          </Container>
        )}
      </Container>
      {divider && <Divider />}
    </Container>
  );
}
