import BDDSortableTable from 'components/bdd/bddsortabletable';
import { useEPStatsContext } from './context';
import { StyledTable } from 'components/bdd/Table';
import { Typography } from 'components/bdd/Typography';
import { Container } from 'components/bdd/Layout';
import { mapSort } from 'components/bdd/Table/helpers';

export const StatsTable = ({
  filterData,
  filterColumns,
  editColumns,
  height = '90%',
  overflow = 'auto',
}) => {
  let { stats, tableData, pageInfo, loading } =
    useEPStatsContext((state) => state.results) || {};
  let columns = useEPStatsContext((state) => state.columns);
  const sort = useEPStatsContext((state) => state.sort);
  const { setSort, applyFilters } = useEPStatsContext((state) => state.actions);

  if (!stats) return <Typography variant="body1">Loading...</Typography>;

  if (tableData.length == 0 || stats.data.length == 0) {
    return <Typography variant="body1">No data found</Typography>;
  }

  if (filterData) {
    tableData = tableData.filter(filterData);
  }

  if (filterColumns) {
    columns = columns.filter(filterColumns);
  }

  if (editColumns) {
    columns = editColumns(columns);
  }

  const sortByProps =
    !pageInfo.hasNextPage && !pageInfo.hasPreviousPage
      ? {}
      : {
          manualSortBy: true,
          onSortChange: (sort) => {
            let mappedSort = mapSort(sort, { player: 'last_name' });
            if (sort[0].id != 'player') {
              mappedSort = [
                mappedSort[0],
                {
                  id: 'last_name',
                  desc: false,
                },
              ];
            }

            setSort(mappedSort);
            applyFilters();
          },
        };

  return (
    <Container height={height} overflow={overflow}>
      <StyledTable variant="sticky" rowHeight={24} withBorders withHighlight>
        <BDDSortableTable
          loading={loading}
          columns={columns}
          data={tableData}
          defaultSort={sort}
          scroll={false}
          virtualizeRows
          disableSortRemove
          {...sortByProps}
        />
      </StyledTable>
    </Container>
  );
};
