

export const tradeAssetsToPlayers = (assets) => assets.filter(a => a.assetType === 'PLAYER').map(a => ({
  assetType: a.assetType,
  fromTeamSlug: a.fromTeamSlug,
  toTeamSlug: a.toTeamSlug,
  bddPlayerSlug: a.bddPlayer?.slug,
  capHit: a.bddPlayer?.bfPlayer?.capHit,
  retainedSalary: a.retainedSalary,
  retainedSalaryPct: a.retainedSalaryPct
}))

export const tradeAssetsToPicks = (assets) => {
  return assets.filter(a => a.assetType === 'DRAFT_PICK').map(a => ({
    assetType: a.assetType,
    fromTeamSlug: a.fromTeamSlug,
    toTeamSlug: a.toTeamSlug,
    pickId: a.bfDraftPick.id,
    isConditional: a.isConditional,
    conditionalDetails: a.conditionalDetails
  }));
}
